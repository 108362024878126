import React from 'react';
import { Box, Typography, ListItem, List, ListItemText } from '@mui/material';
import colors from 'constants/colors';


const Actions = ({ actions, code }) => {
	return (
		<Box>
			<List sx={{ minWidth: '360px' }}>
				{actions.map(action => <ListItem key={action.itemId} disablePadding>
					<ListItemText 
						primary={action.item.name} 
						secondary={action.item.code}
					/>
					<Box>
						<Typography variant='body1' color={'text.secondary'}>{`x ${action.amount}`}</Typography>
					</Box>
				</ListItem>)}
			</List>
			{code !== 'adjustment' && actions.length > 0 && 
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="body1">총 수량: </Typography>
					<Typography variant="body1" style={{ color: colors.secondary }}>{actions.reduce((accu, current) => accu + +current.amount, 0)}</Typography>
				</Box>
			}
		</Box>
	);
}

export default Actions;