import { useEffect, useState } from "react";
import axios from "axios";
import API from "api";
import { format, lastDayOfMonth } from "date-fns";


export default function useGetCoupangSales({ spaceId, vendorId, yearmonth }, pageTrigger) {
    const [ rows, setRows ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ nextToken, setNextToken ] = useState();
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        setError(null);
    }, [ spaceId, vendorId, yearmonth ]);

    useEffect(() => {
        if (!vendorId || !yearmonth) return;

        const dateFrom = format(yearmonth, 'yyyy-MM') + '-01';
        const dateTo = format(lastDayOfMonth(yearmonth), 'yyyy-MM-dd');

        const abortController = new AbortController();
        setLoading(true);
        setError(null);

        const params = { 
            spaceId,
            vendorId,
            dateFrom,
            dateTo,
            nextToken,
        };
        API.get(`/commerce/coupang/get-sales-list`, { params, signal: abortController.signal })
        .then(result => {
            const { nextToken, data } = result;

            setRows(prevs => prevs.concat(data));
            setMayMore(!!nextToken);
            setNextToken(nextToken);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
            else setError(e);
        })
        .finally(() => { setLoading(false); });
        
        return () => { abortController.abort(); };
    }, [ spaceId, vendorId, yearmonth, pageTrigger ]);

    return { loading, error, rows, mayMore, fetched };
}