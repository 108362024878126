import { add, format, parse, sub } from 'date-fns';

const getFromToGroupby = (groupby, from, to) => {
    let fromDate, toDate;
    let from_a, to_a;
    if (!from) {
        const toDate_a = to ? parse(to, 'yyyy-MM-dd', new Date()) : new Date();
        if (groupby === 'month') {
            fromDate = sub(toDate_a, { years: 1 });
        }
        else if (groupby === 'week') {
            fromDate = sub(toDate_a, { months: 3 });
        }
        else {
            fromDate = sub(toDate_a, { months: 1 });
        }
        from_a = format(fromDate, 'yyyy-MM-dd');
    }
    else {
        from_a = from;
        fromDate = parse(from, 'yyyy-MM-dd', new Date());
    }
    
    if (!to) {
        if (from) {
            const fromDate_a = parse(from, 'yyyy-MM-dd', new Date());
            if (groupby === 'month') {
                toDate = add(fromDate_a, { years: 1 });
            }
            else if (groupby === 'week') {
                toDate = add(fromDate_a, { months: 3 });
            }
            else {
                toDate = add(fromDate_a, { months: 1 });
            }
        }
        else {
            toDate = new Date();
        }
        to_a = format(toDate, 'yyyy-MM-dd');
    }
    else {
        to_a = to;
        toDate = parse(to, 'yyyy-MM-dd', new Date());
    }

    return ({
        from_a,
        to_a,
        fromDate,
        toDate,
    });
}

export default getFromToGroupby;