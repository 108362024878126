import { useEffect, useState } from "react";
import axios from "axios";
import API from "api";


export default function useGetPlacesSummaries(spaceId) {
    const [ rows, setRows ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        if (!spaceId) return;
        
        let cancel;
        setLoading(true);
        API.get(`/place/places-summaries/${spaceId}`, { cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setRows(data);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ spaceId ]);

    return { loading, fetched, rows };
}