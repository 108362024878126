import React, { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { format, sub } from 'date-fns';
import useTraderReleaseAmount from 'api/statistics/useTraderRelease_v2';
import Nodata from 'components/Nodata';
import { iso_datetime_pipe, item_name_pipe, number_format_pipe } from 'pipes';
import Filter from './Filter';
import { useSpace } from 'contexts/space_v2.context';



const TraderReleaseAmount = () => {
	const [ searchParams ] = useSearchParams();

    const space = useSpace();

    const params = useMemo(() => {
        const itemId = searchParams.get('itemId');
        let from = searchParams.get('from');
        let to = searchParams.get('to');

        if (!from) from = format(sub(new Date(), { years: 1 }), 'yyyy-MM-dd');
        if (!to) to = format(new Date(), 'yyyy-MM-dd');

        return ({
            spaceId: space?.id,
            from: iso_datetime_pipe(from),
            to: iso_datetime_pipe(to),
            itemId: itemId || null,
        });
    }, [ space, searchParams ]);

    const { loading, rows, resultParams } = useTraderReleaseAmount(params);

    // item selection
    const [ item, setItem ] = useState();

    const resultDescribe = useMemo(() => {
        if (!resultParams) return '';

        const { from, to, itemId } = resultParams;
        
        const fromto_t = `${format(new Date(from), 'yyyy-MM-dd')} ~ ${format(new Date(to), 'yyyy-MM-dd')}`;
        let text = `기간: ${fromto_t}`;
        
        if (itemId) {
            if (item) {
                text += `\n제품: ${item_name_pipe(item)}`;
                if (item.valSummaries) text += ` (${item.valSummaries})`;
            }
            else text += `\n제품: 선택됨`;
        }
        
        return text;
    }, [ resultParams, item ]);

	return (
		<Box>
            <Filter searchDisabled={loading} item={item} setItem={setItem} />

            {!!rows && <>
                <Box sx={{ mt: 4, mb: 2 }}>
                    <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>{resultDescribe}</Typography>
                </Box>

                <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">거래처</th>
                            <th scope="col">판매량</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row) => <tr key={row.id}>
                            <th style={{ textAlign: 'start' }}>{row.name}</th>
                            <td>{number_format_pipe(row.amount)}</td>
                        </tr>)}
                    </tbody>    
                </table>

                {rows.length === 0 && <Nodata>거래처가 없습니다.</Nodata>}
            </>}
		</Box>
	);
}

export default TraderReleaseAmount;