import { useEffect, useState } from "react";
import axios from "axios";
import API from "api";


export default function useGetAlarms({ page, setPage, me }) {
    const [ rows, setRows ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ rowsInPage, setRowsInPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(true);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setFetched(false);
        setRows([]);
        setMayMore(true);
        setPage(0);
    }, []);

    useEffect(() => {
        if (!me) return;
        
        let cancel;
        setLoading(true);
        const params = { page, userId: me.id };
        API.get('/alarm/my-alarms', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setTotalCount(data.totalCount);
            setRowsInPage(data.rowsInPage);
            setRows(prevs => prevs.concat(data.rows));
            setMayMore(data.rows.length > 0);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ me, page ]);

    return { loading, rows, mayMore, fetched, totalCount, rowsInPage };
}