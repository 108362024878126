import React, { createContext, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography, useMediaQuery } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import colors from 'constants/colors';

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
    const isHandset = useMediaQuery(theme => theme.breakpoints.down('xs'));

    // snackbar 
	const [ snackbarOpen, setSnackbarOpend ] = useState(false);
    const [ snackbarMessage, setSnackbarMessage ] = useState();
	
    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpend(true);
    };
    
    const cloaseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpend(false);
    };
    // end: snackbar

    // alert
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ alertContent, setAlertContent ] = useState();

    const showAlert = (message) => {
        setAlertContent(message);
        setAlertOpen(true);
    };
    
    // // dialog
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogData, setDialogData ] = useState(false);
    const showDialog = (data) => {
        setDialogData(data);
        setDialogOpen(true);
    }

	return (
		<AppContext.Provider
			value={{
				showSnackbar,

                showAlert,

                showDialog,
			}}
		>
			{children}

            {/* snackbar */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isHandset ? 'center' : 'right',
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={cloaseSnackbar}
                message={snackbarMessage}
            />

            {/* alert */}
            <Dialog
                open={alertOpen}
                onClose={() => { setAlertOpen(false); }}
            >
                <DialogContent style={{ minWidth: '240px' }}>
                    <Box sx={{ textAlign: 'center' }}><ErrorIcon fontSize='large' sx={{ my: 2 }} /></Box>
                    <DialogContentText style={{ whiteSpace: 'pre-line' }}>{alertContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAlertOpen(false) }} color="primary">확인</Button>
                </DialogActions>
            </Dialog>

            {/* dialog */}
            {dialogData && <Dialog
                open={dialogOpen}
                onClose={() => { setDialogOpen(false); }}
            >
                {dialogData.title && <DialogTitle>{dialogData.title}</DialogTitle>}
                <DialogContent style={{ minWidth: '240px' }}>
                    {dialogData.message && <DialogContentText style={{ whiteSpace: 'pre-line' }}>{dialogData.message}</DialogContentText>}
                    {dialogData.warningMessage && <Typography sx={{ mt: 3, color: colors.warnDark }} variant="body2">{dialogData.warningMessage}</Typography>}
                </DialogContent>
                <DialogActions>
                    {dialogData.second && <Button onClick={() => {
                        setDialogOpen(false);
                        if (dialogData.second?.onClick) dialogData.second.onClick();
                    }} style={{ color: colors.textSecondary }}>{dialogData.second.text || '취소'}</Button>}

                    <Button onClick={() => {
                        setDialogOpen(false);
                        dialogData.first?.onClick();
                    }} color="primary" autoFocus>{dialogData.first?.text || '확인'}</Button>
                </DialogActions>
            </Dialog>}

		</AppContext.Provider>
	);
};

export {
	AppContext,
	AppContextProvider
};