import React, { useState, useMemo } from 'react';
import DataListWrapper from 'components/DataListWrapper';
import ObjTable from './ObjTable';
import useSearchActionobj from 'api/action/useSearchActionobj';
import { useSpace } from 'contexts/space_v2.context';


const SimpleHistory = ({ intend, itemId, traderId, placeId}) => {
	const space = useSpace();

	const [ page, setPage ] = useState(0);
	
    const params = useMemo(() => {
		const spaceId = space?.id;
        
        return ({
			placeId,
			itemId,
			traderId,
			spaceId,
			isProcessed: 'Y',
        });
	}, [ itemId, traderId, placeId ]);

	const { loading, error, rows, count, rowsPerPage, fetched } = useSearchActionobj(page, space?.id, params);

	return (
		<DataListWrapper
            loading={loading}
            fetched={fetched}
            length={rows?.length}
            nodata={'히스토리가 없습니다.'}
        >
            <ObjTable
                rows={rows} 
                count={count} 
                rowsPerPage={rowsPerPage} 
                page={page} 
                setPage={setPage}
                intend={intend}
            />
        </DataListWrapper>
	);
}

export default SimpleHistory;