import React, { useMemo } from 'react';
import { Box, Card, CardActions, CardContent, Grid, Typography, Button } from '@mui/material';
import Guide from 'components/Guide';
import Title from 'components/Title';
import useGetPlacesSummaries from 'api/place/useGetPlacesSummaries';
import Loading from 'components/Loading';
import { number_format_pipe } from 'pipes';
import colors from 'constants/colors';
import useGetPlace from 'api/place/useGetPlace';
import Place from '../Place';
import { useSearchParams } from 'react-router-dom';
import { useSpace } from 'contexts/space_v2.context';


const PlaceSelected = ({ space, place }) => {
    const { loading, fetched, row } = useGetPlace(space.id, place.id);

    if (loading || !fetched) return (<Loading />);

    return (
        <Place place={row} />
    );
}

const PlaceItem = ({ place, handleSelect, selected }) => {

    return (
        <Card variant='outlined' sx={{ bgcolor: place.id === selected?.id ? colors.lime : 'white' }}>
            <CardContent sx={{ position: 'relative' }}>
                <Typography variant='body1' sx={{ fontSize: 14 }} color="text.primary" gutterBottom>{place.name}</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>순번: {place.order}</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>총 제품 수량: {number_format_pipe(place.amount)}</Typography>
            </CardContent>
            <CardActions>
                <Button size="small" variant="contained" color="primary" onClick={handleSelect(place)} disabled={place.id === selected?.id}>보기</Button>
            </CardActions>
        </Card>
    );
}

const PlaceHistoryPage = () => {
	const space = useSpace();
    const [ searchParams, setSearchParams ] = useSearchParams();
    
    const { loading, fetched, rows } = useGetPlacesSummaries(space.id);

    const handleSelect = (place) => () => {
        setSearchParams({
            placeId: place.id
        });
    }

    const selected = useMemo(() => {
        const placeId = searchParams.get('placeId');
        if (placeId && rows) return rows.find(el => el.id == placeId);
    }, [ searchParams, rows ]);
    
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Title>창고 현황</Title>

            {loading ? <Loading /> : <>
                {fetched && <>
                    {rows.length === 0 && <Guide>창고가 없습니다.</Guide>}
                    <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
                        {rows.map(place => <Grid item key={place.id} xs={6} md={4} lg={3} xl={2}>
                            <PlaceItem 
                                place={place}
                                handleSelect={handleSelect}
                                selected={selected}
                            />
                        </Grid>)}
                    </Grid>
                </>}
            </>}

            {selected && <PlaceSelected space={space} place={selected} />}
        </Box>
	);
}

export default PlaceHistoryPage;