import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import { Link } from 'react-router-dom';
import SimpleHistory from 'components/action/simple-history/SimpleHistory';


const Trader = ({ trader }) => {
    const row = (label, value) => (
		<div className={pageStyles.row}>
			<div className={pageStyles.label}>
				<Typography variant="body2" className={pageStyles.labelText}>{label}</Typography>
			</div>
			<div className={pageStyles.content}>
				<Typography variant="body2">{value}</Typography>
			</div>
		</div>
	);

	return (
		<Box>
			<Box sx={{ mb: 3 }}>
				<MuiLink component={Link} to={'/space/statistics/trader-release'}>판매량 통계 보기</MuiLink>
			</Box>
			

			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
				</Box>
				<div className={pageStyles.table}>
					{row('거래처명', trader.name)}
                    {row('순번', trader.order)}
				</div>
			</div>

            <div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>액션 히스토리</Typography>
				</Box>
                <SimpleHistory intend={'trader'} traderId={trader.id} />
            </div>
		</Box>
	);
}

export default Trader;