import React, { useState, useContext, useMemo } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid, ListItemText, MenuItem, Typography, } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Guide from 'components/Guide';
import CategoryFormModal from './CategoryFormModal';
import colors from 'constants/colors';
import ContextMenu from 'components/ContextMenu';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import Title from 'components/Title';
import { useSearchParams } from 'react-router-dom';
import ManagePropsDialog from '../prop/ManagePropsDialog';
import { useSpace, useSpaceDispath } from 'contexts/space_v2.context';


const Item = ({ category }) => {
    const { showDialog, showSnackbar } = useContext(AppContext);
    
    const space = useSpace();
    const dispatch = useSpaceDispath();

    const [ searchParams, setSearchParams ] = useSearchParams();
    const categoryId = useMemo(() => searchParams.get('categoryId') ? +searchParams.get('categoryId') : null);

    const [anchorel, setAnchorel] = useState(null);
    const open = Boolean(anchorel);
    const handleIconClick = (event) => {
        setAnchorel(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorel(null);
    };

    const [ formOpen, setFormOpen ] = useState(false);
    const onModifyClick = () => {
        handleClose();
        setFormOpen(true); 
    }

    const onRemoveClick = () => {
        handleClose();
        showDialog({
            message: '카테고리를 삭제하면 카테고리 내 제품도 삭제됩니다.\n삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/category/${category.id}?spaceId=${space.id}`)
                    .then((data) => {
                        showSnackbar('삭제했습니다.');
                        dispatch({
                            type: 'category_removed',
                            id: category.id
                        });
                    })
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    const onSelectCategory = () => {
        setSearchParams({
            categoryId: category.id
        });
    }

    // --- manage props ---
    const [ managePropsDialogOpen, setManagePropsDialogOpen ] = useState(false);
    const onManagePropsClick = () => { setManagePropsDialogOpen(true); }
    // --- end: manage props ---

    return (
        <>
            <Card sx={{ bgcolor: category.id === categoryId ? colors.lime : 'white' }} variant='outlined'>
                <CardContent sx={{ position: 'relative' }}>
                    <Typography variant='body1' sx={{ fontSize: 14 }} color="text.primary" gutterBottom>{category.name}</Typography>
                    <EditIcon sx={{ position: 'absolute', right: '12px', top: '12px', cursor: 'pointer' }} fontSize="small" color="action" onClick={handleIconClick} />
                    <Typography variant="caption">순번: {category.order}</Typography><br />
                    <Typography variant="caption">속성: {category.props?.length > 0 ? `${category.props.map(el => el.name).join(', ')}` : '-'}</Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" variant="contained" color="primary" onClick={onSelectCategory} disabled={category.id === categoryId}>선택</Button>
                    <Button size="small" variant="contained" color="info" onClick={onManagePropsClick}>속성관리</Button>
                </CardActions>
            </Card>
            <ContextMenu
                anchorEl={anchorel}
                open={open}
                onClose={handleClose}
            >
                <MenuItem sx={{ width: 200 }} onClick={onModifyClick}>
                    <ListItemText>수정</ListItemText>
                </MenuItem>
                <MenuItem sx={{ width: 200 }} onClick={onRemoveClick}>
                    <ListItemText>삭제</ListItemText>
                </MenuItem>
            </ContextMenu>

            <CategoryFormModal
                open={formOpen}
                handleClose={() => { setFormOpen(false); }}
                category={category}
                spaceId={space.id}
            />

            <ManagePropsDialog
                open={managePropsDialogOpen}
                handleClose={() => { setManagePropsDialogOpen(false); }}
                category={category}
            />
        </>
    );
}

const Categories = () => {
    const space = useSpace();

    // --- 추가 ---
    const [ formOpen, setFormOpen ] = useState(false);
    const handleClose = () => {
        setFormOpen(false);
    }

    const handleAddCategoryClick = () => {
        setFormOpen(true);
    }
    // --- end: 추가 ---

	return (
        <>
            <Title>카테고리</Title>
            <Box sx={{ mt: 3, minHeight: 100, display: 'flex', flexDirection: 'column' }}>
                {space.categories.length === 0 && <Guide>제품을 등록하기 전에 "카테고리"를 등록하세요.</Guide>}
                <Grid container spacing={1} sx={{ mb: 2 }}>
                    {space.categories.map(category => 
                        <Grid item key={category.id} xs={6} md={4} lg={3} xl={2}>
                            <Item 
                                category={category} 
                            />
                        </Grid>
                    )}
                </Grid>

                <Button variant="contained" color="primary" onClick={handleAddCategoryClick} sx={{ alignSelf: 'flex-start', marginTop: 'auto' }}>카테고리 등록</Button>
            </Box>

            <CategoryFormModal open={formOpen} handleClose={handleClose} spaceId={space.id} />
        </>
	);
}


export default Categories;