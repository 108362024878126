import { Box, Link as MuiLink, Select } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Nodata from './Nodata';


const NeedSignin = () => {
    return (
        <>
            <Nodata>로그인이 필요한 페이지입니다.</Nodata>
            <Box sx={{ mt: 1 }}>
                <Link to={'/auth/signin'} component={MuiLink}>로그인하기</Link>
            </Box>
        </>
    );
}

export default NeedSignin;