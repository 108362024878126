import React from "react";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box } from "@mui/material";


const Tip = ({ children }) => {
    return (
        <Box sx={{ backgroundColor: '#ff910026', p: 2, color: '#b26500', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <QuestionMarkIcon />
            <Box sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, display: 'flex', flexDirection: 'column' }}>
                {children}
            </Box>
        </Box>
    );
}

export default Tip;