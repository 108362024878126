export default function rowsReducer(rows, action) {
    switch (action.type) {
        case 'set': {
            const { rowIdx, columnIdx, value } = action;

            return rows.map((row, idx) => {
                if (idx === rowIdx) {
                    const newColumns = row.columns.map((column, idx) => {
                        if (idx === columnIdx) return value;
                        else return column;
                    });

                    return ({
                        ...row,
                        columns: newColumns,
                    });
                }
                else {
                    return row;
                }
            });
        }

        case 'beforeProcess': {
            const { rowIdx } = action;
            
            return rows.map((row, idx) => {
                if (idx === rowIdx) {
                    return ({
                        ...row,
                        process: {
                            state: 'loading',
                        }
                    });
                }
                else {
                    return row;
                }
            });
        }
        
        case 'processed': {
            const { rowIdx } = action;
            
            return rows.map((row, idx) => {
                if (idx === rowIdx) {
                    return ({
                        ...row,
                        process: {
                            state: 'processed',
                        }
                    });
                }
                else {
                    return row;
                }
            });
        }

        case 'processFailed': {
            const { rowIdx, message } = action;
            
            return rows.map((row, idx) => {
                if (idx === rowIdx) {
                    return ({
                        ...row,
                        process: {
                            state: 'processFailed',
                            message,
                        }
                    });
                }
                else {
                    return row;
                }
            });
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}