import React from 'react';
import { useItem } from 'contexts/item.context';
import SimpleHistory from 'components/action/simple-history/SimpleHistory';



const ItemActions = () => {
    const item = useItem();

    if (!item) return null;

	return (
		<SimpleHistory intend={'item'} itemId={item.id} />
	);
}

export default ItemActions;