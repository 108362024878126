import React, { useState, useEffect, useContext } from 'react';
import { Box, Divider, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Dialog, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import Title from 'components/Title';
import useGetMyBuckets from 'api/bucket/useGetMyBuckets';
import Guide from 'components/Guide';
import BucketTable from './BucketTable';
import DataListWrapper from 'components/DataListWrapper';
import BucketWithAction from './BucketWithAction';
import { useSpace } from 'contexts/space_v2.context';


const BucketWritePage = () => {
    const { showSnackbar, showDialog } = useContext(AppContext);
	const space = useSpace();

    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();
    const { loading, error, rows, mayMore, count, rowsPerPage, fetched } = useGetMyBuckets(page, space.id, 'making,rejected', trigger);

    // make bucket
    const [ makeModalOpen, setMakeModalOpen ] = useState(false);
    const [ bucketName, setBucketName ] = useState();
    const handleMakeModalClose = () => { setMakeModalOpen(false); }
    const handleCreateClick = () => { setMakeModalOpen(true); }
    const handleCreate = () => {
        const body = { 
            spaceId: space.id,
            name: bucketName || null,
        };
        API.post('/bucket/bucket', body)
        .then((bucket) => {
            showSnackbar('입출고명세서를 새로 만들었습니다.');
            setPage(0);
            setTrigger(new Date().getTime());
            setMakeModalOpen(false);
            setSearchParams({
                bucketId: bucket.id
            });
        })
    }
    // end: make bucket

    // remove bucket
    const handleRemoveBucket = (bucket) => () => {
        showDialog({
            message: '작성중인 명세서를 취소하시겠습니까? 작성중인 내용이 삭제됩니다.',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/bucket/bucket/${bucket.id}`)
                    .then(() => { 
                        showSnackbar('버킷작성을 취소했습니다.');
                        setTrigger(new Date().getTime());
                    });
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    const [ selected, setSelected ] = useState();
    const [ bucketTrigger, setBucketTrigger ] = useState();
    const reloadBucket = () => { setBucketTrigger(new Date().getTime()); }
    const onBucketSelected = (bucket) => {
        setSearchParams({
            bucketId: bucket.id
        });
    }
    useEffect(() => {
        const bucketId = searchParams.get('bucketId');
        if (bucketId) {
            const params = { spaceId: space.id };
            API.get(`/bucket/${bucketId}`, { params })
            .then(setSelected);
        }
        else {
            setSelected(null);
        }
    }, [ searchParams, bucketTrigger ]);

	return (
        <Box>
            <Title style={{ marginBottom: '24px' }}>입출고명세서 작성</Title>
            <Guide style={{ marginBottom: '24px' }}>입출고명세서 작성은 매니저 또는 대리업체가 입출고 된 제품 변경사항을 "액션승인" 권한이 있는 관리자에게 승인받기 위함입니다.</Guide>
            <Button variant={'contained'} onClick={handleCreateClick} style={{ marginBottom: '8px' }}>새로 만들기</Button>

            <DataListWrapper
                loading={loading}
                fetched={fetched}
                length={rows.length}
            >
                <BucketTable
                    rows={rows} 
                    count={count} 
                    rowsPerPage={rowsPerPage}
                    page={page}
                    setPage={setPage}
                    onBucketSelected={onBucketSelected}
                    handleRemove={handleRemoveBucket}
                />
            </DataListWrapper>

            {selected && <>
                <Divider sx={{ mb: 2 }} />
                <BucketWithAction bucket={selected} reloadBucket={reloadBucket} reloadPage={() => { setTrigger(new Date().getTime()); }} />
            </>}

            <Dialog open={makeModalOpen} onClose={handleMakeModalClose}>
                <DialogTitle>입출고명세서 생성</DialogTitle>
                <DialogContent>
                    <DialogContentText>생성한 입출고명세서에 액션을 담을 수 있습니다.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="입출고명세서 타이틀을 입력하세요. (선택)"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={bucketName}
                        onChange={event => setBucketName(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMakeModalClose}>취소</Button>
                    <Button onClick={handleCreate}>새로 만들기</Button>
                </DialogActions>
            </Dialog>
        </Box>
	);
}

export default BucketWritePage;