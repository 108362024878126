module.exports = [
    {
        key: 'private',
        name: 'Private',
        description: '스페이스 멤버만 볼 수 있습니다.',
    },
    {
        key: 'public',
        name: 'Public',
        description: '모두가 볼 수 있습니다.',
    },
    {
        key: 'protected',
        name: 'Protected',
        description: '스페이스 멤버이거나 비밀번호를 알고 있는 사람만 볼 수 있습니다.',
    },
];