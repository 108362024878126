import { CircularProgress, Button } from '@mui/material';
import { green } from '@mui/material/colors';
import React from 'react';


export default function CircularButton({children, loading, disabled, style, buttonStyle, ...props}) {
    return (
        <div style={style}>
            <span style={{ position: 'relative' }}>
                <Button
                        {...props}
                        style={buttonStyle}
                        disabled={loading || disabled}
                    >
                        {children}
                </Button>
                {loading && <CircularProgress size={24} style={{ color: green[500], position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, }} />}
            </span>
        </div>
    );
}