import React, { useContext, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Stack } from '@mui/material';
import colors from 'constants/colors';
import Nodata from 'components/Nodata';
import PropFormModal from './PropFormModal';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import { useSpace, useSpaceDispath } from 'contexts/space_v2.context';


const Prop = ({ prop, category }) => {
    const { showDialog, showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const dispatch = useSpaceDispath();

    const [ open, setOpen ] = useState(false);

    const handleRemove = () => {
        showDialog({
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/prop/${prop.id}?spaceId=${space.id}`)
                    .then((data) => {
                        showSnackbar('삭제했습니다.');
                        dispatch({
                            type: 'prop_removed',
                            categoryId: category.id,
                            propId: prop.id
                        })
                    })
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    return (
        <>
            <tr>
                <th scop='row'>{prop.name}</th>
                <td>{prop.type === 'number' ? '정수' : '텍스트'}</td>
                <td>{prop.order}</td>
                <td>
                    <Stack direction={'row'} spacing={1} justifyContent={'center'}>
                        <Button variant='outlined' size='small' onClick={() => { setOpen(true) }}>수정</Button>
                        <Button variant='outlined' color='error' size='small' onClick={handleRemove}>삭제</Button>
                    </Stack>
                </td>
            </tr>

            <PropFormModal
                open={open}
                handleClose={() => { setOpen(false); }}
                categoryId={category.id}
                prop={prop}
            />
        </>
    );
}

const ManagePropsDialog = ({ open, handleClose, category }) => {
    const onAddPropClick = () => {
        setFormOpen(true);
    }

    const [ formOpen, setFormOpen ] = useState(false);

    if (!category) return null;

	return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>속성 관리 <span style={{ fontSize: '.8em', color: colors.textSecondary }}>{category.name}</span></DialogTitle>

                <DialogContent>
                    <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                        <thead>
                            <tr>
                                <th scope="col">속성명</th>
                                <th scope="col">타입</th>
                                <th scope="col">순번</th>
                                <th scope="col">관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {category.props.map(prop => <Prop key={prop.id} prop={prop} category={category} />)}
                        </tbody>
                    </table>
                    {category.props.length === 0 && <Nodata>추가 속성이 없습니다.</Nodata>}
                </DialogContent>

                <DialogActions>
                    <Button color={'primary'} type="button" onClick={onAddPropClick}>속성추가</Button>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>닫기</Button>
                </DialogActions>
            </Dialog>

            <PropFormModal
                open={formOpen}
                handleClose={() => { setFormOpen(false); }}
                categoryId={category.id}
            />
        </>
	);
}

export default ManagePropsDialog;