import { Box, Typography, Link as MuiLink } from '@mui/material';
import UnderMaintenance from 'components/UnderMaintenance';
import { MemoryContext } from 'contexts/memory.context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';



function App() {
    const protocol = useMemo(() => window?.location.protocol, []);

    const { appCommonInfo } = useContext(MemoryContext);

    const [ serverMaintenanceUntil, setServerMaintenanceUntil ] = useState();
    useEffect(() => {
        setServerMaintenanceUntil(appCommonInfo?.settings?.server_maintenance_until);
    }, [ appCommonInfo ]);

    const [ messageClose, setMessageClose ] = useState(false);
    const globalMessage = useMemo(() => {
        if (appCommonInfo?.settings?.global_message) {
            return appCommonInfo?.settings?.global_message;
        }
    }, [ appCommonInfo ]);

    if (serverMaintenanceUntil) return (<UnderMaintenance until={serverMaintenanceUntil} />)

    if (process.env.NODE_ENV === 'production' && protocol === 'http:') return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Typography variant='h6' marginBottom={3}>보안을 위해 https: 프로토콜을 이용하세요.</Typography>
            <MuiLink variant='contained' href={process.env.REACT_APP_URL}>안전한 사이트로 이동</MuiLink>
        </Box>
    );

    return (
        <Box>
            <Outlet />

            {!!globalMessage && !messageClose && <Box sx={{ zIndex: 50,
                position: 'fixed',
                right: 0,
                top: { xs: 56, sm: 64 },
                backgroundColor: 'black',
                opacity: 0.6,
                paddingY: 3,
                paddingX: 4,
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
            }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', whiteSpace: 'pre-line' }}>{globalMessage}</Typography>
                <Typography onClick={() => { setMessageClose(true); }} variant='body1' sx={{ alignSelf: 'flex-end', marginTop: '8px', textDecoration: 'underline', cursor: 'pointer', display: 'inline', fontWeight: 'bold' }}>닫기</Typography>
            </Box>}
        </Box>
    );
}

export default App;