import pageStyles from 'css/page-style.module.css';


export default (label, value) => (
    <div className={pageStyles.row}>
        <div className={pageStyles.label}>
            <div className={pageStyles.labelText}>{label}</div>
        </div>
        <div className={pageStyles.content}>
            <div style={{ whiteSpace: 'pre-line' }} >{value}</div>
        </div>
    </div>
);