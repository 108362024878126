import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Chart from 'chart.js/auto';


const ItemAmountChart = ({ sampleby, data }) => {
    const labels = useMemo(() => {
        if (sampleby === 'month') {
            return data.map(el => (`${el.year}-${el.month}`));
        }
        else if (sampleby === 'week') {
            return data.map(el => (`${el.year}-${el.week}`));
        }
        else if (sampleby === 'day') {
            return data.map(el => (`${el.year}-${el.day}`));
        }
    }, [ data ]);

    const dataItem = useMemo(() => {
        return data.map(el => el.amount);
    }, [ data ])

    useEffect(() => {
        const ctx = document.getElementById('myChart');

        const data = {
            labels: labels,
            datasets: [
                {
                    label: '재고량',
                    data: dataItem,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                }
            ],
        };
        
        const config = {
            type: 'line',
            data: data,
        };

        const chart = new Chart(ctx, config);

        return (() => {
            chart.destroy();
        })
    }, []);

	return (
		<Box>
            <canvas id="myChart"></canvas>
		</Box>
	);
}

export default ItemAmountChart;