import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import { useSpace, useSpaceDispath } from 'contexts/space_v2.context';



const CategoryFormModal = ({ open, handleClose, category }) => {
    const { showSnackbar } = useContext(AppContext);

    const space = useSpace();
    const dispatch = useSpaceDispath();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: category?.id,
            spaceId: space.id,
            name: category?.name || '',
            order: category?.order,
        },
        validationSchema: yup.object({
            name: yup.string().required('필수입력입니다.'),
            order: yup.number().integer('정수를 입력해주세요.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = {
                spaceId: space.id,
                data: values,
                order: category ? yup.number().required('필수입력입니다.') : yup.number().nullable(),
            };
            
            if (!category) {
                API.post('/category', body)
                .then((data) => {
                    showSnackbar('새로 만들었습니다.');
                    resetForm();
                    handleClose();
                    dispatch({
                        type: 'category_added',
                        value: {
                            ...data,
                            props: []
                        },
                    })
                })
                .finally(() => { setSubmitting(false); });
            }
            else {
                API.put('/category', body)
                .then((data) => {
                    showSnackbar('수정했습니다.');
                    handleClose();
                    dispatch({
                        type: 'category_modified',
                        id: category.id,
                        value: {
                            ...values,
                            props: category.props
                        },
                    })    
                })
                .finally(() => { setSubmitting(false); });
            }
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, getFieldProps, handleSubmit, touched, errors } = formik;

    if (!space) return null;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {category ? category.name + ' 수정' : '카테고리 등록'}
                </DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        label={'카테고리'}
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={!!(touched.name && errors.name)}
                        helperText={(touched.name && errors.name) || '예를들어, 신발제조판매업의 경우, 운동화 또는 깔창 이 될 수 있습니다.'}
                        {...getFieldProps('name')}
                    />

                    {!!category && <TextField
                        type="number"
                        margin='normal'
                        fullWidth
                        label={'순번'}
                        variant="outlined"
                        size="small"
                        error={!!(touched.order && errors.order)}
                        helperText={(touched.order && errors.order) || ' '}
                        {...getFieldProps('order')}
                    />}
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>확인</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}


export default CategoryFormModal;