import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import TicketWritePage from './TicketWritePage';
import TicketPage from './TicketPage';


const TicketTab = () => {
	const location = useLocation();

    const intend = useMemo(() => {
        return location.state?.intend || 'list';
    }, [ location ]);

    if (intend === 'form') return (<TicketWritePage />);
    else return (<TicketPage />);
}

export default TicketTab;