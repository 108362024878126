import React, { useState, useContext } from 'react';
import { Card, CardActions, CardContent, ListItemText, MenuItem, Typography, Link, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AppContext } from 'contexts/app.context';
import ContextMenu from 'components/ContextMenu';
import { page_division_pipe } from 'pipes';
import VisibilityModal from './VisibilityModal';
import visibilities from './visibilities';


const PaperCard = ({
    paper,
    handleModifyTitle,
    handleRemove,
    handleChangeVisibility
}) => {
    const { showDialog, showSnackbar } = useContext(AppContext);

    const [anchorel, setAnchorel] = useState(null);
    const open = Boolean(anchorel);
    const handleIconClick = (event) => {
        setAnchorel(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorel(null);
    };

    const handleModifyTitleClick = () => {
        handleClose();
        const title = prompt('변경할 제목을 입력해주세요.');
        if (title) handleModifyTitle(paper, title);
    }

    const [ visibilityModalOpen, setVisibilityModalOpen ] = useState(false);
    const onModifyVisibilityClick = () => {
        handleClose();
        setVisibilityModalOpen(true);
    }

    const handleRemoveClick = () => {
        showDialog({
            message: '삭제하시겠습니까?',
            first: {
                text: '예',
                onClick: () => {
                    handleRemove(paper);
                }
            },
            second: {
                text: '아니오',
            }
        });
    }

    const onClipboardUrl = async () => {
        await navigator.clipboard.writeText(`${process.env.REACT_APP_HOST}/papers/${paper.uuid}`);
        showSnackbar('클립보드에 링크가 복사되었습니다.');
    }

    return (
        <>
            <Card variant='outlined'>
                <CardContent sx={{ position: 'relative' }}>
                    <Typography variant='body1' sx={{ fontSize: 14 }} color="text.primary" gutterBottom>{paper.title || '제목없음'}</Typography>
                    <EditIcon sx={{ position: 'absolute', right: '12px', top: '12px', cursor: 'pointer' }} fontSize="small" color="action" onClick={handleIconClick} />
                    <Typography variant="caption">{page_division_pipe(paper.meta.division)}</Typography>
                    <br/><Typography variant="caption">{visibilities.find(el => el.key === paper.visibility).name}</Typography>
                </CardContent>

                <CardActions sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start'  }}>
                    <Button size="small" variant="outlined" component={Link} color="info" target="_blank" href={`${process.env.REACT_APP_HOST}/papers/${paper.uuid}`}>보기</Button>
                    <Button size="small" sx={{ ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 } }} variant="outlined" color="secondary" onClick={onClipboardUrl}>공유하기: URL 복사</Button>
                </CardActions> 
            </Card>

            <ContextMenu
                anchorEl={anchorel}
                open={open}
                onClose={handleClose}
            >
                <MenuItem sx={{ width: 200 }} onClick={handleModifyTitleClick}>
                    <ListItemText>제목변경</ListItemText>
                </MenuItem>
                <MenuItem sx={{ width: 200 }} onClick={onModifyVisibilityClick}>
                    <ListItemText>조회권한 설정</ListItemText>
                </MenuItem>
                <MenuItem sx={{ width: 200 }} onClick={handleRemoveClick}>
                    <ListItemText>삭제</ListItemText>
                </MenuItem>
            </ContextMenu>

            <VisibilityModal open={visibilityModalOpen} setOpen={setVisibilityModalOpen} paper={paper} handleSubmit={handleChangeVisibility} />
        </>
    );
}

export default PaperCard;