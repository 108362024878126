import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';



const MemberRequestModal = ({ open, handleClose }) => {
    const { showSnackbar } = useContext(AppContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            spaceUUID: '',
        },
        validationSchema: yup.object({
            spaceUUID: yup.string().required('필수입력입니다.').length(6, '6자리로 입력해주세요.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            API.post('/request/send-spacemember-request', values)
            .then((data) => {
                resetForm();
                showSnackbar('요청했습니다.\n스페이스 관리자가 답변합니다.');
                handleClose();
            })
            .finally(() => { setSubmitting(false); });
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

	return (
        
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {'스페이스 참여 요청하기'}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>{'스페이스 코드는 "계정 - 스페이스" 페이지 에서 확인할 수 있습니다. 스페이스 관리자 또는 회원에게 물어보세요.'}</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        label={'스페이스 코드'}
                        fullWidth
                        variant="standard"
                        error={!!errors.spaceUUID}
                        helperText={(touched.spaceUUID && errors.spaceUUID) || ' '}
                        {...getFieldProps('spaceUUID')}
                    />
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>확인</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}


export default MemberRequestModal;