import React, { useMemo } from 'react';
import { Box, Card, CardActions, CardContent, Grid, Typography, Button } from '@mui/material';
import Guide from 'components/Guide';
import Title from 'components/Title';
import colors from 'constants/colors';
import Trader from '../Trader';
import { useSearchParams } from 'react-router-dom';
import { useSpace } from 'contexts/space_v2.context';


const TraderItem = ({ trader, handleSelect, selected }) => {
    return (
        <Card variant='outlined' sx={{ bgcolor: trader.id === selected?.id ? colors.lime : 'white' }}>
            <CardContent sx={{ position: 'relative' }}>
                <Typography variant='body1' sx={{ fontSize: 14 }} color="text.primary" gutterBottom>{trader.name}</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>순번: {trader.order}</Typography>
            </CardContent>
            <CardActions>
                <Button size="small" variant="contained" color="primary" onClick={handleSelect(trader)} disabled={trader.id === selected?.id}>보기</Button>
            </CardActions>
        </Card>
    );
}

const TraderHistoryPage = () => {
	const space = useSpace();
    const [ searchParams, setSearchParams ] = useSearchParams();

    const handleSelect = (trader) => () => {
        setSearchParams({
            traderId: trader.id
        });
    }

    const selected = useMemo(() => {
        const traderId = searchParams.get('traderId');
        if (traderId && space.traders) return space.traders.find(el => el.id == traderId);
    }, [ searchParams, space.traders ]);
    
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Title>거래처 현황</Title>

            {space.traders.length === 0 && <Guide>거래처가 없습니다.</Guide>}
            <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
                {space.traders.map(trader => <Grid item key={trader.id} xs={6} md={4} lg={3} xl={2}>
                    <TraderItem
                        trader={trader}
                        handleSelect={handleSelect}
                        selected={selected}
                    />
                </Grid>)}
            </Grid>

            {selected && <Trader space={space} trader={selected} />}
        </Box>
	);
}

export default TraderHistoryPage;