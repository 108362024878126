import React, { useState, useEffect, useContext } from 'react';
import { Typography, Dialog, FormControlLabel, DialogContent, DialogActions, DialogTitle, FormControl, Radio, RadioGroup, FormLabel, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import colors from 'constants/colors';
import Guide from 'components/Guide';


const AddActionModal = ({ open, setOpen, orderitem, places, handleSubmit }) => {
    const { showSnackbar } = useContext(AppContext);
	
    const [ selected, setSelected ] = useState();       // Place.id

    const onConfirm = () => {
        if (!selected) return showSnackbar('창고를 선택해주세요.');
        setOpen(false);
        handleSubmit(selected);
    }

    useEffect(() => {
        if (!open) {
            setSelected(null);
        }
    }, [ open ]);

    if (!orderitem || !places) return null;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={() => { setOpen(false); }}>
            <DialogTitle>출고 처리</DialogTitle>

            <DialogContent>
                <Guide>아래사항을 출고액션으로 처리합니다.</Guide>
                <FormControl sx={{ mt: 4 }}>
                    <FormLabel>창고선택</FormLabel>
                    <RadioGroup
                        value={selected}
                        onChange={event => setSelected(event.target.value)}
                    >
                        {places.map(place => <FormControlLabel key={place.id} value={place.id} control={<Radio />} label={place.name} />)}
                    </RadioGroup>
                </FormControl>
                
                <Typography sx={{mt: 3}} variant='body1' color={colors.textSecondary}>출고지</Typography>
                <Typography variant='body1'>{selected ? places.find(el => el.id == selected).name : ''}</Typography>
                <Typography sx={{mt: 2}} color={colors.textSecondary}>제품</Typography>
                <Typography variant='body1'>{`${orderitem.item.name} x ${orderitem.quantity}`}</Typography>
            </DialogContent>

            <DialogActions>
                <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>취소</Button>
                <Button type="submit" onClick={onConfirm}>확인</Button>
            </DialogActions>
        </Dialog>
	);
}

export default AddActionModal;