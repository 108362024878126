import React, { useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import ItemInfo from './ItemInfo';
import ItemActions from './ItemActions';
import TabPanel from 'components/TabPanel';
import Subtitle1 from 'components/document/Subtitle1';
import { useItem } from 'contexts/item.context';
import colors from 'constants/colors';


const TabInfo = [
    { key: 'info', name: '정보', component: <ItemInfo />  },
    { key: 'actions', name: '액션', component: <ItemActions /> },
];

const ItemDetail = ({ tab }) => {
    const item = useItem();

    const tabInitial = useMemo(() => {
        let idx = 0; 
        if (tab) {
            let tIdx = TabInfo.map(el => el.key).indexOf(tab);
            if (tIdx > -1) idx = tIdx;
        }
        return idx;
    }, [ tab ]);

    const [ tabIndex, setTabIndex ] = useState(tabInitial);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    if (!item) return null;

	return (
		<Box>
            <Subtitle1>제품 상세 <span style={{ fontSize: '.8em', color: colors.textSecondary }}>{item.name}</span></Subtitle1>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={+tabIndex} onChange={handleTabChange}> 
                    {TabInfo.map(item => <Tab key={item.key} label={item.name} />)}
                </Tabs>
            </Box>

            <Box sx={{ py: 2 }}>
                {TabInfo.map((item, index) => <TabPanel key={item.key} value={tabIndex} index={index}>
                    <span>{item.component}</span>
                </TabPanel>)}
            </Box>
        </Box>
	);
}

export default ItemDetail;