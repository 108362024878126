import React from 'react';
import { Box, Typography, Link as MuiLink, Divider, Button } from '@mui/material';
import { number_format_pipe } from 'pipes';
import { Link, useNavigate } from 'react-router-dom';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import colors from 'constants/colors';


const TraderSummary = ({ trader }) => {
    const navigate = useNavigate();

	return (
		<Box>
            <Box>
                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <CorporateFareIcon fontSize='small' sx={{ mr: 1, color: colors.releaseColor }} />
                        <MuiLink variant='h6' color={'textPrimary'} component={Link} to={`/space/history/trader?traderId=${trader.id}`}>{trader.name}</MuiLink>
                    </Box>
                    <Typography variant="overline" color="textSecondary">{`[총 출고량] ${number_format_pipe(trader.releaseAmount)}`}</Typography>
                </Box>
                <Divider />
                <Box display={'flex'} my={1}>
                    <Button variant='outlined' size="small" onClick={() => { navigate(`/space/history/trader?traderId=${trader.id}`) }}>현황</Button>
                    <Button variant='outlined' size="small" sx={{ ml: 1 }} onClick={() => { navigate(`/space/statistics/trader-release/trend?traderId=${trader.id}`) }}>월별통계</Button>
                </Box>
            </Box>
		</Box>
	);
}

export default TraderSummary;