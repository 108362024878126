import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Guide from 'components/Guide';
import PlaceSelection from 'components/PlaceSelection';
import getFromToGroupby from '../getFromToGroupby';
import { format } from 'date-fns';
import CircularButton from 'components/CircularButton';
import PeriodInput from 'components/PeriodInput';
import { useSpace } from 'contexts/space_v2.context';


const Filter = ({ searchDisabled }) => {
    const space = useSpace();

    const [ place, setPlace ] = useState();
    const [ processedFrom, setProcessedFrom ] = useState(null);
	const [ processedTo, setProcessedTo ] = useState(null);
    const [ keyword, setKeyword ] = useState('');
    const [ onlyActionExist, setOnlyActionExist ] = useState('N');

    const [searchParams, setSearchParams] = useSearchParams();

    const [ searchParamApplied, setSearchParamApplied ] = useState(false);
    useEffect(() => {
        if (!space) return;

        const placeId = searchParams.get('placeId') || '';
        const keyword = searchParams.get('keyword') || '';
        const processedFrom = searchParams.get('processedFrom');
		const processedTo = searchParams.get('processedTo');
        const onlyActionExist = searchParams.get('onlyActionExist') || '';

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby('day', processedFrom, processedTo);

        setSearchParamApplied(true);
        setKeyword(keyword);
        setProcessedFrom(fromDate);
		setProcessedTo(toDate);
        setOnlyActionExist(onlyActionExist);
        const place_a = space.places.find(el => el.id == placeId);
        setPlace(place_a);
    }, [ searchParams, space ]);

    const handlePlaceSelected = (selected) => {
        setSearchParams({
            keyword,
            processedFrom: format(processedFrom, 'yyyy-MM-dd'),
            processedTo: format(processedTo, 'yyyy-MM-dd'),
            placeId: selected?.id || '',
            onlyActionExist,
        })
    }

    const handleOnlyActionExistChange = event => {
        setSearchParams({
            keyword,
            processedFrom: format(processedFrom, 'yyyy-MM-dd'),
            processedTo: format(processedTo, 'yyyy-MM-dd'),
            placeId: place?.id || '',
            onlyActionExist: onlyActionExist === 'Y' ? 'N' : 'Y',
        });
    }

    const handleSearch = (event) => {
        event.preventDefault();

        setSearchParams({
            keyword,
            processedFrom: processedFrom ? format(processedFrom, 'yyyy-MM-dd') : '',
            processedTo: processedTo ? format(processedTo, 'yyyy-MM-dd') : '',
            placeId: place?.id || '',
            onlyActionExist,
            trigger: new Date().getTime()
        })
    }

	return (
		<form onSubmit={handleSearch}>
            <Box sx={{ mb: 4 }}> 
                <PlaceSelection places={space.places} selected={place} handlePlaceSelected={handlePlaceSelected} />
            </Box>

            <Stack spacing={1}>
                {searchParamApplied && <PeriodInput
                    initialStartDate={processedFrom}
                    initialEndDate={processedTo}
                    onPeriodChanged={(start, end) => {
                        setProcessedFrom(start);
                        setProcessedTo(end);
                    }}
                    options={{
                        baseDateText: '수행일시 기준'
                    }}
                />}

                <div className="mt-2 input-group input-group-sm" style={{ maxWidth: '640px' }}>
                    {/* Checkbox */}
                    <div className="input-group-text">
                        <input className="form-check-input" type="checkbox" checked={onlyActionExist === 'Y'} onChange={handleOnlyActionExistChange}  />
                    </div>
                    <input type="text" className="form-control" value={'액션이 있는 제품만 보기'} readOnly />

                    {/* 키워드 */}
                    <span className="input-group-text ms-1">키워드</span>
                    <input className='form-control' value={keyword} onChange={event => setKeyword(event.target.value)} />
                </div>
                
                <CircularButton type="submit" variant='contained' loading={searchDisabled}>검색</CircularButton>
            </Stack>

            {(!place) && <Box sx={{ my: 2 }}><Guide>창고를 선택하세요.</Guide></Box>}
		</form>
	);
}

export default Filter;