import { Box } from '@mui/material';
import React, { useMemo } from 'react';


const ValueInput = ({ prop, props, setFieldValue }) => {
    const targetProp = useMemo(() => {
        if (prop && props) {
            return props.find(el => el.id === prop.id);
        }
    }, [prop, props]) ;

    const val = useMemo(() => {
        if (!targetProp) return '';

        return prop?.type === 'number' ? targetProp.values.numVal : targetProp.values.val;
    }, [targetProp]) ;

    const handleChange = (event) => {
        const value = event.target.value;
        const key = prop?.type === 'number' ? 'numVal' : 'val';
        
        const idx = props.indexOf(targetProp);

        const target_value_a = {
            propId: prop.id
        };
        target_value_a[key] = value;
        const target_prop_a = {
            ...prop,
            values: target_value_a,
        }

        const props_a = [
            ...props,
        ];
        
        if (idx === -1) {
            props_a.push(target_prop_a);
        }
        else {
            props_a[idx] = target_prop_a;
        }
        setFieldValue('props', props_a);
    }

    return (
        <Box sx={{ pb: 2 }}>
            <div className="input-group input-group-sm">
                <span className="input-group-text" style={{ width: '80px' }}>{prop.name}</span>
                <input
                    className="form-control"
                    value={val}
                    onChange={handleChange}
                />
            </div>
        </Box>
    );
}


export default ValueInput;