import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import ItemList from './item/ItemList_v2';
import Categories from './category/Categories_v2';
import { useSearchParams } from 'react-router-dom';
import styles from 'styles.module.css';
import { useSpace } from 'contexts/space_v2.context';


const ItemPage = () => {
    const space = useSpace();

    const [ searchParams, setSearchParams ] = useSearchParams();
    useEffect(() => {
        if (space.categories.length === 1) {
            setSearchParams({
                'categoryId': space.categories[0].id,
            });
        }
    }, [ space.categories ]);

	return (
        <Box className={styles.page}>
            {/* categories */}
            <Categories />

            {/* items */}
            {space.categories.length > 0 && <Box sx={{ mt: 7 }}>
                <ItemList />
            </Box>}
        </Box>
	);
}

export { ItemPage };