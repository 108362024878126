import React, { useState, useContext } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, MenuItem, DialogActions, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import colors from 'constants/colors';
import { bucket_name_pipe } from 'pipes';
import Place from 'views/place/status/Place';
import Actions from 'views/action/actions';
import { useSpace } from 'contexts/space_v2.context';


const ActionPanel = ({ bucket, actionTriggered }) => {
	const { showSnackbar } = useContext(AppContext);
	const space = useSpace();

	const [ place, setPlace ] = useState();
	const [ trigger, setTrigger ] = useState();
	const [ items, setItems ] = useState();

	
	// actions
	const [ actionLoading, setActionLoading ] = useState(false);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ actionInfo, setActionInfo ] = useState();
    const [ trader, setTrader ] = useState();
    const [ toPlace, setToPlace ] = useState();

	const handleCommit = () => {
        if (['store', 'release'].indexOf(actionInfo.code) > -1 && trader) {
            for (let action of actionInfo.actions) {
                action.traderId = trader.id;
            }
        }
        if (actionInfo.needToPlace) {
            for (let action of actionInfo.actions) {
                action.toPlaceId = toPlace.id;
            }
        }

        const body = {
            spaceId: space.id,
            bucketId: bucket.id,
            actions: actionInfo.actions,
        };
        
        setActionLoading(true);
        API.post('/bucket/append-actions', body)
        .then(() => {
            actionInfo.callback();
            setTrader(null);
            actionTriggered();
        })
        .finally(() => { setActionLoading(false); })
    }

	const handleStore = (place, items) => () => {
        const items_s = items.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'store',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        setActionInfo({
            code: 'store',
            actions,
            intendText: '입고',
            description: `${bucket_name_pipe(bucket)}에 ${place.name} 입고 추가`,
            callback: () => {
                setTrigger(new Date().getTime());
                showSnackbar('입고 추가했습니다.');
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }

    const handleRelease = (place, items) => () => {
        const items_s = items.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'release',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        setActionInfo({
            code: 'release',
            actions,
            intendText: '출고',
            description: `${bucket_name_pipe(bucket)}에 ${place.name} 출고 추가`,
            callback: () => {
                setTrigger(new Date().getTime());
                showSnackbar('출고 추가했습니다.');
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }

    const handleAdjust = (place, items) => () => {
        const items_s = items.filter(item => item.checked && !isNaN(item.diff) && item.diff >= 0);

        const actions = items_s.map(item => ({
            code: 'adjustment',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        setActionInfo({
            code: 'adjustment',
            actions,
            intendText: '조정',
            description: `${bucket_name_pipe(bucket)}에 ${place.name} 조정 추가`,
            callback: () => {
                setTrigger(new Date().getTime());
                showSnackbar('조정 추가했습니다.');
                setModalOpen(false);
                setToPlace(null);
            },
        });

        setModalOpen(true);
    }

    const handleMove = (place, items) => () => {
        const items_s = items.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'move',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        setActionInfo({
            code: 'move',
            actions,
            intendText: '이동',
            needToPlace: true,
            description: `${bucket_name_pipe(bucket)}에 ${place.name} 이동 추가`,
            callback: () => {
                setTrigger(new Date().getTime());
                showSnackbar('이동 추가했습니다.');
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }

	return (
		<>
            <Box sx={{ flex: 1, ml: 3 }}>
                <Place
                    place={place} 
                    setPlace={setPlace} 
                    trigger={trigger}
                    items={items}
                    setItems={setItems}
                    actionLoading={actionLoading}
                    handleStore={handleStore}
                    handleRelease={handleRelease}
                    handleAdjust={handleAdjust}
                    handleMove={handleMove}
                />
            </Box>

			{actionInfo && <Dialog open={modalOpen} onClose={() => { setModalOpen(false); }}>
                <DialogTitle>{`${actionInfo.intendText}`}</DialogTitle>

                <DialogContent>
                    <DialogContentText>{actionInfo.description}</DialogContentText>
                    <Actions actions={actionInfo.actions} />

                    {['store', 'release'].indexOf(actionInfo.code) > -1 && space.traders.length > 0 && <TextField
                        select
                        fullWidth
                        sx={{ mt: 2 }}
                        size='small'
                        variant="outlined"
                        label={'선택: 거래처'}
                        onChange={event => { setTrader(event.target.value); }}
                        value={trader || ''}
                    >
                        {space.traders.map(trader => <MenuItem key={trader.id} value={trader}>{trader.name}</MenuItem>)}
                    </TextField>}

                    {actionInfo.needToPlace && <TextField
                        select
                        fullWidth
                        sx={{ mt: 2 }}
                        size='small'
                        variant="outlined"
                        label={'창고선택: 목적지'}
                        onChange={event => { setToPlace(event.target.value); }}
                        value={toPlace || ''}
                    >
                        {space.places.map(place => <MenuItem key={place.id} value={place}>{place.name}</MenuItem>)}
                    </TextField>}
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setModalOpen(false); }}>취소</Button>
                    <Button type="submit" onClick={handleCommit}>{actionInfo.intendText} 추가</Button>
                </DialogActions>
            </Dialog>}
		</>
	);
}

export default ActionPanel;