import React, { useState, useEffect, useContext } from 'react';
import { Box, Stack, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import InvitationFormModal from './InvitationFormModal';
import Title from 'components/Title';
import { useFetchSpace, useSpace } from 'contexts/space_v2.context';
import Nodata from 'components/Nodata';
import { request_state_pipe } from 'pipes';


const InvitationRequests = () => {
	const { showSnackbar, showDialog } = useContext(AppContext);
	const space = useSpace();
	const fetchSpace = useFetchSpace();

	// requests
    const [ requested, setRequested ] = useState();
    const [ received, setReceived ] = useState();
	const [ trigger, setTrigger ] = useState();
    useEffect(() => {
        if (!space) return;
        fetchData();
    }, [ space, trigger ]);
	const fetchData = () => {
		API.get('/request/space-requests/' + space.id)
        .then((data) => {
            setRequested(data['requests']);
            setReceived(data['receivedRequests']);
        })
	}

    const [ invitationModalOpen, setInvitationModalOpen ] = useState(false);
    const handleInvitationModalClose = () => {
        setInvitationModalOpen(false);
		setTrigger(new Date().getTime());
    }
    // end: requests

	const handleCancel = (row) => {
		showDialog({
			message: '요청을 취소하시겠습니까?',
			first: {
				onClick: () => {
					API.delete('/request/cancel-request/' + row.id)
					.then(() => {
						showSnackbar('취소했습니다.');
						setTrigger(new Date().getTime());
					});
				}
			},
			second: true,
		});
	}

	const handleConfirm = (row) => {
		const body = { requestId: row.id };
		API.put('/request/confirm-request', body)
		.then(() => {
			showSnackbar('확인했습니다.');
			setTrigger(new Date().getTime());
		});
	}

	const handleAccept = (row) => {
		const body = { 
			requestId: row.id,
			reply: 'Y'
		};
		API.put('/request/reply-request', body)
		.then(() => {
			showSnackbar('수락했습니다.');
			fetchSpace();
		});
	}

	const handleReject = (row) => {
		const body = { 
			requestId: row.id,
			reply: 'N'
		};
		API.put('/request/reply-request', body)
		.then(() => {
			showSnackbar('거절했습니다.');
			setTrigger(new Date().getTime());
		});
	}

	if (!requested || !received) return null;

	return (
		<>
			{/* 초대하기 */}
			<Box sx={{ mt: 5 }}>
				<Title style={{ marginBottom: '16px' }}>요청한 초대</Title>
				{requested.length > 0 ? <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">이메일</th>
                            <th scope="col">상태</th>
                            <th scope="col">관리</th>
                        </tr>
                    </thead>
                    <tbody>
						{requested.map(row => <tr key={row.id}>
                            <th scope="row">{row.email}</th>
                            <td>{request_state_pipe(row)}</td>
                            <td>
								<Stack direction={'row'} spacing={1} justifyContent={'center'}>
									{row.state === 'requested' && <Button variant='outlined' size='small' color="error" onClick={() => handleCancel(row)}>취소</Button>}
									{row.state === 'replied' && <Button variant='outlined' size='small' onClick={() => handleConfirm(row)}>확인</Button>}
								</Stack>
							</td>
                        </tr>)}
                    </tbody>
                </table> : <Nodata>초대한 사용자가 없습니다.</Nodata>}
				<Button sx={{ mt: 1, alignSelf: 'flex-start' }} variant="contained" onClick={() => { setInvitationModalOpen(true); }}>초대하기</Button>
			</Box>

			{/* 받은요청 */}
			<Box sx={{ mt: 5 }}>
				<Title style={{ marginBottom: '16px' }}>받은 초대</Title>
				{received.length > 0 ? <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">이메일</th>
                            <th scope="col">상태</th>
                            <th scope="col">관리</th>
                        </tr>
                    </thead>
                    <tbody>
						{received.map(row => <tr key={row.id}>
                            <th scope="row">{row.email}</th>
                            <td>{request_state_pipe(row)}</td>
                            <td>
								{row.state === 'requested' && <Stack direction={'row'} spacing={1} justifyContent={'center'}>
									<Button variant='outlined' size='small' color="success" onClick={() => handleAccept(row)}>수락</Button>
									<Button variant='outlined' size='small' color="error" onClick={() => handleReject(row)}>거절</Button>
								</Stack>}
							</td>
                        </tr>)}
                    </tbody>
                </table> : <Nodata>초대받은 요청이 없습니다.</Nodata>}
			</Box>

			<InvitationFormModal open={invitationModalOpen} handleClose={handleInvitationModalClose} />
		</>
	);
}

export default InvitationRequests;