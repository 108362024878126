import React from 'react';
import { Box, Typography } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import { actioncode_color_pipe, actioncode_pipe, bucket_state_pipe, datetime_pipe, item_name_pipe, number_format_pipe } from 'pipes';


const Bucket = ({ bucket }) => {
	const row = (label, value) => (
		<div className={pageStyles.row}>
			<div className={pageStyles.label}>
				<Typography variant="body2" className={pageStyles.labelText}>{label}</Typography>
			</div>
			<div className={pageStyles.content}>
				<Typography variant="body2" whiteSpace={'pre-line'}>{value}</Typography>
			</div>
		</div>
	); 

	return (
		<Box>
			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
				</Box>
				<div className={pageStyles.table}>
					{row('타이틀', bucket.name || `입출고명세서_${bucket.id}`)}
					{row('작성자', bucket.user.nickname)}
					{row('집행자', bucket.responder?.nickname || '-')}
					{row('상태', bucket_state_pipe(bucket.state))}
					{row('요청수행일시', datetime_pipe(bucket.processTime))}
					{row('메모', bucket.memo)}
				</div>
			</div>
			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>히스토리</Typography>
				</Box>
				<div className={pageStyles.table}>
					{row('작성일시', datetime_pipe(bucket.createdAt))}
					{row('제출일시', datetime_pipe(bucket.submittedAt))}
					{row('수행일시', datetime_pipe(bucket.confirmedAt))}
					{row('반려일시', datetime_pipe(bucket.rejectedAt))}
				</div>
			</div>

			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>액션</Typography>
				</Box>
				<div className={pageStyles.table}>
					<div className={pageStyles.row}>
						<div style={{ flex: 2, padding: '8px 16px' }}><Typography variant="body2">제품</Typography></div>
						<div style={{ flex: 2, padding: '8px 16px' }}><Typography variant="body2">거래처 / 창고</Typography></div>
						<div style={{ flex: 2, padding: '8px 16px' }}><Typography variant="body2">비고</Typography></div>
						<div style={{ flex: 1, padding: '8px 16px', textAlign: 'right' }}><Typography variant="body2">수량</Typography></div>
					</div>
					{bucket.actionObjects.map((obj) => {
						const action = obj.action;
						let primary = item_name_pipe(action.item);
						if (action.item.values) primary += ` (${action.item.values})`;
						
						let secondary = action.place.name;
						if (action.toPlace) secondary += ` => ${action.toPlace.name}`;
						if (action.trader) secondary += `, ${action.trader.name}`;

						return (<div key={obj.id} className={pageStyles.row}>
							<div className={pageStyles.label} style={{ flex: 2, justifyContent: 'flex-start' }}>
								<Typography variant="body2" className={pageStyles.labelText}><span><span style={{ color: actioncode_color_pipe(action.code) }}>{actioncode_pipe(action.code)}</span> {primary}</span></Typography>
							</div>
							<div className={pageStyles.content} style={{ flex: 2 }}>
								<Typography variant="body2">{secondary}</Typography>
							</div>
							<div className={pageStyles.content} style={{ flex: 2 }}>
								<Typography variant="body2">{obj.deletedAt ? '수행취소됨: ' + datetime_pipe(obj.deletedAt) : '-'}</Typography>
							</div>
							<div className={pageStyles.content} style={{ flex: 1, textAlign: 'right' }}>
								<Typography variant="body2">{number_format_pipe(action.amount)}</Typography>
							</div>
						</div>)
					})}
				</div>
			</div>
		</Box>
	);
}

export default Bucket;