import { Box, Card, CardContent, Divider, Typography, Button } from '@mui/material';
import React from 'react';
import colors from 'constants/colors';
import globals from 'constants/globals';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';


const UnderMaintenance = ({ until }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h3' style={{ marginBottom: '8px', color: colors.primary, fontWeight: 'bold' }}>{globals.appName}</Typography>
            <Card>
                <CardContent style={{ paddingTop: '32px', paddingBottom: '24px' }}>
                    <Box display='flex' alignItems='center' justifyContent='center'>
                        {/* <SearchIcon fontSize='large' color='primary' /> */}
                        <Typography variant='h4' style={{ marginLeft: '8px' }}>현재 서버점검중입니다.</Typography>
                    </Box>

                    <Typography variant='body2' style={{ margin: '24px 16px 0 16px', whiteSpace: 'pre-line' }}>{globals.appName}을 찾아주신 고객님,<br />{`${format((new Date(until)), 'MM월 dd일 hh시mm분')} 까지 서버점검이 있습니다.\n종료시각이후에 새로고침을 하시거나 재실행하셔서 앱을 사용해주세요.`}</Typography>

                    <Divider style={{ margin: '24px 0 16px 0' }} />
                    <Box>
                        <Button variant='contained' onClick={() => { window.location.reload(); }}>새로고침</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default UnderMaintenance;