import React, { useContext, useReducer, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import BatchRegisterTable from './BatchRegisterTable';
import { RowsContext, RowsDispatchContext, RowsProcessContext } from './Rows.context';
import rowsReducer from './rowsReducer';
import selectedReducer from 'reducer/selectedReducer';
import { AppContext } from 'contexts/app.context';
import { useNavigate } from 'react-router-dom';
import API from 'api';
import { useSpace } from 'contexts/space_v2.context';


const convertFromValuesToItem = (headers, columns) => {
    function getIdx(division, key) {
        const idx = headers.findIndex(el => el.division === division && el.key === key);
        return idx;
    }

    const values = headers.filter(header => header.division === 'prop').map((header, idx) => {
        return ({
            propId: header.key,
            val: header.type === 'number' ? null : columns[getIdx('prop', header.key)],
            numVal: header.type === 'string' ? null : columns[getIdx('prop', header.key)],
        })
    });

    const placeItemAmounts = [];
    const placeHeaders = headers.filter(header => header.division === 'place');
    for (let header of placeHeaders) {
        const amount = columns[getIdx('place', header.key)];
        if (amount === '0' || !!amount) {
            placeItemAmounts.push({
                placeId: header.key,
                amount,
            })
        }
    }

    return ({
        name: columns[getIdx('base', 'name')],
        code: columns[getIdx('base', 'code')],
        values,
        placeItemAmounts,
    });
}


const BatchRegister = ({ categoryId, headers, initialRows }) => {
    const { showAlert, showDialog } = useContext(AppContext);
	const space = useSpace();
    const navigate = useNavigate();

	const [ rows, dispatch ] = useReducer(rowsReducer, initialRows.map(el => (
        {
            columns: el,
            process: {
                state: 'ready',
            }
        }
    )));

    // selected checkbox
    const [ selected, dispatchSelected ] = useReducer(selectedReducer, []);

    // options
    const [ blockDuplicateName, setBlockDuplicateName ] = useState(true);

    const handleBatchRegister = () => {
        if (selected.length === 0) return showAlert('등록할 제품을 선택(체크박스) 해주세요.');

        API.get(`/plan/plan-status/${space.id}`)
        .then((result) => {
            if (result.itemVolume.remains < selected.length) {
                const dialogData = {
                    title: '플랜 제한',
                    message: '요금플랜의 제한 범위를 넘어섰습니다.\n현재 데이터 사용량을 확인하시겠습니까?',
                    first: {
                        onClick: () => {
                            navigate('/space/billing/usage');
                        }
                    },
                    second: true,
                }
                showDialog(dialogData);
            }
            else {
                // 서버에서 순서대로 validation 확인이 되도록 serial 방식
                selected.reduce((p, rowIdx) => {
                    return p.then(() => handleAddItem(rowIdx));
                }, Promise.resolve());
            }
        })
    }

    const handleAddItem = async (rowIdx) => {
        if (rows[rowIdx].process.state === 'processed') return;

        const columns = rows[rowIdx].columns;

        const item = convertFromValuesToItem(headers, columns);

        const body = {
            spaceId: space.id,
            data: {
                spaceId: space.id,
                categoryId,
                ...item,
                blockDuplicateName,
            }
        };

        dispatch({
            type: 'beforeProcess',
            rowIdx,
        });

        const baseURL = process.env.NODE_ENV === 'development' ? '/api' : process.env.REACT_APP_API_URL;
        return fetch(`${baseURL}/item`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'platform': 'web',
            },
            credentials: 'include',
            body: JSON.stringify(body),
        })
        .then(response => {
            if (response.ok) {
                dispatch({
                    type: 'processed',
                    rowIdx,
                });
            }
            else {
                response.json()
                .then(data => {
                    dispatch({
                        type: 'processFailed',
                        rowIdx,
                        message: data?.message ?? '네트워크 응답이 실패했습니다.'
                    });
                })
            }
        })
        .catch(error => {
            dispatch({
                type: 'processFailed',
                rowIdx,
                message: '네트워크 응답이 실패했습니다.'
            });
        })
        .finally(() => {
            // console.log('done: ', rowIdx);
        })
	}

	return (
        <RowsContext.Provider value={rows}>
            <RowsDispatchContext.Provider value={dispatch}>
                <RowsProcessContext.Provider value={{
                    handleAddItem,
                }}>
                    {headers && rows && 
                        <Box mt={4}>
                            <Box display="flex" justifyContent='space-between' marginBottom={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={blockDuplicateName}
                                            onChange={(event) => { setBlockDuplicateName(val => !val) }}
                                        />
                                    }
                                    label="등록시 제품명 중복을 허용하지 않습니다."
                                />

                                <Button variant="contained" color="primary" onClick={handleBatchRegister}>선택항목 일괄등록</Button>
                            </Box>
                            <BatchRegisterTable 
                                headers={headers} 
                                selected={selected} 
                                dispatchSelected={dispatchSelected}
                            />
                        </Box>
                    }
                </RowsProcessContext.Provider>
            </RowsDispatchContext.Provider>
        </RowsContext.Provider>	
	);
}



export default BatchRegister;