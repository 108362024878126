import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import routes from 'Routes';
import { AppContextProvider } from 'contexts/app.context';
import { MemoryContextProvider } from 'contexts/memory.context';
import { AuthContextProvider } from 'contexts/auth.context';
import theme from 'theme';
import { GoogleOAuthProvider } from '@react-oauth/google';




function AppRoutes() {
    const element = useRoutes(routes);
    return element;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId='624739656350-v1fap69gk903cuekotrvl30nq5cn58t1.apps.googleusercontent.com'>
            <BrowserRouter>
                <AppContextProvider>
                    <AuthContextProvider>
                        <MemoryContextProvider>
                            <AppRoutes />
                        </MemoryContextProvider>
                    </AuthContextProvider>
                </AppContextProvider>
            </BrowserRouter>
        </GoogleOAuthProvider>
    </ThemeProvider>
);