import React from 'react';
import { Box, Typography } from '@mui/material';
import colors from 'constants/colors';


export default function Guide({ children, style }) {
    return (
        <Box style={{ borderLeft: '4px solid #ff6333', padding: '0.7rem 1rem', backgroundColor: colors.primary + '22', ...style }}>
            <Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}>{children}</Typography>
        </Box>
    );
}