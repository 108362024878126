import React, { useContext, useState } from 'react';
import { Button, Checkbox, Stack } from '@mui/material';
import PageContext from 'contexts/page.context';
import ItemFormModal from './ItemFormModal';
import { useSelected, useSelectedDispatch } from 'contexts/SelectedContext';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import { useSpace } from 'contexts/space_v2.context';



const ItemRow = ({ item }) => {
    const { showDialog, showSnackbar } = useContext(AppContext);
    const { setRows } = useContext(PageContext);
    const space = useSpace();

    const selected = useSelected();
    const selectedDispatch = useSelectedDispatch();

    const toggleCheckbox = () => {
        selectedDispatch({
            type: 'toggle', 
            id: item.id,
        });
    };

    const onCheckboxClick = (event) => {
        event.stopPropagation();
        toggleCheckbox();
    }
    
    const isChecked = selected.indexOf(item.id) > -1;

    const onModifyClick = (event) => {
        event.stopPropagation();
        setFormOpen(true);
    }

    const onRemoveClick = (event) => {
        event.stopPropagation();

        showDialog({
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '예',
                onClick: () => {
                    API.delete(`/item/items?ids=${item.id}&spaceId=${space.id}`)
                    .then(() => {
                        showSnackbar('삭제했습니다.');
                        setRows(prevs => {
                            const result = [
                                ...prevs
                            ];
                            const index = result.findIndex(el => el.id == item.id);
                            if (index > -1) {
                                result.splice(index, 1);
                                return result;
                            }
                            else return result;
                        })
                    })
                }
            },
            second: {
                text: '아니오',
            }
        });
    }

    const [ formOpen, setFormOpen ] = useState(false);

    return (
        <>
            <td>
                <Checkbox
                    size='small'
                    checked={isChecked}
                    onClick={onCheckboxClick}
                />
            </td>
            <th scope='row'>{item.order}</th>
            <td>{item.name}</td>
            <td>{item.code}</td>
            <td style={{ textAlign: 'start' }}>{item.valSummaries}</td>
            <td>
                <Stack direction={'row'} spacing={1} justifyContent={'center'}>
                    <Button size='small' variant='outlined' onClick={onModifyClick}>수정</Button>
                    <Button size='small' variant='outlined' color='error' onClick={onRemoveClick}>삭제</Button>
                </Stack>
            </td>
            <ItemFormModal
                open={formOpen} setOpen={setFormOpen} itemId={item.id}
            />
        </>
    );
}

const ItemTable = () => {
    const { rows, lastElementRef } = useContext(PageContext);

    const selected = useSelected();
    const selectedDispatch = useSelectedDispatch();

    const onSelectAllClick = (event) => {
        selectedDispatch({
            type: 'all', 
            checked: event.target.checked,
            rows,
        });
    }

    if (!rows) return null

	return (
		<div className="mt-3 table-responsive">
            <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                <thead>
                    <tr>
                        <th scope="col">
                            <Checkbox
                                size='small'
                                onChange={onSelectAllClick}
                                checked={selected.length > 0 && selected.length === rows.length}
                            />
                        </th>
                        <th scope="col">순번</th>
                        <th scope="col">제품명</th>
                        <th scope="col">제품코드</th>
                        <th scope="col">속성</th>
                        <th scope="col">관리</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => <tr key={row.id} ref={index = rows.length - 1 ? lastElementRef : null}>
                        <ItemRow item={row} />
                    </tr>)}
                </tbody>
            </table>
        </div>
	);
}

export default ItemTable;