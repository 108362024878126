import React, { useState, useEffect, useContext } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import colors from 'constants/colors';



const PlaceSelectionModal = ({ open, setOpen, places, onSelect }) => {
    const { showSnackbar } = useContext(AppContext);
	
    const [ selected, setSelected ] = useState([]);

    const handleSubmit = () => {
        if (selected.length === 0) return showSnackbar('창고를 하나이상 선택해주세요.');
        onSelect(selected);
        setOpen(false);
    }

    useEffect(() => {
        if (!open) {
            setSelected([]);
        }
    }, [ open ]);

	return (
		<Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={() => { setOpen(false); }}>
            <DialogTitle>창고 선택</DialogTitle>

            <DialogContent>
                {places.map(place => <FormControlLabel key={place.id} sx={{ width: '140px' }} control={<Checkbox
                    checked={selected.indexOf(place.id + '') > -1} 
                    value={place.id}
                    onChange={event => {
                        setSelected((values) => {
                            const result = [
                                ...values
                            ];
                            const idx = result.indexOf(event.target.value);
                            if (idx > -1) {
                                result.splice(idx, 1);
                            }
                            else {
                                result.push(event.target.value);
                            }
                            return result;
                        });
                    }}
                />} label={place.name} />)}
                
                <Typography sx={{mt: 3}} variant='body2' color={colors.textSecondary}>선택된 창고: {selected.map(id => (places.find(el => el.id == id)).name ).join(', ')}</Typography>
            </DialogContent>

            <DialogActions>
                <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>취소</Button>
                <Button type="submit" onClick={handleSubmit}>확인</Button>
            </DialogActions>
        </Dialog>
	);
}

export default PlaceSelectionModal;