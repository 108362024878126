import { Box } from '@mui/material';
import React from 'react';


const PlaceInput = ({ values, place, setFieldValue }) => {
    const handleChange = (event) => {
        const val = event.target.value;

        const newValue = values.places.map(el => {
            if (el.placeId == place.placeId) {
                return ({
                    ...el,
                    amount: val
                });
            }
            else return el;
        })

        setFieldValue('places', newValue);
    }

    return (
        <Box sx={{ pb: 2 }}>
            <div className="input-group input-group-sm">
                <span className="input-group-text">{place.placeName + ' 내 제품 수량'}</span>
                <input
                    className="form-control"
                    value={place.amount}
                    onChange={handleChange}
                />
            </div>
        </Box>
    );
}


export default PlaceInput;