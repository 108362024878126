import React, { useContext, useMemo } from 'react';
import { Box, Typography, Paper, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import CircularButton from 'components/CircularButton';
import { MemoryContext } from 'contexts/memory.context';
import ErrorText from 'components/ErrorText';


const PasswordSigninPage = () => {
    const { syncAuth } = useContext(AuthContext);
	const { routeAfterLogin } = useContext(MemoryContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
			userId: '',
        },
        validationSchema: yup.object({
            email: yup.string().email('이메일 형식에 맞지 않습니다.').required('이메일을 입력해주세요.'),
            password: yup.string().required('비밀번호를 입력해주세요.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            API.post('/auth/password-signin', values)
            .then((data) => {
				return syncAuth();
            })
			.then(routeAfterLogin)
            .finally(() => { setSubmitting(false); });
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

	const isAdminEmail = useMemo(() => {
		return (values.email === 'admin@antpocket.io');
	}, [ values.email ]);

	return (
		<form onSubmit={handleSubmit}>
			<Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				<Paper sx={{ padding: 4, width: '320px', display: 'flex', flexDirection: 'column' }}>
					<Typography variant='h6' sx={{ alignSelf: 'center', mt: 1, mb: 4 }}>비밀번호로 로그인</Typography>

					<div className="input-group input-group-sm">
						{/* 이메일 */}
						<span className='input-group-text' style={{ width: '72px' }}>이메일</span>
						<input autoFocus className='form-control' type='emal' {...getFieldProps('email')} />
					</div>
					<ErrorText error={touched.email && errors.email} />

					<div className="input-group input-group-sm">
						{/* 비밀번호 */}
						<span className='input-group-text' style={{ width: '72px' }}>비밀번호</span>
						<input className='form-control' type='password' {...getFieldProps('password')} />
					</div>
					<ErrorText error={touched.password && errors.password} />
					
					{isAdminEmail && <div className="input-group input-group-sm">
						{/* 관리자 권한으로 로그인 */}
						<span className='input-group-text' style={{ width: '72px' }}>User.id</span>
						<input className='form-control' {...getFieldProps('userId')} />
					</div>}


					<MuiLink component={Link} variant={'body2'} to={'/auth/find-password'} underline={'none'} sx={{ mt: 2 }}>비밀번호를 잊어버리셨나요?</MuiLink>
					
					<Box sx={{ mt: 5, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
						<MuiLink component={Link} to={'/auth/password-signup'} sx={{ fontSize: '.9em', color: colors.primaryDark }} underline={'none'}>회원가입하기</MuiLink>
						<CircularButton type="submit" variant="contained" color="primary" loading={isSubmitting}>로그인</CircularButton>
					</Box>
				</Paper>
			</Box>
		</form>
	);
}


export default PasswordSigninPage;