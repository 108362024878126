import React, { useContext, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, MenuItem, Switch, TextField, useMediaQuery, useTheme } from '@mui/material';
import { PlacePageContext } from './PlaceStatusPage';
import Place from './Place';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import colors from 'constants/colors';
import Actions from 'views/action/actions';
import { formatISO, parse } from 'date-fns';
import { Box } from '@mui/system';
import Guide from 'components/Guide';
import { useSpace } from 'contexts/space_v2.context';


export default function PlaceStatus() {
    const { showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const { leftPlace, rightPlace, setLeftPlace, setRightPlace } = useContext(PlacePageContext);

    const [ rightEnable, setRightEnable ] = useState(false);
    const [ leftTrigger, setLeftTrigger ] = useState();
    const [ rightTrigger, setRightTrigger ] = useState();

    const [ leftItems, setLeftItems ] = useState();
    const [ rightItems, setRightItems ] = useState();

    const [ loading, setLoading ] = useState(false);
    const [ blockSubmitAt, setBlockSubmitAt ] = useState();

    // actions
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ actionInfo, setActionInfo ] = useState();
    const [ trader, setTrader ] = useState();
    const [ withProcessedAt, setWithProcessedAt ] = useState(false);
    const [ processedAt, setProcessedAt ] = useState(null);
    const [ toPlace, setToPlace ] = useState();

    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xl'));

    
    const handleCommit = () => {
        if (loading || (blockSubmitAt && (new Date().getTime()) - blockSubmitAt < 1000)) return;
        setBlockSubmitAt(new Date().getTime());

        if (['store', 'release'].indexOf(actionInfo.code) > -1 && trader) {
            for (let action of actionInfo.actions) {
                action.traderId = trader.id;
            }
        }
        if (actionInfo.needToPlace) {
            if (!toPlace) return showSnackbar('목적지 창고를 선택해주세요.');
            for (let action of actionInfo.actions) {
                action.toPlaceId = toPlace.id;
            }
        }

        const body = {
            spaceId: space.id,
            actions: actionInfo.actions,
        };
        if (withProcessedAt && processedAt) {
            body['processedAt'] = formatISO(parse(processedAt, 'yyyy-MM-dd', new Date()));
        }
        
        
        setLoading(true);
        API.post('/action/add-and-execute-actions', body)
        .then(() => {
            actionInfo.callback();
            setTrader(null);
        })
        .finally(() => { setLoading(false); })
    }

    const handleStore = (place, items) => () => {
        const items_s = items.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'store',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        const unknownAmountItemExist = !!items_s.find(item => item.rAmount === null);
        setActionInfo({
            code: 'store',
            actions,
            intendText: '입고',
            description: `다음의 제품을 ${place.name}(으)로 입고합니다.`,
            unknownAmountItemExist,
            callback: () => {
                const setTrigger = place === leftPlace ? setLeftTrigger : setRightTrigger;
                setTrigger(new Date().getTime());
                showSnackbar('입고했습니다.');
                setWithProcessedAt(false);
                setProcessedAt(null);
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }

    const handleRelease = (place, items) => () => {
        const items_s = items.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'release',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        const unknownAmountItemExist = !!items_s.find(item => item.rAmount === null);
        setActionInfo({
            code: 'release',
            actions,
            intendText: '출고',
            description: `다음의 제품을 ${place.name}에서 출고합니다.`,
            unknownAmountItemExist,
            callback: () => {
                const setTrigger = place === leftPlace ? setLeftTrigger : setRightTrigger;
                setTrigger(new Date().getTime());
                showSnackbar('출고했습니다.');
                setWithProcessedAt(false);
                setProcessedAt(null);
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }

    const handleAdjust = (place, items) => () => {
        const items_s = items.filter(item => item.checked && !isNaN(item.diff) && item.diff >= 0);

        const actions = items_s.map(item => ({
            code: 'adjustment',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        setActionInfo({
            code: 'adjustment',
            actions,
            intendText: '조정',
            description: `${place.name} 창고 내 제품 수량을 다음과 같이 조정합니다.`,
            callback: () => {
                const setTrigger = place === leftPlace ? setLeftTrigger : setRightTrigger;
                setTrigger(new Date().getTime());
                showSnackbar('조정했습니다.');
                setWithProcessedAt(false);
                setProcessedAt(null);
                setModalOpen(false);
                setToPlace(null);
            },
        });

        setModalOpen(true);
    }

    const handleMove = (place, items) => () => {
        const items_s = items.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'move',
            itemId: item.id,
            placeId: place.id,
            amount: item.diff,
            item,
        }));
        const unknownAmountItemExist = !!items_s.find(item => item.rAmount === null);
        setActionInfo({
            code: 'move',
            actions,
            intendText: '이동',
            needToPlace: true,
            description: `다음의 제품을 ${place.name} 창고에서 선택한 창고로 이동합니다.`,
            unknownAmountItemExist,
            callback: () => {
                const setTrigger = place === leftPlace ? setLeftTrigger : setRightTrigger;
                setTrigger(new Date().getTime());
                showSnackbar('이동했습니다.');
                setWithProcessedAt(false);
                setProcessedAt(null);
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }

    const handleMoveRight = () => {
        const items_s = leftItems.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'move',
            itemId: item.id,
            placeId: leftPlace.id,
            toPlaceId: rightPlace.id,
            amount: item.diff,
            item,
        }));
        const unknownAmountItemExist = !!items_s.find(item => item.rAmount === null);
        setActionInfo({
            code: 'move',
            actions,
            intendText: '이동',
            description: `다음의 제품을 ${leftPlace.name}에서 ${rightPlace.name}로 이동합니다.`,
            unknownAmountItemExist,
            callback: () => {
                setLeftTrigger(new Date().getTime());
                setRightTrigger(new Date().getTime());
                showSnackbar('이동했습니다.');
                setWithProcessedAt(false);
                setProcessedAt(null);
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }

    const handleMoveLeft = () => {
        const items_s = rightItems.filter(item => item.checked && item.diff && item.diff > 0);

        const actions = items_s.map(item => ({
            code: 'move',
            itemId: item.id,
            placeId: rightPlace.id,
            toPlaceId: leftPlace.id,
            amount: item.diff,
            item,
        }));
        const unknownAmountItemExist = !!items_s.find(item => item.rAmount === null);
        setActionInfo({
            code: 'move',
            actions,
            intendText: '이동',
            description: `다음의 제품 내역이 ${rightPlace.name}에서 ${leftPlace.name}로 출고됩니다.`,
            unknownAmountItemExist,
            callback: () => {
                setLeftTrigger(new Date().getTime());
                setRightTrigger(new Date().getTime());
                showSnackbar('이동했습니다.');
                setWithProcessedAt(false);
                setProcessedAt(null);
                setModalOpen(false);
            },
        });

        setModalOpen(true);
    }
    // end: actions

    // interface
    const rightMoveButtonEnabled = useMemo(() => {
        if (leftPlace && rightPlace && leftItems) {
            if (leftItems.filter(item => item.checked && item.diff && item.diff > 0).length > 0) return true;
        }
        return false;
    }, [ leftPlace, rightPlace, leftItems ]);

    const leftMoveButtonEnabled = useMemo(() => {
        if (leftPlace && rightPlace && rightItems) {
            if (rightItems.filter(item => item.checked && item.diff && item.diff > 0).length > 0) return true;
        }
        return false;
    }, [ leftPlace, rightPlace, rightItems ]);
    // end: interface

    return (
        <>
            {!isHandset && <Box sx={{ mb: 1, textAlign: 'right' }}>
                <FormControlLabel control={<Switch checked={rightEnable} onChange={event => setRightEnable(event.target.checked)} />} label="우측 패널 사용" />
            </Box>}
            

            <Grid container spacing={2} justifyContent="center">
                <Grid item sx={{ flex: 1 }}>
                    <Place 
                        place={leftPlace} 
                        setPlace={setLeftPlace} 
                        trigger={leftTrigger}
                        items={leftItems}
                        setItems={setLeftItems}
                        actionLoading={loading}
                        handleStore={handleStore}
                        handleRelease={handleRelease}
                        handleAdjust={handleAdjust}
                        handleMove={handleMove}
                    />
                </Grid>
                
                {rightEnable && !isHandset && <>
                    <Grid item sx={{ alignSelf: 'center' }}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                disabled={!rightMoveButtonEnabled}
                                onClick={handleMoveRight}
                                
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                disabled={!leftMoveButtonEnabled}
                                onClick={handleMoveLeft}
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ flex: 1 }}>
                        <Place 
                            place={rightPlace} 
                            setPlace={setRightPlace} 
                            trigger={rightTrigger}
                            items={rightItems}
                            setItems={setRightItems}
                            actionLoading={loading}
                            handleStore={handleStore}
                            handleRelease={handleRelease}
                            handleAdjust={handleAdjust}
                            handleMove={handleMove}
                        />
                    </Grid>
                </>}
            </Grid>
            
            {actionInfo && <Dialog open={modalOpen} onClose={() => { setModalOpen(false); }}>
                <DialogTitle>{`요약: ${actionInfo.intendText}`}</DialogTitle>

                <DialogContent>
                    <DialogContentText>{actionInfo.description}</DialogContentText>
                    <Actions actions={actionInfo.actions} code={actionInfo.code} />

                    {['store', 'release'].indexOf(actionInfo.code) > -1 && space.traders.length > 0 && <TextField
                        select
                        fullWidth
                        sx={{ mt: 2 }}
                        size='small'
                        variant="outlined"
                        label={'선택: 거래처'}
                        onChange={event => { setTrader(event.target.value); }}
                        value={trader || ''}
                    >
                        {space.traders.map(trader => <MenuItem key={trader.id} value={trader}>{trader.name}</MenuItem>)}
                    </TextField>}

                    {actionInfo.needToPlace && <TextField
                        select
                        fullWidth
                        sx={{ mt: 2 }}
                        size='small'
                        variant="outlined"
                        label={'창고선택: 목적지'}
                        onChange={event => { setToPlace(event.target.value); }}
                        value={toPlace || ''}
                    >
                        {space.places.map(place => <MenuItem key={place.id} value={place}>{place.name}</MenuItem>)}
                    </TextField>}

                    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel control={<Checkbox checked={withProcessedAt} onChange={event => {
                            setWithProcessedAt(!withProcessedAt)
                        }} />} label={<span style={{ color: colors.textSecondary }}>{`${actionInfo.intendText}일시 직접 설정하기`}</span>} />
                        <input
                            type="date"
                            className='form-control'
                            disabled={!withProcessedAt}
                            value={processedAt || ''}
                            onChange={(event) => { setProcessedAt(event.target.value); }}
                            placeholder='설정하지 않으면 현재시각으로 자동 설정됩니다.'
                        />
                    </Box>
                    
                    {actionInfo.unknownAmountItemExist && <Guide style={{ marginTop: '24px' }}>{'창고 내 초기수량이 지정되지 되지 않은 제품이 포함되어 있습니다.\n창고 내 제품 초기수량을 설정하지 않으면 0개로 가정합니다.\n초기 수량은 "조정" 액션으로 설정하실 수 있습니다.'}</Guide>}
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setModalOpen(false); }}>취소</Button>
                    <Button type="submit" onClick={handleCommit}>{actionInfo.intendText}</Button>
                </DialogActions>
            </Dialog>}
        </>
    );
}