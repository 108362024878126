import React from 'react';
import { Typography, Box, Grid, styled, Divider, Chip } from '@mui/material';


const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));


const ColorPage = () => {
    const content = (
        <div>
          {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id dignissim justo.
       Nulla ut facilisis ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus.
       Sed malesuada lobortis pretium.`}
        </div>
      );

    return (
        <Box>
            <Typography component="div" variant="body1">
                <Box color="primary.main">primary.main</Box>
                <Box color="secondary.main">secondary.main</Box>
                <Box color="error.main">error.main</Box>
                <Box color="warning.main">warning.main</Box>
                <Box color="info.main">info.main</Box>
                <Box color="success.main">success.main</Box>
                <Box color="text.primary">text.primary</Box>
                <Box color="text.secondary">text.secondary</Box>
                <Box color="text.disabled">text.disabled</Box>
            </Typography>
            
            <Grid container spacing={1} style={{ marginTop: '32px' }}>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="primary.main" color="primary.contrastText" p={2}>primary.main</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>secondary.main</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="error.main" color="error.contrastText" p={2}>error.main</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="warning.main" color="warning.contrastText" p={2}>warning.main</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="info.main" color="info.contrastText" p={2}>info.main</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="success.main" color="success.contrastText" p={2}>success.main</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="text.primary" color="background.paper" p={2}>text.primary</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="text.secondary" color="background.paper" p={2}>text.secondary</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box bgcolor="text.disabled" color="background.paper" p={2}>text.disabled</Box>
                </Grid>
            </Grid>


            <Root>
            {content}
            <Divider>CENTER</Divider>
            {content}
            <Divider textAlign="left">LEFT</Divider>
            {content}
            <Divider textAlign="right">RIGHT</Divider>
            {content}
            <Divider>
                <Chip label="CHIP" />
            </Divider>
            {content}
            </Root>

            <Divider sx={{ alignSelf: 'stretch' }}>CENTER</Divider>
        </Box>
    );
}

export default ColorPage;