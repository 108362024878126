import { useEffect, useState } from "react";
import API from "api";


export default function useSearchActionobj(page, spaceId, { placeId, userId, itemId, categoryId, traderId, code, keyword, isProcessed, processedFrom, processedTo }, trigger) {
    const [ rows, setRows ] = useState();
    const [ count, setCount ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        setError(null);
    }, [ spaceId, placeId, userId, itemId, categoryId, traderId, code, keyword, isProcessed, processedFrom, processedTo, page, trigger ]);

    useEffect(() => {
        if (!spaceId) return;

        let controller = new AbortController();
        setLoading(true);
        setError(false);
        const body = {
            spaceId,
            parameters: { 
                spaceId, 
                placeId, 
                userId, 
                itemId,
                categoryId,
                traderId, 
                code, 
                keyword, 
                isProcessed,
                processedFrom,
                processedTo,
                page,
            }
        }
        API.post('/action/search', body, { signal: controller.signal })
        .then(data => {
            setCount(data.count);
            setRowsPerPage(data.rowsPerPage);
            setRows(data.rows);
            setMayMore(data.rows.length > 0);
            setFetched(true);
        })
        .finally(() => {
            setLoading(false);
        });
        
        return () => { controller.abort(); };
    }, [ page, spaceId, placeId, userId, itemId, categoryId, traderId, code, keyword, isProcessed, processedFrom, processedTo, trigger ]);

    return { loading, error, rows, mayMore, count, rowsPerPage, fetched };
}