import React, { useState, useEffect, useContext, useRef } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import API from 'api';
import Loading from 'components/Loading';
import { AppContext } from 'contexts/app.context';
import { AuthCode } from 'constants/auth-codes.constants';
import Bucket from '../Bucket';
import { useReactToPrint } from 'react-to-print';
import { useSpace } from 'contexts/space_v2.context';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';


const BucketManageModal = ({ bucketId, open, setOpen, reload }) => {
    const { showDialog, showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const spaceAuthinfo = useSpaceAuthinfo();
    
    const handleClose = () => { setOpen(false); }

    const [ bucket, setBucket ] = useState();
    const [ loading, setLoading ] = useState(false);
    useEffect(() => {
        if (bucketId && space && open) {
            setLoading(true);
            API.get(`/bucket/${bucketId}?spaceId=${space.id}`)
            .then(bucket => {
                setBucket(bucket);
            })
            .finally(() => { setLoading(false); });
        }
    }, [bucketId, space, open]);
	
	const [ confirmLoading, setConfirmLoading ] = useState(false);
	const handleRejectBucket = () => {
		if (!bucket) return;
		handleClose();
		showDialog({
            message: '입출고명세서를 반려하시겠습니까?',
            first: {
                text: '네',
                onClick: () => {
					setConfirmLoading(true);
                    API.put(`/bucket/reject/${bucket.id}`)
					.then(() => {
						showSnackbar('반려했습니다.');
						reload();
					})
					.finally(() => { setConfirmLoading(false); });
                }
            },
            second: {
                text: '아니오',
            }
        });
	}

	const handleConfirmBucket = () => {
		if (!bucket) return;
		handleClose();
		showDialog({
            message: '입출고명세서를 승인하고 액션을 수행하시겠습니까?',
            first: {
                text: '네',
                onClick: () => {
					setConfirmLoading(true);
                    API.put(`/bucket/confirm/${bucket.id}`)
					.then(() => {
						showSnackbar('승인했습니다.');
						reload()
					})
					.finally(() => { setConfirmLoading(false); });
                }
            },
            second: {
                text: '아니오',
            }
        });
	}

    const handleRemove = () => {
        showDialog({
            message: '입출고명세서를 삭제하시겠습니까?',
            first: {
                text: '네',
                onClick: () => {
                    API.delete(`/bucket/bucket/${bucket.id}`)
					.then(() => {
                        handleClose();
						showSnackbar('삭제했습니다.');
						reload()
					});
                }
            },
            second: {
                text: '아니오',
            }
        });
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    if (!bucket) return null;

	return (
		<Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
        >
            <DialogContent ref={componentRef}>
                {!loading && !confirmLoading && bucket ? <Bucket bucket={bucket} /> : <Loading />}
            </DialogContent>
            <DialogActions>
                <Button color={'info'} onClick={handlePrint}>프린트</Button>
                {bucket?.state === 'submitted' && spaceAuthinfo[AuthCode.actionConfirmable] && <>
                    <Button color={'error'} onClick={handleRejectBucket}>반려</Button>
                    <Button color={'success'} onClick={handleConfirmBucket}>승인</Button>
                </>}
                {[ 'making', 'submitted', 'rejected' ].indexOf(bucket.state) > -1 && <Button color={'error'} onClick={handleRemove}>삭제</Button>}
                <Button onClick={handleClose}>닫기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default BucketManageModal;