import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';


const CommerceFormModal = ({
    open,
    handleClose,
    commerce,
    spaceId,
    reloadCommerce,
}) => {
    const { showSnackbar } = useContext(AppContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: commerce?.id,
            spaceId,
            // traderId: commerce?.trader.id || '',
            alias: commerce?.alias || '나의 커머스',
            order: commerce?.order,
        },
        validationSchema: yup.object({
            alias: yup.string().required('필수입력입니다.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (!commerce) {
                // if (!values.traderId) return showSnackbar('거래처를 선택해주세요.');

                API.post(`/commerce`, values)
                .then((data) => {
                    showSnackbar('등록했습니다.');
                    handleClose();
                    reloadCommerce();
                })
                .finally(() => { setSubmitting(false); });
            }
            else {
                if (!values.order) return showSnackbar('순번을 입력해주세요.');

                API.put(`/commerce/${commerce.id}`, values)
                .then((data) => {
                    showSnackbar('수정했습니다.');
                    handleClose();
                    reloadCommerce();
                })
                .finally(() => { setSubmitting(false); });
            }
        },
    });
    const { values, handleChange, handleBlur, setFieldValue, isSubmitting, getFieldProps, handleSubmit, touched, errors } = formik;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{commerce ? commerce.alias + ' 수정' : '커머스 등록'}</DialogTitle>

                <DialogContent>
                    <TextField
                        required
                        fullWidth
                        label={'커머스명'}
                        variant="outlined"
                        size='small'
                        margin='dense'
                        autoFocus
                        error={!!errors.alias}
                        helperText={(touched.alias && errors.alias) || ' '}
                        {...getFieldProps('alias')}
                    />

                    {/* <TextField
                        select
                        required
                        fullWidth
                        disabled={!!commerce}
                        label={'선택: 거래처'}
                        variant="outlined"
                        size='small'
                        margin='dense'
                        onChange={event => { setFieldValue('traderId', event.target.value) }}
                        value={values.traderId || ''}
                    >
                        {space.traders.map(trader => <MenuItem key={trader.id} value={trader.id}>{trader.name}</MenuItem>)}
                    </TextField> */}

                    {commerce && <TextField
                        fullWidth
                        required={!!commerce}
                        type="number"
                        label={'순번'}
                        variant="outlined"
                        size='small'
                        margin='dense'
                        sx={{ mt: 4 }}
                        error={!!errors.order}
                        helperText={(touched.order && errors.order) || ' '}
                        {...getFieldProps('order')}
                    />}
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>확인</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}

export default CommerceFormModal;