import React from 'react';
import { Box, Typography } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import { actioncode_pipe, bucket_name_pipe, datetime_pipe, item_name_pipe } from 'pipes';


const Actionobject = ({ obj }) => {
	const row = (label, value) => (
		<div className={pageStyles.row}>
			<div className={pageStyles.label}>
				<Typography variant="body2" className={pageStyles.labelText}>{label}</Typography>
			</div>
			<div className={pageStyles.content}>
				<Typography variant="body2">{value}</Typography>
			</div>
		</div>
	); 

	return (
		<Box>
			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
				</Box>
				<div className={pageStyles.table}>
					{row('순번', obj.order)}
					{row('구분', actioncode_pipe(obj.action.code))}
					{row('제품', item_name_pipe(obj.action.item))}
					{row('수량', obj.action.amount)}
					{row('창고', obj.action.place.name)}
					{obj.action.toPlace && row('목적지 창고', obj.action.toPlace.name)}
					{obj.action.trader && row('거래처', obj.action.trader.name)}
					{obj.bucket && row('입출고명세서', bucket_name_pipe(obj.bucket))}
				</div>
			</div>

			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>히스토리</Typography>
				</Box>
				<div className={pageStyles.table}>
					{row('요청', `${datetime_pipe(obj.createdAt)}, ${obj.user.nickname}`)}
					{obj.processedAt && row('수행', `${datetime_pipe(obj.processedAt)}, ${obj.executor.nickname}`)}
				</div>
			</div>
		</Box>
	);
}

export default Actionobject;