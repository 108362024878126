import React, { useState, useContext, useMemo } from 'react';
import { Card, CardActions, CardContent, ListItemText, MenuItem, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import ContextMenu from 'components/ContextMenu';
import { useNavigate } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import colors from 'constants/colors';
import CoupangAuthFormModal from './CoupangAuthFormModal';
import CommerceFormModal from './CommerceFormModal';
import { useSpace } from 'contexts/space_v2.context';


const Commerce = ({ commerce, reloadCommerce }) => {
    const { showDialog, showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const navigate = useNavigate();

    const [anchorel, setAnchorel] = useState(null);
    const open = Boolean(anchorel);
    const handleIconClick = (event) => {
        setAnchorel(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorel(null);
    };

    // --- commerce ---
    const [ modalOpen, setModalOpen ] = useState(false);
    const onModifyClick = () => {
        setAnchorel(null);
        setModalOpen(true);
    }

    const onRemoveClick = () => {
        setAnchorel(null);
        showDialog({
            message: '연결을 해제하시겠습니까?\nAntpocket 에 있는 해당 커머스 데이터가 삭제됩니다.',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/commerce/${commerce.id}?spaceId=${space.id}`)
                    .then((data) => {
                        showSnackbar('삭제했습니다.');
                        reloadCommerce();
                    })
                }
            },
            second: {
                text: '취소',
            }
        });
    }
    // --- end: commerce ---

    // --- coupang auth ---
    const [ coupangAuthModalOpen, setCoupangAuthModalOpen ] = useState(false);
    const onConnectCoupangAuthClick = () => {
        setAnchorel(null);
        setCoupangAuthModalOpen(true);
    }
    const onModifyCoupangAuthClick = () => {
        setAnchorel(null);
        setCoupangAuthModalOpen(true);
    }
    const onDisconnectCoupangAuthClick = () => {
        setAnchorel(null);
        showDialog({
            message: '쿠팡계정을 연결해제하시겠습니까?',
            first: {
                text: '네',
                onClick: () => {
					API.delete(`/commerce/coupang/disconnect-coupang-auth/${commerce.coupangAuth.vendorId}?spaceId=${space.id}`)
                    .then(() => {
                        showSnackbar('연결을 해제했습니다.');
                        reloadCommerce();
                    })
                }
            },
            second: {
                text: '아니오',
            }
        });
    }
    // --- end: coupang auth ---

    

    const isCoupangAuthExpired = useMemo(() => {
        if (commerce.coupangAuth) {
            const expireDays = 180;
            return differenceInDays(new Date(), new Date(commerce.coupangAuth.updatedAt)) > expireDays;
        }
    }, [ commerce ]);

    return (
        <>
            <Card variant='outlined'>
                <CardContent sx={{ position: 'relative' }}>
                    <Typography variant='body1' sx={{ fontSize: 14 }} color="text.primary" gutterBottom>{commerce.alias}</Typography>
                    <EditIcon sx={{ position: 'absolute', right: '12px', top: '12px', cursor: 'pointer' }} fontSize="small" color="action" onClick={handleIconClick} />
                    <Typography variant="caption">순번: {commerce.order}{commerce.platform ? `, 플랫폼: 쿠팡` : ''}</Typography>
                    {isCoupangAuthExpired && <>
                        <br></br><Typography variant="caption" style={{ color: colors.error }}>쿠팡연결정보가 180일이 지났습니다. 재연결이 필요할 수 있습니다.</Typography>
                    </>}
                </CardContent>

                <CardActions>
                    {commerce.platform ? <>
                        {/* <Button size="small" variant="contained" color="primary" onClick={() => { navigate(`coupang/${commerce.id}/products`); }}>상품 조회</Button> */}
                        <Button size="small" variant="contained" color="primary" onClick={() => { navigate(`coupang/${commerce.id}/sales`); }}>매출내역 조회</Button>
                    </> : <>
                        <Button size="small" variant="contained" color="primary" onClick={onConnectCoupangAuthClick}>쿠팡계정연결</Button>
                    </>}
                </CardActions>
            </Card>

            <ContextMenu
                anchorEl={anchorel}
                open={open}
                onClose={handleClose}
            >
                <MenuItem sx={{ width: 200 }} onClick={onModifyClick}>
                    <ListItemText>수정</ListItemText>
                </MenuItem>
                {commerce.coupangAuth && <MenuItem sx={{ width: 200 }} onClick={onModifyCoupangAuthClick}>
                    <ListItemText>쿠팡연결정보 수정</ListItemText>
                </MenuItem>}
                {commerce.coupangAuth && <MenuItem sx={{ width: 200 }} onClick={onDisconnectCoupangAuthClick}>
                    <ListItemText>쿠팡연결해제</ListItemText>
                </MenuItem>}
                <MenuItem sx={{ width: 200 }} onClick={onRemoveClick}>
                    <ListItemText>삭제</ListItemText>
                </MenuItem>
            </ContextMenu>

            <CommerceFormModal open={modalOpen} handleClose={() => { setModalOpen(false); }} spaceId={commerce.spaceId} commerce={commerce} reloadCommerce={reloadCommerce} />
            <CoupangAuthFormModal open={coupangAuthModalOpen} handleClose={() => { setCoupangAuthModalOpen(false); }} spaceId={commerce.spaceId} commerce={commerce} reloadCommerce={reloadCommerce} />
        </>
    );
}

export default Commerce;