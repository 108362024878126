import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';


const CoupangAuthFormModal = ({
    open,
    handleClose,
    commerce,
    spaceId,
    reloadCommerce,
}) => {
    const { showSnackbar } = useContext(AppContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            spaceId,
            commerceId: commerce.id,
            vendorId: commerce.coupangAuth?.vendorId || '',
            accessKey: '',
            secretKey: '',
        },
        validationSchema: yup.object({
            vendorId: yup.string().required('필수입력입니다.'),
            accessKey: yup.string().required('필수입력입니다.'),
            secretKey: yup.string().required('필수입력입니다.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (!commerce.coupangAuth) {
                API.post('/commerce/coupang/connect-coupang-auth', values)
                .then((data) => {
                    showSnackbar('쿠팡계정이 연결되었습니다.');
                    handleClose();
                    reloadCommerce();
                    resetForm();
                })
                .finally(() => { setSubmitting(false); });
            }
            else {
                API.put(`/commerce/coupang/modify-coupang-auth`, values)
                .then((data) => {
                    showSnackbar('수정했습니다.');
                    handleClose();
                    reloadCommerce();
                })
                .finally(() => { setSubmitting(false); });
            }
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, getFieldProps, handleSubmit, touched, errors } = formik;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{commerce.coupangAuth ? '쿠팡 연결정보 수정' : '쿠팡 계정 연결'}</DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        disabled={!!commerce.coupangAuth}
                        margin="normal"
                        label={'업체코드 (Vendor ID)'}
                        fullWidth
                        variant="standard"
                        error={!!errors.vendorId}
                        helperText={(touched.vendorId && errors.vendorId) || ' '}
                        {...getFieldProps('vendorId')}
                    />

                    <TextField
                        required
                        margin="normal"
                        label={'Access Key'}
                        fullWidth
                        variant="standard"
                        error={!!errors.accessKey}
                        helperText={(touched.accessKey && errors.accessKey) || ' '}
                        {...getFieldProps('accessKey')}
                    />

                    <TextField
                        required
                        margin="normal"
                        label={'Secret Key'}
                        fullWidth
                        variant="standard"
                        error={!!errors.secretKey}
                        helperText={(touched.secretKey && errors.secretKey) || ' '}
                        {...getFieldProps('secretKey')}
                    />
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>확인</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}


export default CoupangAuthFormModal;