import { useEffect, useRef, useState } from "react";
import API from "api";


export default function useItemSummariesStatistics(params, pageTrigger) {
    const page = useRef(0);

    const [ rows, setRows ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        page.current = 0;
    }, [ params ]);

    useEffect(() => {
        if (!mayMore) return;
        if (!(params.spaceId && params.placeId && params.processedFrom && params.processedTo)) return;

        const controller = new AbortController();
        setLoading(true);
        const params_s = { 
            ...params,
            page: page.current,
        };
        API.get('/statistics/item-summaries', { params: params_s, signal: controller.signal })
        .then(data => {
            page.current = page.current + 1;
            setRows(prevs => prevs.concat(data.rows));
            setMayMore(data.rows.length === data.rowsPerPage);
            setFetched(true);
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); }
    }, [ params, pageTrigger, mayMore ]);

    return { loading, rows, mayMore, fetched };
}