import React, { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Loading from 'components/Loading';
import useTraderReleaseTrend from 'api/statistics/useTraderReleaseTrend_v2';
import { iso_datetime_pipe, item_name_pipe } from 'pipes';
import getFromToGroupby from 'views/statistics/getFromToGroupby';
import Filter from './Filter';
import TraderReleaseChart from './TraderReleaseChart';
import { useSpace } from 'contexts/space_v2.context';
import { format } from 'date-fns';


const TraderReleaseTrend = () => {
	const [ searchParams ] = useSearchParams();

    const space = useSpace();

    const params = useMemo(() => {
        const groupby = searchParams.get('groupby') || 'month';
        const traderId = searchParams.get('traderId');
        const placeId = searchParams.get('placeId');
        const itemId = searchParams.get('itemId');
        let from = searchParams.get('from');
        let to = searchParams.get('to');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby(groupby, from, to);

        return ({
            spaceId: space?.id,
            groupby,
            from: iso_datetime_pipe(from_a),
            to: iso_datetime_pipe(to_a),
            traderId,
            placeId,
            itemId,
        });
    }, [ space, searchParams ]);

    const [ item, setItem ] = useState(null);

	const { loading, datasets, resultParams } = useTraderReleaseTrend(params);

    const resultDescribe = useMemo(() => {
        if (!resultParams || !space) return '';
        
        const groupby_t = resultParams.groupby === 'month' ? '월별' : (resultParams.groupby === 'week' ? '주간별' : '일별');
        const fromto_t = `${format(new Date(resultParams.from), 'yyyy-MM-dd')} ~ ${format(new Date(resultParams.to), 'yyyy-MM-dd')}`;
        
        let text = `기간구분: ${groupby_t}\n 기간: ${fromto_t}`;
        if (resultParams.traderId) {
            const tradername = space.traders.find(el => el.id == resultParams.traderId).name;
            text += `\n거래처: ${tradername}`;
        }
        if (resultParams.placeId) {
            const placename = space.places.find(el => el.id == resultParams.placeId).name;
            text += `\n창고: ${placename}`;
        }
        if (resultParams.itemId) {
            if (item) {
                text += `\n제품: ${item_name_pipe(item)}`;
                if (item.valSummaries) text += ` (${item.valSummaries})`;
            }
            else text += `\n제품: 선택됨`;
        }
        
        return text;
    }, [ resultParams, space ]);

	return (
		<Box>
			<Filter searchDisabled={loading} item={item} setItem={setItem} />

            {!!resultDescribe && <>
                <Box sx={{ mt: 4, mb: 2 }}>
                    <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>{resultDescribe}</Typography>
                </Box>
			</>}

			{!loading ? <>
                {!!datasets && <>
                    <TraderReleaseChart
                        groupby={resultParams.groupby}
                        datasets={datasets}
                    />
                </>}
            </> : (<Loading />)}
			
		</Box>
	);
}

export default TraderReleaseTrend;