import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import API from 'api';
import Loading from 'components/Loading';
import Actionobject from '../Actionobject';
import RevertActionobjectDialog from '../RevertActionobjectDialog';
import { useSpace } from 'contexts/space_v2.context';


const ActionobjectModal = ({ objId, open, setOpen, needReload }) => {
    const space = useSpace();
    
    const handleClose = () => { setOpen(false); }

    const [ actionobject, setActionobject ] = useState();
    const [ loading, setLoading ] = useState(false);
    useEffect(() => {
        if (objId && space) {
            setLoading(true);
            API.get(`/action/actionobject/${objId}?spaceId=${space.id}`)
            .then(obj => {
                setActionobject(obj);
            })
            .finally(() => { setLoading(false); })
        }
    }, [objId, space]);

    const [ revertModalOpen, setReverModalOpen ] = useState(false);
    const revertDone = () => {
        needReload();
        setOpen(false);
    }
    
	return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    {!loading && actionobject ? <Actionobject obj={actionobject} /> : <Loading />}
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setReverModalOpen(true)}>과거로 돌아가기</Button>
                    <Button onClick={handleClose}>닫기</Button>
                </DialogActions>
            </Dialog>
            
            {actionobject && <RevertActionobjectDialog actionobject={actionobject} open={revertModalOpen} setOpen={setReverModalOpen} revertDone={revertDone} />}
        </>
	);
}

export default ActionobjectModal;