import { createContext, useContext, useReducer } from "react";


const ItemContext = createContext(null);
const ItemDispatchContext = createContext(null);


export function ItemProvider({ item, children }) {
    if (!item) throw new Error('need item');

    const [ itemValue, dispatch ] = useReducer(reducer, item);

    return (
        <ItemContext.Provider value={itemValue}>
            <ItemDispatchContext.Provider value={dispatch}>
                {children}
            </ItemDispatchContext.Provider>
        </ItemContext.Provider>
    )
}

export function useItem() {
    return useContext(ItemContext);
}

export function useItemDispath() {
    return useContext(ItemDispatchContext);
}


function reducer(item, action) {
    switch (action.type) {
        case 'modified': {
            const { value } = action;

            return ({
                ...value,
            })
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}