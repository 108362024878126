import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CircularButton from 'components/CircularButton';
import globals from 'constants/globals';
import PeriodInput from 'components/PeriodInput';
import ItemSelectionDialog from 'components/item/selection/ItemSelectionDialog';
import { format } from 'date-fns';
import { useSpace } from 'contexts/space_v2.context';


const Filter = ({ searchDisabled }) => {
    const space = useSpace();

	const [ place, setPlace ] = useState();
    const [ trader, setTrader ] = useState();
	const [ itemId, setItemId ] = useState();
	const [ category, setCategory ] = useState();
	const [ code, setCode ] = useState('');
	const [ keyword, setKeyword ] = useState('');
	const [ processedFrom, setProcessedFrom ] = useState(null);
	const [ processedTo, setProcessedTo ] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const [ itemName, setItemName ] = useState('');
	
	useEffect(() => {
        if (!space) return;

        const keyword = searchParams.get('keyword') || '';
		const placeId = searchParams.get('placeId');
        const traderId = searchParams.get('traderId');
        const categoryId = searchParams.get('categoryId');
		const itemId = searchParams.get('itemId');
		
		const code = searchParams.get('code') || '';
		const processedFrom = searchParams.get('processedFrom');
		const processedTo = searchParams.get('processedTo');

		setKeyword(keyword);
		setPlace(space.places.find(el => el.id == placeId));
		setTrader(space.traders.find(el => el.id == traderId));
		setCategory(space.categories.find(el => el.id == categoryId));
        setItemId(itemId);
		setCode(code);
		setProcessedFrom(processedFrom && new Date(processedFrom));
		setProcessedTo(processedTo && new Date(processedTo));
	}, [ space, searchParams ]);

	const onSearchClick = (event) => {
		event.preventDefault();
		
        setSearchParams({
            keyword,
			placeId: place ? place.id : '',
			traderId: trader ? trader.id : '',
			categoryId: category ? category.id : '',
			itemId: itemId || '',
			code,
			processedFrom: processedFrom ? format(processedFrom, 'yyyy-MM-dd') : '',
			processedTo: processedTo ? format(processedTo, 'yyyy-MM-dd') : '',
        })
	}

    // item selection
	const [ itemSelectionOpen, setItemSelectionOpen ] = useState(false);

    if (!space) return null;

	return (
        <>
            <form onSubmit={onSearchClick}>
                <Stack spacing={1} sx={{ maxWidth: 1200 }}>
                    <Box>
                        <PeriodInput
                            initialStartDate={processedFrom}
                            initialEndDate={processedTo}
                            onPeriodChanged={(start, end) => {
                                setProcessedFrom(start);
                                setProcessedTo(end);
                            }}
                            options={{
                                baseDateText: '수행일시 기준'
                            }}
                        />
                    </Box>

                    <div className="mt-2 input-group input-group-sm">
                        {/* 액션 */}
                        <span className="input-group-text">액션</span>
                        <select className="form-select" value={code} onChange={event => setCode(event.target.value)}>
                            <option value=""></option>
                            {globals.actionCodes.map(code => <option key={code.code} value={code.code}>{code.name}</option>)}
                        </select>

                        {/* 창고 */}
                        <span className="input-group-text ms-1">창고</span>
                        <select className="form-select" value={place?.id || ''} onChange={event => setPlace(space.places.find(el => el.id == event.target.value))}>
                            <option value=""></option>
                            {space.places.map(place => <option key={place.id} value={place.id}>{place.name}</option>)}
                        </select>

                        {/* 거래처 */}
                        <span className="input-group-text ms-1">거래처</span>
                        <select className="form-select" value={trader?.id || ''} onChange={event => setTrader(space.traders.find(el => el.id == event.target.value))}>
                            <option value=""></option>
                            {space.traders.map(trader => <option key={trader.id} value={trader.id}>{trader.name}</option>)}
                        </select>

                        {/* 카테고리 */}
                        <span className="input-group-text ms-1">카테고리</span>
                        <select className="form-select" value={category?.id || ''} onChange={event => setCategory(space.categories.find(el => el.id == event.target.value))}>
                            <option value=""></option>
                            {space.categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                        </select>

                        {/* 제품 */}
                        <span className="input-group-text ms-1">제품</span>
                        <input className='form-control' value={itemName ? itemName : (itemId ? '제품선택됨' : '')} readOnly />
                        {itemId
                            ? <button type="button" className='btn btn-secondary' onClick={() => { setItemId(null); setItemName('') }}>선택해제</button>
                            : <button type="button" className='btn btn-secondary' onClick={() => { setItemSelectionOpen(true); }}>선택</button>
                        }
                    </div>

                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <div className="input-group input-group-sm" style={{ maxWidth: 240 }}>
                            {/* 키워드 */}
                            <span className="input-group-text">키워드</span>
                            <input className="form-control" placeholder='제품명 또는 제품코드 입력' value={keyword} onChange={event => setKeyword(event.target.value)} />
                        </div>

                        <CircularButton style={{ marginLeft: 8 }} type="submit" variant='contained' size='small' loading={searchDisabled}>검색</CircularButton>
                    </Box>
                </Stack>
            </form>

            <ItemSelectionDialog
                open={itemSelectionOpen}
                setOpen={setItemSelectionOpen}
                onSelect={item => {
                    setItemId(item?.id);
                    setItemName(item?.name || '');
                }}
            />
        </>
	);
}

export default Filter;