import React, { useState, useContext, useMemo } from 'react';
import { Box, Button, Stack } from '@mui/material';
import Guide from 'components/Guide';
import colors from 'constants/colors';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import Title from 'components/Title';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from 'components/Loading';
import ExportItemsModal from './ExportItemsModal';
import { useSpace } from 'contexts/space_v2.context';
import useDetectLast from 'hooks/useDetectLast';
import { SelectedProvider, useSelected, useSelectedDispatch } from 'contexts/SelectedContext';
import ItemTable from './ItemTable_v2';
import { ItemsProvider } from 'contexts/items.context';
import useFetchItems from './useFetchItems';
import PageContext from 'contexts/page.context';
import ItemFormModal from './ItemFormModal';


const ItemList = () => {
    const { showSnackbar, showDialog } = useContext(AppContext);
    const space = useSpace();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const category = useMemo(() => {
        if (!space) return null;
        return space.categories.find(el => el.id == searchParams.get('categoryId'));
    }, [ space, searchParams ]);

	const [ keyword, setKeyword ] = useState('');

    const params = useMemo(() => {
        if (!space || !category) return null;
        const keyword = searchParams.get('keyword') || '';
        setKeyword(keyword);
        
        return ({
            spaceId: space.id,
            categoryId: category.id,
            keyword,
        });
    }, [ searchParams, space ]);
    const [ pageTrigger, setPageTrigger ] = useState();

    const [ rows, setRows ] = useState([]);
    const { loading, mayMore, fetched } = useFetchItems(params, pageTrigger, setRows);

    const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

    const handleSearch = () => {
        setSearchParams({
            categoryId: category.id,
            keyword,
            trigger: new Date().getTime()
        });
    }

    // 등록
    const [ addModalOpen, setAddModalOpen ] = useState(false);
    const onAddClick = () => { setAddModalOpen(true); }

    const selected = useSelected();
    const selectedDispatch = useSelectedDispatch();
    const handleRemoveClick = () => {
        if (selected.length === 0) return;
        const ids = selected.join(',');

        showDialog({
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '예',
                onClick: () => {
                    API.delete(`/item/items?ids=${ids}&spaceId=${space.id}`)
                    .then(() => {
                        showSnackbar('삭제했습니다.');
                        handleSearch();
                        selectedDispatch({
                            type: 'clear',
                        })
                    })
                }
            },
            second: {
                text: '아니오',
            }
        });
    }

    // export
    const [ exportModalOpen , setExportModalOpen ] = useState(false);
    const handleExport = () => {
        setExportModalOpen(true);
    }

	return (
		<PageContext.Provider
            value={{
                category,
                rows,
                setRows,
                lastElementRef,
                reload: handleSearch,
            }}
        >
            <Title>제품{category && <span style={{ fontSize: '.8em', color: colors.textSecondary }}> {category.name}</span>}</Title>

            <Box sx={{ mt: 2 }}>
                {!category ? <Guide style={{ marginTop: '16px' }}>카테고리를 선택하세요.</Guide> : <>
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        handleSearch();
                    }}>
                        <div className="input-group input-group-sm mb-3" style={{ maxWidth: '240px' }}>
                            <span className="input-group-text">키워드</span>
                            <input className='form-control'
                                placeholder='제품명 또는 제품코드'
                                value={keyword || ''}
                                onChange={event => { setKeyword(event.target.value) }}
                            />
                            <button type='submit' className='btn btn-secondary' disabled={loading}>검색</button>
                        </div>
                    </form>

                    <Stack direction={'row'} spacing={1} mb={1}>
                        <Button variant="contained" color="primary" onClick={onAddClick}>등록하기</Button>
                        <Button variant='contained' color={'info'} onClick={() => { navigate(`/space/schema/item/batch-register?categoryId=${category.id}`) }}>엑셀로 등록하기</Button>
                        <Button variant='contained' color={'info'} onClick={handleExport}>내려받기</Button>
                        <Button color="error" variant="contained" onClick={handleRemoveClick} disabled={selected.length === 0}>선택 삭제</Button>
                    </Stack>

                    {fetched && <ItemTable />}

                    {loading && (<Loading />)}

                    <ItemFormModal open={addModalOpen} setOpen={setAddModalOpen} />
                </>}
            </Box>

            <ExportItemsModal open={exportModalOpen} setOpen={setExportModalOpen} spaceId={space.id} categoryId={category?.id} />
        </PageContext.Provider>
	);
}

export default function() {
    return (
        <ItemsProvider items={null}>
            <SelectedProvider selected={[]}>
                <ItemList />
            </SelectedProvider>
        </ItemsProvider>
    );
}