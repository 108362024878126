import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import CircularButton from 'components/CircularButton';


const ButtonPage = () => {
    const [loading, setLoading] = useState(false);
    const handleCircularPress = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    return (
        <Box>
            <Button variant="contained" sx={{ mb: 1 }} color="primary">Primary</Button><br />
            <Button variant="contained" sx={{ mb: 1 }} color="secondary">Secondary</Button><br />
            <Button variant="outlined" sx={{ mb: 1 }} color="primary">Outlined Primary</Button><br />
            <Button variant="outlined" sx={{ mb: 1 }} color="secondary">Outlined Secondary</Button><br />
            <Button variant="contained" sx={{ mb: 1 }} disabled>Disabled</Button>
            <CircularButton variant="contained" color="primary" loading={loading} onClick={handleCircularPress}>circular</CircularButton>
        </Box>
    );
}

export default ButtonPage