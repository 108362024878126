import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button } from '@mui/material';
import API from 'api';
import styles from 'styles.module.css';
import SelectSpace from 'components/SelectSpace';
import MemberAuthModal from './MemberAuthModal';
import Title from 'components/Title';
import { AppContext } from 'contexts/app.context';
import { AuthCode } from 'constants/auth-codes.constants';
import { useFetchSpace, useSpace } from 'contexts/space_v2.context';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';


const MembersAuths = () => {
    const { showDialog, showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const fetchSpace = useFetchSpace();
    const spaceAuthinfo = useSpaceAuthinfo();

    const amIOwner = useMemo(() => {
        return spaceAuthinfo && spaceAuthinfo[AuthCode.spaceOwner];
    }, [ spaceAuthinfo ]);
    const have_auth_pipe = (user, authcode) => {
        return !!user.auths.find(auth => auth.code === authcode && auth.refId === space.id);
    }

    // 멤버권한
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ selected, setSelected ] = useState();
    const handleShowAuth = (user) => {
        setSelected(user);
        setModalOpen(true);
    }
    const handleCloseModal = () => {
        setModalOpen(false);
        setSelected(null);
    }
    // end: 멤버권한

    // 멤버 추방
    const handleMakeMemberOut = (user) => {
        showDialog({
            message: '해당 멤버를 스페이스에서 추방하시겠습니까?',
            first: {
                text: '네',
                onClick: () => {
                    const body = {
                        spaceId: space.id,
                        userId: user.id,
                    }
                    API.post('/user/make-member-out', body)
					.then(() => {
						showSnackbar('추방했습니다.');
                        fetchSpace();
					});
                }
            },
            second: {
                text: '아니오',
            }
        });
    }

    if (!space) return (<SelectSpace />);

	return (
		<div className={styles.section}>
            <Title>멤버</Title>

            <div className="mt-3 table-responsive">
                <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">닉네임</th>
                            <th scope="col">이메일</th>
                            <th scope="col">관리</th>
                        </tr>
                    </thead>
                    <tbody>
                        {space.users.map((row) => (
                            <tr key={row.id}>
                                <td>{row.nickname}</td>
                                <td>{row.email}</td>
                                <td>
                                    <Button variant='outlined' size="small" onClick={() => { handleShowAuth(row) }}>권한보기</Button>
                                    {amIOwner && <Button variant='outlined' sx={{ ml: 1 }} color='error' size="small" disabled={have_auth_pipe(row, AuthCode.spaceOwner)} onClick={() => { handleMakeMemberOut(row) }}>추방하기</Button>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selected && <MemberAuthModal user={selected} setUser={setSelected} open={modalOpen} handleClose={handleCloseModal} />}
        </div>
	);
}

export default MembersAuths;