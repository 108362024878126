import { useEffect, useState } from "react";
import axios from "axios";
import API from "api";


export default function useGetPlace(spaceId, placeId) {
    const [ row, setRow ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        if (!spaceId || !placeId) return;
        
        let cancel;
        setLoading(true);
        const params = { spaceId };
        API.get(`/place/${placeId}`, { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setRow(data);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ spaceId, placeId ]);

    return { loading, fetched, row };
}