import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button } from '@mui/material';
import API from 'api';


const ExportItemSummariesModal = ({ open, setOpen, spaceId, params }) => {
    const exportExcel = () => {
        const body = {
            spaceId,
            code: "item_summaries",
            params,
            withExcel: true,
        }

        API.post(`/data/generate-data-export-url-grade3`, body)
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

    const exportCSV = () => {
        const body = {
            spaceId,
            code: "item_summaries",
            params,
        }

        API.post(`/data/generate-data-export-url-grade3`, body)
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

	return (
		<Dialog
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>내려받기: 제품별 요약</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>{'설정한 조건으로 "제품별 요약정보" 를 내려받습니다.\n데스크탑 환경에서만 기능합니다.'}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
                <Button onClick={exportCSV} color="info" variant="contained">CSV파일로 내려받기</Button>
                <Button onClick={exportExcel} variant="contained">액셀파일로 내려받기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default ExportItemSummariesModal;