import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';



const ModalPage = () => {
    const { showSnackbar, showAlert, showDialog } = useContext(AppContext);

    // input: message
    const handleSnackbar = () => {
        showSnackbar('hello world');
    }

    // input: message
    const handleAlert = () => {
        showAlert('error');
    }

    // input: title, message, confirm(text, onpress), cancel(text)
    const handleDialog = () => {
        showDialog({
            title: 'title',
            message: 'message',
            first: {
                text: '확인',
                onClick: () => {
                    console.log('OK');
                }
            },
            second: {
                text: '취소',
                onClick: () => {
                    console.log('CANCEL');
                }
            }
        });
        // showDialog({
        //     title: 'title',
        //     message: 'message',
        //     confirm: {
        //         onClick: () => {
        //             console.log('OK');
        //         }
        //     },
        // });
    }

    return (
        <Box>
            <Button variant="outlined" style={{ marginRight: '8px' }} onClick={handleSnackbar}>snackbar</Button>
            <Button variant="outlined" style={{ marginRight: '8px' }} onClick={handleAlert}>alert</Button>
            <Button variant="outlined" style={{ marginRight: '8px' }} onClick={handleDialog}>dialog</Button>
        </Box>
    );
}

export default ModalPage;