import React, { useState, useContext, useMemo, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import API from 'api';
import Loading from 'components/Loading';
import { Box } from '@mui/system';
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Button, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import colors from 'constants/colors';
import { AppContext } from 'contexts/app.context';
import * as yup from 'yup';
import PlaceSelection from 'components/PlaceSelection';
import TablePaginationActions from 'components/TablePaginationActions';
import { useSpace } from 'contexts/space_v2.context';


export default function Place({ 
    place,
    setPlace,
    items,
    setItems,
    trigger,
    actionLoading,
    handleStore,
    handleRelease,
    handleAdjust,
    handleMove,
}) {
    const { showSnackbar } = useContext(AppContext);
    const space = useSpace();

    const [ category, setCategory ] = useState();   // filter: category
    const [ keyword, setKeyword] = useState('');    // filter: keyword
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState(0);
    const rowsPerPage = 10;

    useEffect(() => {
        if (!place) return;

        setLoading(true);
        API.get(`/place/${place.id}/items-info?spaceId=${space.id}`)
        .then((data) => {
            setItems(data);
        })
        .finally(() => { setLoading(false); });
    }, [ place, trigger ]);

    const vItems = useMemo(() => {
        if (!items) return;

        return items.filter(el => {
            if (category) {
                if (el.categoryId !== category.id) return false;
            }
            if (keyword) {
                if (!el.name.includes(keyword)) return false;
            }
            return true;
        });
    }, [ items, category, keyword ]);

    useEffect(() => {
        setPage(0);
    }, [ category, keyword ]);

    // interface
    const checkedInfo = useMemo(() => {
        let isAllChecked = false
        let numberOfChecked = 0;
        if (items) {
            numberOfChecked = items.filter(el => !!el.checked).length;
            isAllChecked = numberOfChecked > 0 && numberOfChecked === items.length;
        }
        return ({ isAllChecked, numberOfChecked });
    }, [ items ]);

    const storeAvailable = useMemo(() => {
        if (!items) return false;
        return items.filter(item => item.checked && item.diff && item.diff > 0).length > 0
    }, [ items ]);

    const adjustAvailable = useMemo(() => {
        if (!items) return false;
        return items.filter(item => item.checked && !isNaN(item.diff) && item.diff >= 0).length > 0
    }, [ items ]);

    const handleToggle = (item) => {
        const index = items.indexOf(item);
        const checked = !item.checked;

        const item_a = {
            ...item,
            checked,
        };
        
        setItems(items => {
            const result = [...items];
            result.splice(index, 1, item_a)
            return result;
        });
    }

    const handleCount = (item, diff) => (event) => {
        event.stopPropagation();
        const index = items.indexOf(item);

        setItems(items => {
            return items.map((item, idx) => {
                if (idx === index) {
                    const prev = item.diff || 0;
                    const diff_a = prev + diff;
                    if (diff_a < 0) return item;
                    return ({
                        ...item,
                        checked: true,
                        diff: diff_a,
                    });
                }
                else return item;
            })
        });
    }

    const [ amountModalOpen, setAmountModalOpen ] = useState(false);
    const [ vamount, setVamount ] = useState('');
    const [ vitem, setVitem ] = useState();
    const handleAmountDirectly = (item) => (event) => {
        event.stopPropagation();

        setVamount(item.diff || '0');
        setVitem(item);
        setAmountModalOpen(true);

        setTimeout(() => {
            amountInput.current?.select();    
        }, 200);
    }

    const handleAmountDirectlyConfirm = (event) => {
        event.preventDefault();     // preventDefault 안하면 enter 했을때 모달이 안닫힘.
        const schema = yup.number().integer().min(0);
        schema.validate(vamount)
        .then(() => {
            const index = items.indexOf(vitem);
            setItems(items => {
                return items.map((item, idx) => {
                    if (idx === index) {
                        return ({
                            ...item,
                            checked: true,
                            diff: +vamount,
                        });
                    }
                    else return item;
                })
            });
            setAmountModalOpen(false);
        })
        .catch(error => showSnackbar('0 이상의 수를 입력하세요.'));
    }
    
    const amountInput = useRef();
    // end: interface

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const emptyRows = rowsPerPage - Math.min(vItems?.length, rowsPerPage)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <PlaceSelection
                places={space.places}
                selected={place}
                handlePlaceSelected={setPlace}
            />
            <Card variant='outlined' square sx={{ mt: 1, minHeight: '540px' }}>
                {
                    (loading || actionLoading) ? <Loading color="secondary" /> : <>
                        {items ? <>
                            <CardHeader
                                sx={{ px: 2, py: 1, bgcolor: 'background.paper' }}
                                title={place?.title}
                                subheader={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant='body2'>{`${checkedInfo.numberOfChecked}/${items.length} 선택됨`}</Typography>

                                        <TextField
                                            type="text"
                                            label={'제품'}
                                            sx={{ ml: 'auto', width: '160px' }}
                                            variant="outlined"
                                            size="small"
                                            onChange={event => { setKeyword(event.target.value); }}
                                            value={keyword}
                                        />
                                        
                                        <TextField
                                            select
                                            size='small'
                                            sx={{ width: '160px', ml: 1 }}
                                            variant="outlined"
                                            label={'선택: 카테고리'}
                                            onChange={event => { setCategory(event.target.value); }}
                                            value={category || ''}
                                        >
                                            <MenuItem value={null}>{'전체'}</MenuItem>
                                            {space.categories.map(category => <MenuItem key={category.id} value={category}>{category.name}</MenuItem>)}
                                        </TextField>
                                    </Box>
                                }
                            />
                            <Divider />

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: colors.lime }}>
                                            <TableCell padding="none">
                                            </TableCell>
                                            <TableCell padding='none'>제품</TableCell>
                                            <TableCell padding='none'>속성</TableCell>
                                            <TableCell padding='none' align='right'>수량</TableCell>
                                            <TableCell padding='none' align='right'>수량조절</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vItems
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleToggle(row)}
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={!!row.checked}
                                                    sx={{ bgcolor: row.rAmount === null ? colors.gray100 : 'auto' }}
                                                >
                                                    <TableCell padding="checkbox"><Checkbox color="primary" checked={!!row.checked} /></TableCell>
                                                    <TableCell padding="none">{row.name}{row.code ? ` [${row.code}]` : ''}</TableCell>
                                                    <TableCell padding="none">{row.valSummaries || '-'}</TableCell>
                                                    <TableCell padding="none" align='right'>
                                                        <Box sx={{ flex: 1, textAlign: 'right' }}><Typography variant='body2' color='text.secondary'>{row.amount}</Typography></Box>
                                                        <Box sx={{ ml: 1, flex: 1 }}>
                                                            {(!!row.diff || row.diff === 0) && <Typography variant='body2' color='secondary'>{row.diff}</Typography>}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell padding="none" align='right'>
                                                        <ButtonGroup variant="outlined" size='small'>
                                                            <Button color='success' onClick={handleCount(row, +1)}>+</Button>
                                                            <Button color='warning' onClick={handleCount(row, -1)}>-</Button>
                                                            <Button color='secondary' onClick={handleAmountDirectly(row)}>=</Button>
                                                        </ButtonGroup>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 43 * emptyRows }}><TableCell colSpan={5} /></TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                labelRowsPerPage={'페이지당 개수'}
                                rowsPerPageOptions={[rowsPerPage]}
                                component="div"
                                count={vItems.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </> : <Box sx={{ mt: '200px', textAlign: 'center' }}>
                            <Typography variant='body1' sx={{ color: colors.textSecondary }}>창고를 선택하세요.</Typography>
                        </Box>}
                    </>
                }
            </Card>

            <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
                <Button variant='outlined' disabled={!storeAvailable} sx={{ color: colors.storeColor, borderColor: colors.storeColor }} onClick={handleStore(place, items)}>입고</Button>
                <Button variant='outlined' disabled={!storeAvailable} sx={{ ml: 1, color: colors.releaseColor, borderColor: colors.releaseColor }} onClick={handleRelease(place, items)}>출고</Button>
                <Button variant='outlined' disabled={!adjustAvailable} sx={{ ml: 1, color: colors.adjustmentColor, borderColor: colors.adjustmentColor }} onClick={handleAdjust(place, items)}>조정</Button>
                <Button variant='outlined' disabled={!storeAvailable} sx={{ ml: 1, color: colors.moveColor, borderColor: colors.moveColor }} onClick={handleMove(place, items)}>이동</Button>
            </Box>

            <Dialog open={amountModalOpen} onClose={() => { setAmountModalOpen(false); }}>
                <DialogTitle>{'제품 수량'}</DialogTitle>

                <DialogContent>
                    <TextField
                        inputRef={amountInput}
                        type="text"
                        margin='normal'
                        label={'제품 수량'}
                        variant="outlined"
                        size="small"
                        onChange={event => setVamount(event.target.value)}
                        onKeyDown={ev => { if(ev.key === 'Enter') handleAmountDirectlyConfirm(ev); }}
                        value={vamount}
                        autoFocus
                    />
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setAmountModalOpen(false); }}>취소</Button>
                    <Button variant='contained' type="button" onClick={handleAmountDirectlyConfirm}>확인</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}