import React from 'react';
import { Box } from '@mui/material';
import docuStyles from 'css/docu-style.module.css';
import H4 from 'components/document/H4';
import H5 from 'components/document/H5';
import Body1 from 'components/document/Body1';
import Glossary from 'components/document/Glossary';
import Emphasize from 'components/document/Emphasize';


const SchemaHomePage = () => {
	return (
		<Box>
            <H4>데이터</H4>

            <Glossary>
                <Body1 style={{ margin: 0 }}>
                    <span style={{ fontWeight: 'bold' }}>데이터</span><br></br>
                    데이터는 재고관리의 기본틀입니다. 당신의 사업모델을 구성하세요.<br></br>
                    앞으로의 재고관리를 위해서는 먼저 스페이스 데이터를 정의해야 합니다.<br></br>
                    데이터는 제품(상품), 거래처, 창고, 제품 속성 이 있습니다.
                </Body1>
            </Glossary>

            <Box sx={{ mt: 4 }}>
                <Emphasize>
                    <Body1>
                        관련액션: <span className={docuStyles.emphasize}>데이터 관리 권한[링크]</span>
                    </Body1>
                </Emphasize>
            </Box>
            
            
            <Box className={docuStyles.section}>
                <H5>데이터 관리</H5>
                <Body1>데이터를 관리(추가, 수정, 삭제)를 위해서는 데이터 관리(schema.writable) 권한이 필요합니다.</Body1>
                <Body1>스페이스 관리자는 기본적으로 데이터 관리 권한을 가지고 있습니다.</Body1>
                <Body1>스페이스 관리자는 데이터 관리 권한을 스페이스 멤버에게 추가로 위임할 수 있습니다.</Body1>
                <Body1>등록하신 데이터를 CSV 파일(엑샐 파일) 로 내보낼 수 있습니다.</Body1>
            </Box>
		</Box>
	);
}

export default SchemaHomePage;