import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useStoreReleaseStatistics from 'api/statistics/useStoreReleaseStatistics_v2';
import StoreReleaseChart from './StoreReleaseChart';
import Loading from 'components/Loading';
import { format } from 'date-fns';
import { iso_datetime_pipe, item_name_pipe } from 'pipes';
import getFromToGroupby from '../getFromToGroupby';
import Filter from './Filter';
import { useSpace } from 'contexts/space_v2.context';


const StoreReleaseStatisticsPage = () => {
    const [ searchParams, setSearchParams ] = useSearchParams();

    const space = useSpace();

    const params = useMemo(() => {
        const codes = searchParams.get('codes') || 'store,release';
        const groupby = searchParams.get('groupby') || 'month';
        const placeId = searchParams.get('placeId');
        const itemId = searchParams.get('itemId');
        let from = searchParams.get('from');
        let to = searchParams.get('to');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby(groupby, from, to);

        return ({
            spaceId: space?.id,
            codes,
            groupby,
            from: iso_datetime_pipe(from_a),
            to: iso_datetime_pipe(to_a),
            placeId,
            itemId,
        });
    }, [ space, searchParams ]);

    const { loading, row } = useStoreReleaseStatistics(params);

    const [ item, setItem ] = useState(null);

    const resultDescribe = useMemo(() => {
        if (!row || !space) return '';
        
        const codes_t = params.codes === 'store' ? '입고' : (params.codes === 'release' ? '출고' : '입출고');
        const groupby_t = params.groupby === 'month' ? '월별' : (params.groupby === 'week' ? '주간별' : '일별');
        const fromto_t = `${format(params.from, 'yyyy-MM-dd')} ~ ${format(params.to, 'yyyy-MM-dd')}`;
        
        let text = `구분: ${codes_t}\n기간구분: ${groupby_t}\n 기간: ${fromto_t}`;
        if (params.placeId) {
            const placename = space.places.find(el => el.id == params.placeId).name;
            text += `\n창고: ${placename}`;
        }
        if (params.itemId) {
            if (item) {
                text += `\n제품: ${item_name_pipe(item)}`;
                if (item.valSummaries) text += ` (${item.valSummaries})`;
            }
            else text += `\n제품: 선택됨`;
        }
        
        return text;
    }, [ row, space, params ]);

	return (
		<Box>
            <Filter searchDisabled={loading} item={item} setItem={setItem} />

            <Box>
                <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>{resultDescribe}</Typography>
            </Box>

            {!loading ? <>
                {!!row && <>
                    <StoreReleaseChart
                        groupby={row.groupby}
                        datasets={row.datasets}
                    />
                </>}
            </> : (<Loading />)}
		</Box>
	);
}

export default StoreReleaseStatisticsPage;