import React, { useState, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useItemSummariesStatistics from 'api/statistics/useItemSummariesStatistics_v2';
import Guide from 'components/Guide';
import getFromToGroupby from '../getFromToGroupby';
import ExportItemSummariesModal from './ExportItemSummariesModal';
import Filter from './Filter';
import { iso_datetime_pipe, item_name_pipe, number_format_pipe } from 'pipes';
import useDetectLast from 'hooks/useDetectLast';
import Nodata from 'components/Nodata';
import { useSpace } from 'contexts/space_v2.context';


const ItemSummariesPage = () => {
    const space = useSpace();

    const [ searchParams ] = useSearchParams();
    const [ pageTrigger, setPageTrigger ] = useState();

    const params = useMemo(() => {
        const keyword = searchParams.get('keyword') || '';
        const placeId = searchParams.get('placeId') || '';
        const processedFrom = searchParams.get('processedFrom');
		const processedTo = searchParams.get('processedTo');
        const onlyActionExist = searchParams.get('onlyActionExist');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby('day', processedFrom, processedTo);

        return ({
			spaceId: space?.id,
            placeId,
            processedFrom: iso_datetime_pipe(from_a),
			processedTo: iso_datetime_pipe(to_a),
            keyword,
            onlyActionExist,
        });
    }, [ searchParams, space ]);

    const { loading, rows, fetched, mayMore } = useItemSummariesStatistics(params, pageTrigger);

    const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

    // export
    const [ exportModalOpen , setExportModalOpen ] = useState(false);
    const handleExport = () => {
        setExportModalOpen(true);
    }

    if (space?.places.length === 0) return (
        <Guide>먼저 창고를 생성해주세요.</Guide>
    );

	return (
		<Box>
            <Filter searchDisabled={loading} />

            <div className="mt-2 table-responsive">
                {fetched && <>
                    <Box sx={{ mb: 1, display: 'flex', justifyContent: 'flex-end' }}><Button variant='contained' color={'info'} onClick={handleExport}>내려받기</Button></Box>
            
                    <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                        <thead>
                            <tr>
                                <th scope="col">제품</th>
                                <th scope="col">옵션</th>
                                <th scope="col">입고</th>
                                <th scope="col">출고</th>
                                <th scope="col">조정</th>
                                <th scope="col">이동</th>
                                <th scope="col">종료 재고량</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => <tr key={row.id} ref={index === rows.length - 1 ? lastElementRef : null}>
                                <td style={{ textAlign: 'start' }}>{item_name_pipe(row)}</td>
                                <td>{row.valSummaries}</td>
                                <td>{number_format_pipe(row.actionInfo.find(el => el.code === 'store').amount)}</td>
                                <td>{number_format_pipe(row.actionInfo.find(el => el.code === 'release').amount)}</td>
                                <td>{number_format_pipe(row.actionInfo.find(el => el.code === 'adjustment').adj_diff)}</td>
                                <td>{number_format_pipe(row.actionInfo.find(el => el.code === 'move').move_diff)}</td>
                                <td>{number_format_pipe(row.lastAmount)}</td>
                            </tr>)}
                        </tbody>
                    </table>

                    {rows.length === 0 && <Nodata>조회된 데이터가 없습니다.</Nodata>}
                </>}
            </div>
            
            <ExportItemSummariesModal open={exportModalOpen} setOpen={setExportModalOpen} spaceId={space?.id} params={params} />
		</Box>
	);
}

export default ItemSummariesPage;