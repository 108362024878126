import { useEffect, useRef, useState } from "react";
import readXlsxFile from 'read-excel-file'


function useReadExcelFile (headers, file) {
    const [ body, setBody ] = useState();
    const [ error, setError ] = useState();

    const headerDetected = useRef(false);
    const headerIndexes = useRef([]);

    useEffect(() => {
        if (headers && file) {
            const reqFieldLength = headers.filter(el => el.isReq).length;
            readXlsxFile(file).then((rows) => {
                const result = [];
                loop1: for (let columns of rows) {
                    if (!headerDetected.current) {
                        const unemptyColumns = columns.filter(el => !!el);
                        if (unemptyColumns.length >= Math.max(reqFieldLength, 2)) {    // 비어있지 않은 열이 n개이상이면, 헤더이다.
                            for (let header of headers) {
                                const idx = columns.indexOf(header.name);
                                if (idx === -1) {
                                    if (header.isReq) {
                                        setError(`엑셀파일에 "${header.name}" 헤더(칼럼이름)가 없습니다.`);
                                        break loop1;
                                    }
                                    else {
                                        headerIndexes.current.push(null);
                                    }
                                }
                                else {
                                    headerIndexes.current.push(idx);
                                }
                            }
                            headerDetected.current = true;
                        }
                    }
                    else {
                        const item = [];
                        for (const idx of headerIndexes.current) {
                            if (item === null) item.push('');
                            item.push(columns[idx]);
                        }
                        result.push(item);
                    }
                }
                if (headerDetected) {
                    setBody(result);
                }
            })
            .catch(error => {
                console.error(error);
                setError('엑셀 파일을 읽지 못했습니다. 적절한 파일인지 확인해 주세요.');
            })

            return () => {
                setBody();
                setError(null);
                headerIndexes.current = [];
                headerDetected.current = false;
            }
        }
    }, [headers, file]);

    return { body, error };
}

export default useReadExcelFile;