import React from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import docuStyles from 'css/docu-style.module.css';
import H4 from 'components/document/H4';
import H5 from 'components/document/H5';
import Body1 from 'components/document/Body1';
import Subtitle2 from 'components/document/Subtitle2';
import colors from 'constants/colors';
import Glossary from 'components/document/Glossary';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { Link } from 'react-router-dom';


const SpaceHomePage = () => {
	return (
		<Box>
            <H4>스페이스</H4>

            <Glossary>
                <Body1 style={{ margin: 0 }}>
                    <span style={{ fontWeight: 'bold' }}>스페이스</span><br></br>
                    스페이스는 Antpocket 의 일터 (Workspace) 입니다.<br></br>
                    스페이스 내의 팀원, 제품, 창고 히스토리 등의 모든 자원은 같은 스페이스 내에서만 공유할 수 있습니다.<br></br>
                    팀원들과 함께 풍족하고 유익한 스페이스를 만드세요.
                </Body1>
            </Glossary>
            
            <Box className={docuStyles.section}>
                <H5>스페이스 참여 방법</H5>
                <Body1>Antpocket 을 사용하는 누구나 다음의 방법으로 스페이스에 참여할 수 있습니다.</Body1>
                <Box className={docuStyles.subsection}>
                    <Body1>스페이스 참여 방법</Body1>
                    <ul>
                        <li className={docuStyles.subsection}>
                            <Subtitle2>스페이스 관리자에게 멤버가 되기를 요청하기</Subtitle2>
                            <ol>
                                <li><Link to={'/?tab=myspaces'}>내 스페이스 페이지</Link>로 이동</li>
                                <li>"스페이스 참여 요청" 버튼 클릭</li>
                                <li>
                                    가입하고자 하는 스페이스의 스페이스 코드를 입력하면 초대요청이 됩니다.
                                    스페이스 코드는 스페이스 멤버들 모두가 알고 있습니다.
                                    <Link to={'/space/account/space'}>스페이스 페이지</Link>에서 확인이 가능합니다.
                                </li>
                            </ol>
                        </li>
                        <li className={docuStyles.subsection}>
                            <Subtitle2>스페이스 관리자가 스페이스로 초대하기</Subtitle2>
                            <ol>
                                <li>스페이스 관리자는 스페이스 홈에서 <Link to={'/space/account/space'}>스페이스 페이지</Link>로 이동합니다.</li>
                                <li>"초대하기" 버튼을 클릭 후 가입시키고자 하는 사용자의 이메일을 입력합니다.</li>
                                <li>그 사용자는 이미 Antpocket 에 가입이 되어 있어야 합니다.</li>
                                <li>초대받은 사용자는 <Link to={'/?tab=myspaces'}>내 스페이스 페이지</Link> 에서 초대를 수락하면 스페이스 멤버가 됩니다.</li>
                            </ol>
                        </li>
                    </ul>
                </Box>
            </Box>

            <Box className={docuStyles.section}>
                <H5>권한</H5>
                <Body1>대부분의 데이터 조회는 스페이스 멤버이면 누구나 가능하지만, 데이터 관리 등의 기능과 같이 권한이 필요한 기능도 있습니다.</Body1>
                <Body1>스페이스 소유자는 <span className={docuStyles.emphasize}>팀원에게 적절히 권한을 분배하세요.</span></Body1>
                <Body1>규모가 작은 팀이라면 권한을 세분화하여 부여할 필요는 없습니다.</Body1>

                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table size='small' style={{ minWidth: '800px' }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>권한</StyledTableCell>
                                <StyledTableCell>티어</StyledTableCell>
                                <StyledTableCell>요약</StyledTableCell>
                                <StyledTableCell>가능한 작업</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell component="th">스페이스 소유</StyledTableCell>
                                <StyledTableCell>1티어</StyledTableCell>
                                <StyledTableCell>스페이스를 생성한 사람이 가지고 있습니다.<br></br>결제계정과 연결되어 있습니다.</StyledTableCell>
                                <StyledTableCell>스페이스 범위 내의 모든 권한에 대해 팀원에게 부여하거나 파기</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th">데이터 관리</StyledTableCell>
                                <StyledTableCell>2티어</StyledTableCell>
                                <StyledTableCell>스페이스의 데이터를 관리할 수 있는 권한입니다.<br></br>일반적으로 팀의 관리자 역할을 하는 사람에게 부여합니다.</StyledTableCell>
                                <StyledTableCell>스페이스 범위 내의 제품, 창고, 거래처 를 추가/등록/삭제</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th">액션: 승인</StyledTableCell>
                                <StyledTableCell>3티어</StyledTableCell>
                                <StyledTableCell>모든 액션(입고, 출고, 이동, 조정) 에 대해서 입출고명세서 등록 뿐 아니라 액션 수행할 수 있습니다.</StyledTableCell>
                                <StyledTableCell>모든 액션 수행</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th">액션: 입고, 출고, 이동, 조정</StyledTableCell>
                                <StyledTableCell>4티어</StyledTableCell>
                                <StyledTableCell>입출고명세서 작성시 해당 액션을 등록할 수 있습니다.</StyledTableCell>
                                <StyledTableCell>입출고명세서 작성 시에, 스페이스 범위 내의 액션을 등록/삭제, 입출고명세서 제출</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            
		</Box>
	);
}

export default SpaceHomePage;