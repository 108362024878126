import React, { useState, useMemo } from 'react';
import useSearchActionobj from 'api/action/useSearchActionobj_v2';
import useDetectLast from 'hooks/useDetectLast';
import Nodata from 'components/Nodata';
import { actioncode_color_pipe, actioncode_pipe, bucket_name_pipe, datetime_pipe, item_name_pipe, number_format_pipe } from 'pipes';
import Loading from 'components/Loading';
import BucketModal from 'views/bucket/BucketModal';
import { Link } from '@mui/material';
import { useSpace } from 'contexts/space_v2.context';


const SimpleHistory = ({ intend, itemId, traderId, placeId}) => {
    const space = useSpace();
	
    const params = useMemo(() => {
		const spaceId = space?.id;
        
        return ({
			placeId,
			itemId,
			traderId,
			spaceId,
			isProcessed: 'Y',
        });
	}, [ itemId, traderId, placeId ]);

    const [ pageTrigger, setPageTrigger ] = useState();

	const { loading, rows, mayMore, fetched } = useSearchActionobj(space?.id, params, pageTrigger);

	const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

    // bucket
    const [bucketModalOpen, setBucketModalOpen] = useState(false);
    const [selectedBucketId, setSelectedBucketId] = useState();
    const onBucketClick = (bucket) => () => {
        setSelectedBucketId(bucket.id);
        setBucketModalOpen(true)
	}

	return (
        <>
            <div className="mt-1 table-responsive">
                {rows && <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">순번</th>
                            <th scope="col">구분</th>
                            {intend !== 'item' && <th scope="col">제품</th>}
                            <th scope="col">요청자</th>
                            <th scope="col">수행자</th>
                            <th scope="col">입출고명세서</th>
                            <th scope="col">창고</th>
                            <th scope="col">창고(이동)</th>
                            <th scope="col">거래처</th>
                            <th scope="col">수량</th>
                            <th scope="col">남은수량</th>
                            <th scope="col">요청일시</th>
                            <th scope="col">수행일시</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => <tr key={row.id} ref={index === rows.length - 1 ? lastElementRef : null}>
                            <th scope='row'>{row.order}</th>
                            <td sx={{ color: actioncode_color_pipe(row.code) }}>{actioncode_pipe(row.code)}</td>
                            {intend !== 'item' && <td style={{ textAlign: 'start' }}>{item_name_pipe(row.item)}</td>}
                            <td>{row.user.nickname}</td>
                            <td>{row.executor?.nickname}</td>
                            <td>{row.bucket.id ? <Link href="#" onClick={onBucketClick(row.bucket)}>{bucket_name_pipe(row.bucket)}</Link> : '-'}</td>
                            <td>{row.place.name || '-'}</td>
                            <td>{row.toPlace.name || '-'}</td>
                            <td>{row.trader.name || '-'}</td>
                            <td align='right'>{number_format_pipe(row.amount)}</td>
                            <td align='right'>{number_format_pipe(row.hAmount)}</td>
                            <td>{datetime_pipe(row.createdAt)}</td>
                            <td>{datetime_pipe(row.processedAt)}</td>
                        </tr>)}
                    </tbody>
                </table>}

                {loading && (<Loading />)}
                
                {fetched && rows.length === 0 && <Nodata>조회된 데이터가 없습니다.</Nodata>}
            </div>

            <BucketModal bucketId={selectedBucketId} open={bucketModalOpen} setOpen={setBucketModalOpen} />
        </>
	);
}

export default SimpleHistory;