import { Stack, Button } from '@mui/material';
import React from 'react';



const PlaceSelection = ({ places, selected, handlePlaceSelected }) => {
    return (
        <Stack direction="row" spacing={1}>
            {places.map(place => 
                <Button 
                    key={place.id} 
                    color="primary" 
                    variant={selected === place ? 'contained' : 'outlined'} 
                    size="large"
                    onClick={() => { handlePlaceSelected(place); }}
                >
                    {place.name}
                </Button>
            )}
        </Stack>
    );
}

export default PlaceSelection;