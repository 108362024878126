import { createContext, useContext, useReducer } from "react";


const PlaceContext = createContext(null);
const PlaceDispatchContext = createContext(null);


export function PlaceProvider({ place, children }) {
    if (!place) throw new Error('need place');

    const [ placeValue, dispatch ] = useReducer(reducer, place);

    return (
        <PlaceContext.Provider value={placeValue}>
            <PlaceDispatchContext.Provider value={dispatch}>
                {children}
            </PlaceDispatchContext.Provider>
        </PlaceContext.Provider>
    )
}

export function usePlace() {
    return useContext(PlaceContext);
}

export function usePlaceDispath() {
    return useContext(PlaceDispatchContext);
}


function reducer(place, action) {
    switch (action.type) {
        case 'modified': {
            const { value } = action;

            return ({
                ...value,
            })
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}