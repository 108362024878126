import React from 'react';
import { Box } from '@mui/material';
import SelectSpace from 'components/SelectSpace';
import Traders from './Traders';
import styles from 'styles.module.css';
import { useSpace } from 'contexts/space_v2.context';


const TraderPage = () => {
    const space = useSpace();

    if (!space) return (<SelectSpace />);

	return (
        <Box className={styles.page}>
            <Traders />
        </Box>
	);
}

export default TraderPage;