import React from "react";
import StarIcon from '@mui/icons-material/Star';
import { Box } from "@mui/material";


const Emphasize = ({ children }) => {
    return (
        <Box sx={{ backgroundColor: '#e1f5fe', p: 2, color: '#01579b', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <StarIcon />
            <Box sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, display: 'flex', flexDirection: 'column' }}>
                {children}
            </Box>
        </Box>
    );
}

export default Emphasize;