import React, { useState, useContext, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Box, Paper, Typography, Button } from '@mui/material';
import colors from 'constants/colors';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import { Link } from 'react-router-dom';
import globals from 'constants/globals';
import { AuthContext } from 'contexts/auth.context';
import API from 'api';
import { MemoryContext } from 'contexts/memory.context';
import { AppContext } from 'contexts/app.context';



const SigninPage = () => {
	const {showAlert} = useContext(AppContext);
	const { me, syncAuth } = useContext(AuthContext);
	const { routeAfterLogin } = useContext(MemoryContext);

	function handleCredentialResponse(response) {
		console.log('handleCredentialResponse');
		const jwt = response.credential;
		const body = {
			jwt
		};
		API.post('/auth/google-signin', body)
		.then(() => {
			return syncAuth();
		})
		.then(routeAfterLogin)
	}
	// end: google signin

	// facebook login 삭제: 페이스북 측에서 개인정보처리방침이 제대로 설명되어 있지 않다고 하는데, 어떻게 해결해야할지 잘 모르겠음, 고민하다가 그냥 노출하지 않기로 결정

	return (
		<Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
			<Paper elevation={3} sx={{ padding: 4, display: 'inline-flex', width: '320px', flexDirection: 'column', backgroundColor: colors.lime }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingY: 5 }}>
					<img alt="logo" src={`${process.env.REACT_APP_HOST}/images/antpocket-logo.png`} style={{ width: '200px', height: '170px' }} />
					<Typography variant='h4' sx={{ fontFamily: 'Audiowide', marginTop: 3, fontWeight: 'bold' }}>{globals.appName}</Typography>
					<Typography variant='body2' sx={{ marginTop: 1, fontWeight: 'bold' }}>내 손안의 재고관리</Typography>
				</Box>
				<Box sx={{ backgroundColor: 'white', marginLeft: -4, marginRight: -4, marginBottom: -4,  borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', display: 'flex', flexDirection: 'column', alignItems: 'center', py: 5 }}>
					{!me ? <>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<GoogleLogin
								onSuccess={handleCredentialResponse}
								onError={() => {
									console.log('Google Login Failed');
									showAlert('구글: 로그인 실패');
								}}
							/>

							<Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch', my: 2 }}>
								<Box sx={{ flex: 1, height: '1px', borderTop: '1px solid #dddddd' }} ></Box>
								<Typography variant='caption' sx={{ mx: 1 }}>또는</Typography>
								<Box sx={{ flex: 1, height: '1px', borderTop: '1px solid #dddddd' }} />
							</Box>
							<Button component={Link} to={'/auth/password-signin'} color={'inherit'} variant="outlined" startIcon={<EmailIcon />}>Email 로 시작하기</Button>
						</Box>
					</> : <Box>
						<Typography>{`반갑습니다. ${me.nickname}님`}</Typography>
						<Link to={'/'}>홈으로 이동하기</Link>
					</Box>}
				</Box>
				<div id="customBtn" data-onsuccess="onSignIn"></div>
			</Paper>
		</Box>
	);
}

export default SigninPage;