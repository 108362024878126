import React from 'react';
import { Paper } from '@mui/material';
import Guide from 'components/Guide';



const GuidePage = () => {
    return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <Guide>this is guide paragraph.</Guide>
            </Paper>
        </div>
    );
}

export default GuidePage;