import { useEffect, useState } from "react";
import axios from "axios";
import API from "api";


export default function useItemAmountStatistics({ spaceId, sampleby, from, to, placeId, itemId }) {
    const [ row, setRow ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRow(null);
        setFetched(false);
        setError(null);
    }, [ spaceId, sampleby, from, to, itemId, placeId ]);

    useEffect(() => {
        if (!spaceId || !sampleby || !from || !to || !placeId || !itemId ) return;

        let cancel;
        setLoading(true);
        setError(false);
        const params = { 
            spaceId,
            sampleby,
            placeId,
            itemId,
            from,
            to,
        };
        API.get(`/statistics/item-amount`, { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setRow(data);
            setFetched(true);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ spaceId, sampleby, from, to, placeId, itemId ]);

    return { loading, error, row, fetched };
}