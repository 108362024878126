import { createContext, useContext, useReducer } from "react";


const SelectedContext = createContext(null);
const SelectedDispatchContext = createContext(null);


export function SelectedProvider({ selected, children }) {
    const [ selectedValue, dispatch ] = useReducer(selectedReducer, selected);

    return (
        <SelectedContext.Provider value={selectedValue}>
            <SelectedDispatchContext.Provider value={dispatch}>
                {children}
            </SelectedDispatchContext.Provider>
        </SelectedContext.Provider>
    )
}

export function useSelected() {
    return useContext(SelectedContext);
}

export function useSelectedDispatch() {
    return useContext(SelectedDispatchContext);
}


function selectedReducer(selected, action) {
    switch (action.type) {
        case 'toggle': {
            const { id } = action;

            const selectedIndex = selected.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }

            return newSelected;
        }

        case 'all': {
            const { checked, rows } = action;

            if (checked) {
                return rows.map((el) => el.id);
            }
            else return [];
        }

        case 'clear': {
            return ([]);
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}