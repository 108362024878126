import { useEffect, useRef, useState } from "react";
import API from "api";


export default function useSearchBucket(spaceId, params, pageTrigger) {
    const lastId = useRef(null);

    const [ rows, setRows ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        lastId.current = null;
    }, [ spaceId, params ]);

    useEffect(() => {
        if (!mayMore) return;
        if (!spaceId || !params) return;

        let controller = new AbortController();
        setLoading(true);
        
        const parameters = {
            ...params,
            lastId: lastId.current,
        };
        API.get(`/bucket/search-v2/${spaceId}`, { params: parameters, signal: controller.signal })
        .then(data => {
            const count = data.rows.length;
            setRows(prevs => prevs.concat(data.rows));
            setMayMore(count === data.limit);
            setFetched(true);
            if (count > 0) lastId.current = data.rows[count - 1].id;
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); }
    }, [ spaceId, params, pageTrigger, mayMore ]);

    return { loading, rows, mayMore, fetched };
}