import React, { useState, useEffect, Fragment } from 'react';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import getFromToGroupby from '../getFromToGroupby';
import CircularButton from 'components/CircularButton';
import Guide from 'components/Guide';
import ItemSelectionDialog from 'components/item/selection/ItemSelectionDialog';
import PeriodInput from 'components/PeriodInput';
import { useSpace } from 'contexts/space_v2.context';


const PeriodDivisions = [
    { key: 'month', name: '월별' },
    { key: 'week', name: '주별' },
    { key: 'day', name: '일별' },
];

const Filter = ({ searchDisabled, item, setItem }) => {
    const [ searchParams, setSearchParams ] = useSearchParams();

    const space = useSpace();

    const [ sampleby, setSampleby ] = useState();
    const [ place, setPlace ] = useState();
    const [ itemId, setItemId ] = useState();
	const [ from, setFrom ] = useState(null);
	const [ to, setTo ] = useState(null);

    useEffect(() => {
        if (!space) return;

        const sampleby = searchParams.get('sampleby') || 'month';
        const placeId = searchParams.get('placeId');
        const itemId = searchParams.get('itemId');
        let from = searchParams.get('from');
        let to = searchParams.get('to');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby(sampleby, from, to);

        setSampleby(sampleby);
		setPlace(space.places.find(el => el.id == placeId));
		setItemId(itemId);
        setFrom(fromDate);
        setTo(toDate);
    }, [ space, searchParams ]);

    const onSearchClick = (event) => {
		event.preventDefault();
		
        const sParams = {
            sampleby,
            from: from ? format(from, 'yyyy-MM-dd') : '',
            to: to ? format(to, 'yyyy-MM-dd') : '',
		};
		if (place) sParams.placeId = place.id;
		if (itemId) sParams.itemId = itemId;
		setSearchParams(sParams);
	}

    // sampleby 가 변경될때마다 기간 초기화
    const handleChangeSampleby = (event) => {
        const sampleby_a = event.target.value;
        const { from_a, to_a, fromDate, toDate } = getFromToGroupby(sampleby_a, '', '');

        setSampleby(sampleby_a);
        setFrom(from_a);
        setTo(to_a);
    }

    // item selection
	const [ itemSelectionOpen, setItemSelectionOpen ] = useState(false);

    if (!space) return null;

	return (
		<Box>
            <form onSubmit={onSearchClick} style={{ marginBottom: '24px' }}>
                <div className="mt-4 input-group input-group-sm" style={{ maxWidth: '480px' }}>
                    {/* 창고 */}
                    <span className="input-group-text">창고</span>
                    <select className="form-select" value={place?.id || ''} onChange={event => setPlace(space.places.find(el => el.id == event.target.value))}>
                        <option value=""></option>
                        {space.places.map(place => <option key={place.id} value={place.id}>{place.name}</option>)}
                    </select>

                    {/* 제품 */}
                    <span className="input-group-text ms-1">제품</span>
                    <input className='form-control' value={item ? item.name : (itemId ? '제품선택됨' : '')} readOnly />
                    {itemId
                        ? <button type="button" className='btn btn-secondary' onClick={() => { setItemId(null); setItem(null) }}>선택해제</button>
                        : <button type="button" className='btn btn-secondary' onClick={() => { setItemSelectionOpen(true); }}>선택</button>
                    }
                </div>

                {itemId && place && <>
                    <div className="my-2 input-group input-group-sm" style={{ maxWidth: '320px' }}>
                        {/* 기간구분 */}
                        <span className="input-group-text">기간구분</span>
                        <select className="form-select" value={sampleby} onChange={handleChangeSampleby}>
                            <option value=""></option>
                            {PeriodDivisions.map(el => <option key={el.key} value={el.key}>{el.name}</option>)}
                        </select>
                    </div>
                    
                    {/* sampleby 가 변경됨에 따라 다시 그려야 함 */}
                    <Fragment key={sampleby}>
                        <PeriodInput
                            initialStartDate={from}
                            initialEndDate={to}
                            onPeriodChanged={(start, end) => {
                                setFrom(start);
                                setTo(end);
                            }}
                            options={{
                                baseDateText: '수행일시 기준'
                            }}
                        />
                    </Fragment>
                    
                    
                    <CircularButton type="submit" style={{ marginTop: '16px', marginBottom: '24px' }} variant='contained' loading={searchDisabled}>검색</CircularButton>
                </>}
            </form>
            
            {(!itemId || !place) && <Box sx={{ mb: 2 }}><Guide>제품과 창고를 선택후 검색하세요.</Guide></Box>}

            <ItemSelectionDialog
                open={itemSelectionOpen}
                setOpen={setItemSelectionOpen}
                onSelect={item => {
                    setItemId(item.id)
                    setItem(item);
                }}
            />
		</Box>
	);
}

export default Filter;