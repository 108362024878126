import React from "react";
import SchoolIcon from '@mui/icons-material/School';
import { Box } from "@mui/material";


const Glossary = ({ children }) => {
    return (
        <Box sx={{ backgroundColor: '#e0f2f1', p: 2, color: 'rgb(0,121,107)', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <SchoolIcon />
            <Box sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, display: 'flex', flexDirection: 'column' }}>
                {children}
            </Box>
        </Box>
    );
}

export default Glossary;