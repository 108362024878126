import * as React from 'react';
import { Box, Dialog, Typography, Button, Slide } from '@mui/material';
import colors from 'constants/colors';
import { Link } from 'react-router-dom';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NeedLoginModal() {

    return (
        <Dialog
            fullScreen
            open={true}
            TransitionComponent={Transition}
        >
            <Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>로그인이 필요합니다.</Typography>
                {/* todo: button 을 link component 로 */}
                <Button component={Link} variant="contained" style={{ marginTop: '8px' }} to="/auth/signin">로그인 하러가기</Button>
            </Box>
        </Dialog>
    );
}