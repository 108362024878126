import React from 'react';
import { Box, Typography } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import InfoRow from 'components/InfoRow';
import { usePlace } from 'contexts/place.context';


const PlaceInfo = () => {
    const place = usePlace();

    if (!place) return null;

	return (
        <div className={pageStyles.section}>
            <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
            </Box>
            <div className={pageStyles.table}>
                {InfoRow('창고', place.name)}
                {InfoRow('순번', place.order)}
            </div>
        </div>
	);
}

export default PlaceInfo;