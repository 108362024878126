import React, { useEffect, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { Box, Tab, Tabs, Typography, Button } from '@mui/material';
import RenderingPage from 'views/RenderingPage';
import MySpaces from 'views/space/MySpaces';
import colors from 'constants/colors';
import MyAccount from 'views/my/MyAccount';
import MyAlarms from 'views/my/MyAlarms';
import TicketTab from 'views/ticket/TicketTab';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import CircularButton from 'components/CircularButton';

  
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
  
const TabInfo = [
    { key: 'myspaces', name: '내 스페이스', component: <MySpaces /> },
    { key: 'myaccount', name: '내 계정', component: (<MyAccount />) },
    { key: 'myalarms', name: '알림내역', component: (<MyAlarms />) },
    { key: 'inquiry', name: '문의', component: (<TicketTab />) },
    // { key: 'bill', name: '결제내역', component: (<BillPage />) },
];

function HomePage() {
    const { me, syncAuth } = useContext(AuthContext);
    const { showSnackbar } = useContext(AppContext);

    // search params
    const [searchParams, setSearchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = useState(0);
    
    useEffect(() => {
        const tab = searchParams.get('tab') || TabInfo[0].key;
        const item = TabInfo.find(el => el.key === tab);
        const idx = TabInfo.indexOf(item);
        setTabIndex(idx);
    }, [ searchParams ]);
    
    const handleTabChange = (event, newValue) => {
        const tabKey = TabInfo[newValue].key;
        setSearchParams({ tab:  tabKey });
    };
    // end: search params

    // email authentication
    const reloadAuth = () => {
        API.get('/auth/ping')
        .then(syncAuth);
    }

    const [ emailLoading, setEmailLoading ] = useState(false);
    const handleReauthenticate = () => {
        setEmailLoading(true);
        API.post('/auth/send-email-authentication-email')
        .then(() => { showSnackbar(`${me.email}로 인증메일을 보냈습니다. 확인해보세요.`); })
        .finally(() => { setEmailLoading(false); })
    }
    // end: email authentication

    if (!me) return (<RenderingPage />);

    return (
        <Box sx={{ height: '100vh', backgroundColor: colors.gray100 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'white' }}>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    {TabInfo.map(item => <Tab key={item.key} label={item.name} />)}
                </Tabs>
            </Box>

            {me.state === 'pending' && <Box style={{ display: 'flex', alignItems: 'center', borderLeft: '4px solid #ff6333', padding: '0.7rem 1rem', backgroundColor: colors.primary + '22' }}>
                <Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}>이메일 인증이 필요합니다. 인증 후 새로고침 해주세요.</Typography>
                <Button size="small" variant="outlined" sx={{ marginLeft: 3, color: 'primary.dark' }} onClick={reloadAuth}>새로고침</Button>
                <CircularButton style={{ display: 'inline' }} loading={emailLoading} size="small" variant="outlined" sx={{ marginLeft: 1, color: 'text.secondary' }} onClick={handleReauthenticate}>인증메일 재전송하기</CircularButton>
            </Box>}

            {TabInfo.map((item, index) => <TabPanel key={item.key} value={tabIndex} index={index}>
                <span>{item.component}</span>
            </TabPanel>)}
        </Box>
    );
}

export default HomePage;