export default function selectedReducer(selected, action) {
    switch (action.type) {
        case 'toggle': {
            const { idx } = action;

            const selectedIndex = selected.indexOf(idx);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, idx);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }

            return newSelected;
        }

        case 'all': {
            const { checked, rows } = action;

            if (checked) {
                return rows.map((n, idx) => idx);
            }
            else return [];
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}