import React, { useContext } from 'react';
import { Checkbox, Typography, LinearProgress, Button } from '@mui/material';
import { RowsContext, RowsDispatchContext, RowsProcessContext } from './Rows.context';
import colors from 'constants/colors';


const InputColumn = ({ rowIdx, columnIdx }) => {
    const rows = useContext(RowsContext);
    const dispatch = useContext(RowsDispatchContext);

    const handleChange = (event) => {
        dispatch({
            type: 'set',
            rowIdx: rowIdx,
            columnIdx: columnIdx,
            value: event.target.value || null,
        });
    }

    const value = rows[rowIdx].columns[columnIdx] ?? '';

    return (
        <input
            className='form-control form-control-sm'
            value={value}
            onChange={handleChange}
            disabled={rows[rowIdx].process.state === 'processed'}
        />
    );
}

const Row = ({ idx, selected, toggleCheckbox }) => {
    const rows = useContext(RowsContext);
    const { handleAddItem } = useContext(RowsProcessContext);

    const row = rows[idx];

    let processMessage;
    let color;
    switch(row.process.state) {
        case 'processed':
            processMessage = '등록완료';
            color = colors.success;
            break;
        case 'processFailed':
            processMessage = row.process.message ? `처리실패: ${row.process.message}` : '처리실패';
            color = colors.error;
            break;
        default:
            processMessage = '-';
            color = colors.textPrimary;
    }

    return (
        <tr>
            <td>
                <Checkbox
                    size='small'
                    color="primary"
                    checked={selected.indexOf(idx) > -1}
                    onClick={(event) => toggleCheckbox(idx)}
                />
            </td>
            {row.columns.map((column, columnIdx) => <td key={columnIdx}>
                <InputColumn
                    rowIdx={idx}
                    columnIdx={columnIdx}
                />
            </td>)}
            <td><Button size='small' variant='outlined' color='primary' disabled={row.process.state === 'processed' || row.process.state === 'loading'} onClick={() => { handleAddItem(idx); }}>등록</Button></td>
            <td>{row.process.state === 'loading' ? (<LinearProgress />) : <Typography variant='body2' color={color}>{processMessage}</Typography>}</td>
        </tr>
    );
}

export default function BatchRegisterTable({ headers, selected, dispatchSelected }) {
    const rows = useContext(RowsContext);

    const onSelectAllClick = (event) => {
        dispatchSelected({
            type: 'all', 
            checked: event.target.checked,
            rows,
        });
    }

    const toggleCheckbox = (idx) => {
        dispatchSelected({
            type: 'toggle', 
            idx: idx,
        });
    };

    if (!rows) return null;

    return (
        <div className="table-responsive">
            <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                <thead>
                    <tr>
                        <th scope="col">
                            <Checkbox
                                size='small'
                                color="primary"
                                indeterminate={selected.length > 0 && selected.length < rows.length}
                                checked={selected.length > 0 && selected.length === rows.length}
                                onChange={onSelectAllClick}
                            />
                        </th>
                        {headers.map((header, idx) => <th key={idx}>{`${header.name}${header.division === 'place' ? ': 창고 내 제품 수량' : ''}${header.isReq ? ' (필수)' : ''}`}</th>)}
                        <th scope="col">처리</th>
                        <th scope="col">처리상태</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, idx) => (
                        <Row
                            key={idx}
                            idx={idx}
                            selected={selected}
                            toggleCheckbox={toggleCheckbox}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}