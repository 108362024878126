import React, { Fragment } from 'react';
import { Box, Typography, Link as MuiLink, Divider, Button } from '@mui/material';
import styles from 'styles.module.css';
import { item_name_pipe, number_format_pipe } from 'pipes';
import { Link, useNavigate } from 'react-router-dom';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import colors from 'constants/colors';



const PlaceSummary = ({ place }) => {
    const navigate = useNavigate();

	return (
		<Box>
            <Box>
                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <HouseOutlinedIcon fontSize='small' sx={{ mr: 1, color: colors.storeColor }} />
                        <MuiLink variant='h6' color={'textPrimary'} component={Link} to={`/space/history/place?placeId=${place.id}`}>{place.name}</MuiLink>
                    </Box>
                    <Typography variant="overline" color="textSecondary">{`[제품 수량] ${number_format_pipe(place.amount)}`}</Typography>
                </Box>
                <Divider />
                <Box display={'flex'} my={1}>
                    <Button variant='outlined' size="small" onClick={() => { navigate(`/space/action/place?initialPlaceId=${place.id}`) }}>재고관리</Button>
                </Box>
                <Divider />
            </Box>

            <div className={styles.table} style={{ marginTop: '24px' }}>
                {place.items.slice(0,10).map(item => <Fragment key={item.id}>
                    {Row(item_name_pipe(item), number_format_pipe(item.amount))}
                </Fragment>)}
            </div>
            {place.items.length > 10 && <MuiLink component={Link} to={`/space/history/place?placeId=${place.id}`}>더보기</MuiLink>}
		</Box>
	);
}

const Row = (label, value) => (<div className={styles.row}>
    <div style={{
        backgroundColor: '#f0f0f0',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        flex: 3,
        whiteSpace: 'nowrap',
    }}>
        <Typography variant="body2" className={styles.labelText}>{label}</Typography>
    </div>
    <div style={{
        padding: '8px 16px',
        flex: 1,
    }}>
        <Typography variant="body2">{value}</Typography>
    </div>
</div>)

export default PlaceSummary;