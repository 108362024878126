import colors from 'constants/colors';
import { actionCodes } from 'constants/globals';
import { formatISO, parse } from 'date-fns';


const datetime_pipe = (datetext) => {
    if (!datetext) return '';
    const date = new Date(datetext);
    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${date.getHours()}시 ${date.getMinutes()}분`;
}

const date_pipe = (datetext) => {
    if (!datetext) return '';
    const date = new Date(datetext);
    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
}

const iso_datetime_pipe = (datetimeText) => {
    return datetimeText ? formatISO(parse(datetimeText, 'yyyy-MM-dd', new Date())) : '';
}

const number_format_pipe = (x) => {
    if (x === null) return '';
    if (!x) return x;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const mobile_pipe = (mobile) => {
    if (!mobile) return '';
    if (mobile.length === 11) return `${mobile.slice(0, 3)}-${mobile.slice(3, 7)}-${mobile.slice(7, 11)}`;
    return mobile;
}

const filename_frompath_pipe = (filePath) => {
    if (!filePath) return '';
    const start = filePath.lastIndexOf("/");
    if (start < 0) return '';
    return filePath.slice(start + 1);
}

const item_props_pipe = (item) => {
    return item.props.map(prop => {
        const value = prop.values.val || prop.values.numVal || ''
        return `${prop.name}: ${value}`;
    }).join(', ');
}

const actioncode_pipe = (code) => {
    const result = actionCodes.find(el => el.code === code)?.name;
    return result || '';
}

const actioncode_color_pipe = (code) => {
    switch (code) {
        case 'store': return colors.storeColor;
        case 'release': return colors.releaseColor;
        case 'adjustment': return colors.adjustmentColor;
        default: return colors.moveColor;
    }
}

const request_state_pipe = (request) => {
    switch (request.state) {
        case 'requested': return '요청';
        case 'canceled': return '취소됨';
        case 'replied': 
            if (request.isAccepted) return '수락';
            else return '거절';
        case 'confirmed': return '완료';
        default: return '-';
    }
}

const bucket_name_pipe = (bucket) => {
    if (!bucket) return '';
    return bucket.name || `입출고명세서_${bucket.id}`;
}

const bucket_state_pipe = (state) => {
    switch (state) {
        case 'making': return '작성중';
        case 'submitted': return '확인대기';
        case 'confirmed': return '수행완료';
        case 'rejected': return '반려';
        default: return '';
    }
}

const item_name_pipe = (item) => {
    if (!item) return '';
    return item.code ? `${item.name} [${item.code}]` : item.name;
}

const plan_name_pipe = (code) => {
    switch (code) {
        case 'basic': return '베이직 (Basic)';
        case 'business': return '비지니스 (business)';
        default: return '';
    }
}

const page_division_pipe = (division) => {
    switch (division) {
        case 'item_amount': return '모든 제품현황';
        case 'place_item_amount': return '창고별 제품현황';
        default: return '';
    }
}


export {
    datetime_pipe,
    date_pipe,
    iso_datetime_pipe,
    number_format_pipe,
    mobile_pipe,
    filename_frompath_pipe,
    request_state_pipe,
    bucket_name_pipe,
    bucket_state_pipe,

    item_name_pipe,
    item_props_pipe,
    actioncode_pipe,
    actioncode_color_pipe,

    plan_name_pipe,

    page_division_pipe,
}