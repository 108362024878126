import { createTheme, responsiveFontSizes } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import colors from 'constants/colors';


let theme = createTheme({
    typography: {
        fontFamily: [
            '"Lexend Deca"',
            '"Spoqa Han Sans Neo"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
    },
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: deepOrange['A400'],
            // light: '#ffffff',
            // dark: '#c8b7b5',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 2
                }
            },
            defaultProps: {
                disableElevation: true
            }
        },

    }
})

theme = responsiveFontSizes(theme);

export default theme;