import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Button } from '@mui/material';
import API from 'api';
import Loading from 'components/Loading';
import Title from 'components/Title';
import PaperCard from './PaperCard';
import { AppContext } from 'contexts/app.context';
import Guide from 'components/Guide';
import PlaceSelectionModal from './PlaceSelectionModal';
import { useSpace } from 'contexts/space_v2.context';


const MyPapersPage = () => {
    const { showSnackbar } = useContext(AppContext);
    const space = useSpace();

    const [ papers, setPapers ] = useState();
    const [ trigger, setTrigger ] = useState();

    useEffect(() => {
        if (!space) return;

        API.get(`/paper/my-space-papers/${space.id}`)
        .then(setPapers);

        return () => setPapers(null);
    }, [ space, trigger ]);
    const reloadMypapers = () => { setTrigger(new Date().getTime()); }

    const handleAddItemAmountPaper = () => {
        const body = {
            spaceId: space.id,
            meta: {
                version: 1,
                division: 'item_amount'
            },
            visibility: 'private',
        }
        API.post('/paper', body)
        .then(() => {
            reloadMypapers();
        })
    }

    const onAddPlaceItemAmountPaperClick = () => {
        setPlaceModalOpen(true);
    }

    const handleAddPlaceItemAmountPaper = (placeIds) => {
        const body = {
            spaceId: space.id,
            meta: {
                version: 1,
                division: 'place_item_amount',
                places: placeIds
            },
            visibility: 'private',
        }
        API.post('/paper', body)
        .then(() => {
            reloadMypapers();
        })
    }

    const handleModifyTitle = (paper, title) => {
        const body = {
            uuid: paper.uuid,
            spaceId: space.id,
            title,
            meta: paper.meta,
        }
        API.put('/paper', body)
        .then(() => {
            reloadMypapers();
        });
    }

    const handleChangeVisibility = (uuid, visibility, password) => {
        const body = {
            spaceId: space.id,
            visibility,
            password
        }
        API.put(`/paper/${uuid}/change-visibility`, body)
        .then(() => {
            showSnackbar('설정했습니다.');
            reloadMypapers();
        })
    }

    const handleRemove = (paper) => {
        API.delete(`/paper/${paper.uuid}?spaceId=${space.id}`)
        .then(() => {
            showSnackbar('삭제했습니다.');
            reloadMypapers();

        })
    }

    const [ placeModalOpen, setPlaceModalOpen ] = useState(false);

	return (
		<Box>
            <Title>내가 만든 페이퍼</Title>

            {papers ? <>
                {papers.length > 0 ? <>
                    <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
                        {papers.map((paper) => <Grid item key={paper.uuid} xs={6} md={4} lg={3} xl={2}>
                            <PaperCard 
                                paper={paper}
                                // reloadCommerce={reloadCommerce}
                                handleModifyTitle={handleModifyTitle}
                                handleRemove={handleRemove}
                                handleChangeVisibility={handleChangeVisibility}
                            />
                        </Grid>)}
                    </Grid>
                    <Guide style={{ marginTop: '24px' }}>현재 페이퍼는 스페이스 멤버만 볼 수 있습니다. 곧 누구나 볼 수 있거나 원하는 파티에게만 볼 수 있도록 하는 기능이 추가될 예정입니다.</Guide>
                </> : <Guide style={{ marginTop: '24px' }}>아래 버튼을 클릭해서 페이퍼를 생성하세요.</Guide>}

                <Box mt={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start' }}>
                    <Button variant="contained" color="primary" onClick={handleAddItemAmountPaper}>모든 제품현황을 볼 수 있는 페이퍼 만들기</Button>
                    <Button variant="contained" color="primary" sx={{ ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 } }} onClick={onAddPlaceItemAmountPaperClick}>특정 창고의 제품현황을 볼 수 있는 페이퍼 만들기</Button>
                </Box>
            </> : (<Loading />)}

            <PlaceSelectionModal open={placeModalOpen} setOpen={setPlaceModalOpen} places={space.places} onSelect={handleAddPlaceItemAmountPaper} />
		</Box>
	);
}

export default MyPapersPage;