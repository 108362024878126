import { useEffect, useState } from "react";
import API from "api";


export default function useTraderReleaseAmount(params) {
    const [ rows, setRows ] = useState();
    const [ resultParams, setResultParams ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (!params.spaceId || !params.from || !params.to) return;

        let controller = new AbortController();

        setLoading(true);
        API.get(`/statistics/trader-release`, { params, signal: controller.signal })
        .then(data => {
            setRows(data.rows);
            setResultParams(data.params);
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); setRows(null); setResultParams(null); };
    }, [ params ]);

    return { loading, rows, resultParams };
}