import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Container, Paper, TextField, Button, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { AppContext } from 'contexts/app.context';
import { Formik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import CircularButton from 'components/CircularButton';
import MyAlarmSetup from './MyAlarmSetup';
import pageStyles from '../../css/page-style.module.css';
import MyBillings from './MyBillings';
import PasswordChangeModal from './PasswordChangeFormModal';


// 내계정
const MyAccount = () => {
	const navigate = useNavigate();

    const { showSnackbar } = useContext(AppContext);
    const { me, syncAuth, logout } = useContext(AuthContext);

    // 로그아웃
    const handleLogout = () => {
        logout()
        .then(() => { navigate('/auth/signin');})
    }

    // 계정삭제
    const [ leaveAppModalOpen, setLeaveAppModalOpen ] = useState(false);
    const [ leaveAppText, setLeaveAppText ] = useState('');
    const handleLeaveApp = () => {
        setLeaveAppModalOpen(false);
        if (leaveAppText === me.email) {
            API.delete('/auth/leave-app')
            .then(syncAuth)
            .then(() => { 
                navigate('/'); 
            });
        }
        else {
            showSnackbar('정확히 입력되지 않았습니다.');
        }
    }

    // password 인증방식이 있는지
    const [ havePasswordAuthMethod, setHavePasswordAuthMethod ] = useState();
    useEffect(() => {
        API.get('/auth/my-auth-methods')
        .then(method => {
            setHavePasswordAuthMethod(method.password);
        })
    }, []);

    const [ passwordChangeModalOpen, setPasswordChangeModalOpen ] = useState(false);

	return (
		<Box sx={{ py: 4, backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Container maxWidth="sm">
                
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" style={{ fontWeight: 'bold', mb: 1 }}>계정</Typography>
                        <Divider />

                        {/* 내정보 */}
                        <Formik
                            initialValues={{
                                nickname: me.nickname,
                            }}
                            validationSchema={yup.object({
                                nickname: yup.string().trim().required('필수입력입니다.').min(2, "2자 이상 입력해주세요.").max(12, "12자 이하로 입력해주세요."),
                            })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                const body = {
                                    nickname: values.nickname.trim(),
                                }
                                API.put('/auth/myinfo', body)
                                .then(() => { showSnackbar('업데이트했습니다.') })
                                .then(syncAuth)
                                .finally(() => { setSubmitting(false); });
                            }}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, getFieldProps }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box className={pageStyles.section}>
                                        <Typography variant="subtitle2">내정보</Typography>
                                        <TextField
                                            sx={{ mt: 2 }}
                                            fullWidth
                                            size="small"
                                            label="이메일"
                                            variant="outlined"
                                            disabled
                                            value={me.email}
                                        />
                                        <TextField
                                            sx={{ mt: 4 }}
                                            required
                                            fullWidth
                                            size="small"
                                            label="닉네임"
                                            variant="outlined"
                                            error={!!errors.nickname}
                                            helperText={(touched.nickname && errors.nickname) || "스페이스 멤버들에게 노출되는 이름입니다."}
                                            {...getFieldProps('nickname')}
                                        />
                                        <CircularButton type="submit" sx={{ mt: 2 }} variant="outlined" size="small" loading={isSubmitting}>수정</CircularButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>

                        {havePasswordAuthMethod && <>
                            <Divider sx={{ my: 2 }} />
                            <Box className={pageStyles.section}>
                                <Typography variant="subtitle2">비밀번호 변경</Typography>
                                <Typography variant="caption" className={pageStyles.guideText}>비밀번호를 변경하려면 버튼을 누르세요.</Typography>
                                <Button type="button" variant='outlined' sx={{ mt: 2, alignSelf: 'flex-start' }} size="small" onClick={() => { setPasswordChangeModalOpen(true); }}>변경</Button>
                            </Box>
                            <PasswordChangeModal open={passwordChangeModalOpen} setOpen={setPasswordChangeModalOpen} />
                        </>}

                        {/* 계정삭제 */}
                        <Divider sx={{ my: 2 }} />                        
                        <Box className={pageStyles.section}>
                            <Typography variant="subtitle2">계정삭제</Typography>
                            <Typography variant="caption" className={pageStyles.guideText} style={{ whiteSpace: 'pre-line' }}>{'Antpocket 에서 사용자 데이터를 영구히 삭제합니다.\n삭제 후 데이터를 복원 할 수 없습니다.'}</Typography>
                            <Button type="button" variant='outlined' sx={{ mt: 2, alignSelf: 'flex-start' }} size="small" color='error' onClick={() => { setLeaveAppModalOpen(true); }}>계정 삭제하기</Button>
                        </Box>
                        <Dialog open={leaveAppModalOpen} onClose={() => { setLeaveAppModalOpen(false); }}>
                            <DialogTitle>계정삭제</DialogTitle>
                            <DialogContent>
                                <DialogContentText>본인의 이메일을 정확히 입력해주세요. 확인시 계정이 삭제됩니다.</DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="이메일"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                    value={leaveAppText}
                                    onChange={(event) => { setLeaveAppText(event.target.value); }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { setLeaveAppModalOpen(false); }}>취소</Button>
                                <Button color={'error'} onClick={handleLeaveApp}>확인</Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>


                {/* 알림 설정 */}
                <Box sx={{ mt: 4 }}>
                    <MyAlarmSetup />
                </Box>

                {/* 결제 방법 */}
                <Box sx={{ mt: 4 }}>
                    <MyBillings />
                </Box>

                <Box sx={{ mt: 4 }}><Button size='large' variant="contained" color="secondary" onClick={handleLogout}>로그아웃</Button></Box>
            </Container>

            
        </Box>
	);
}

export default MyAccount;