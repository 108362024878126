import React, { useState, useContext, useEffect } from 'react';
import { Box, MenuItem, TextField, FormControlLabel, Checkbox, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import globals from 'constants/globals';
import CircularButton from 'components/CircularButton';


const Filter = ({ searchDisabled }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { me } = useContext(AuthContext);

	const [userId, setUserId] = useState();
	const [keyword, setKeyword] = useState();
	const [state, setState] = useState();

	useEffect(() => {
        const keyword = searchParams.get('keyword') || '';
		const userId = searchParams.get('userId');
		const state = searchParams.get('state') || '';

		setKeyword(keyword);
		setUserId(userId);
		setState(state);
	}, [ searchParams ]);

	const onSearchClick = (event) => {
		event.preventDefault();
		handleSearch();
	}

	const handleSearch = () => {
		const sParams = {
			page: 0,
		};
		if (keyword.trim()) sParams.keyword = keyword;
		if (userId) sParams.userId = userId;
		if (state) sParams.state = state;
		setSearchParams(sParams);
    }

    if (!me) return null;

	return (
		<form onSubmit={onSearchClick}>
            <Stack mt={3} spacing={1}>
                <div className="input-group input-group-sm" style={{ maxWidth: '480px' }}>
                    {/* 상태 */}
                    <span className="input-group-text">상태</span>
                    <select className="form-select" value={state} onChange={event => { setState(event.target.value) }}>
                        <option value="">모두</option>
                        {globals.bucketStates.map(el => <option key={el.key} value={el.key}>{el.name}</option>)}
                    </select>

                    {/* 키워드 */}
                    <span className="input-group-text ml-1">키워드</span>
                    <input className="form-control" placeholder='제품명 또는 제품코드 입력' value={keyword} onChange={event => setKeyword(event.target.value)} />
                </div>

                <div className="input-group input-group-sm" style={{ maxWidth: '200px' }}>
                    {/* 내가 작성한 입출고명세서 */}
                    <div class="input-group-text">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            checked={userId == me.id}
                            onChange={event => {
                                const userId_a = userId ? null : me.id
                                setUserId(userId_a);
                        }} />
                    </div>
                    <input className='form-control' disabled={true} value='내가 작성한 명세서만 보기' />
                </div>

                <CircularButton type="submit" variant='contained' loading={searchDisabled}>검색</CircularButton>
            </Stack>
        </form>
	);
}

export default Filter;