import React, { useContext } from 'react';
import { Box, TextField, Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import CircularButton from 'components/CircularButton';


const PasswordChangeModal = ({ open, setOpen }) => {
    const { showSnackbar, showAlert } = useContext(AppContext);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            rePassword: '',
        },
        validationSchema: yup.object().shape({
            oldPassword: yup.string().required('필수입력입니다.'),
            newPassword: yup.string().required('필수입력입니다.').matches(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/, '8자 이상, 숫자/영문자 최소 1자 이상 입력해주세요.'),
            rePassword: yup.string().oneOf([yup.ref('newPassword'), ''], '비밀번호 확인란이 일치하지 않습니다.')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = values;
            
            API.put('/user/my-password', body)
            .then(() => {
                showSnackbar('비밀번호를 변경했습니다.');
                resetForm();
                setOpen(false);
            })
            .finally(() => { setSubmitting(false); })
        },
    });
    const { values, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={() => { setOpen(false); }}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{'비밀번호 변경'}</DialogTitle>

                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            required
                            sx={{ mt: 2 }}
                            label={(touched.oldPassword && errors.oldPassword) || '기존 비밀번호'}
                            type="password"
                            variant="outlined"
                            size="small"
                            error={!!(touched.oldPassword && errors.oldPassword)}
                            {...getFieldProps('oldPassword')}
                        />
                        <TextField
                            required
                            sx={{ mt: 2 }}
                            label={(touched.newPassword && errors.newPassword) || '새 비밀번호'}
                            type="password"
                            variant="outlined"
                            size="small"
                            error={!!(touched.newPassword && errors.newPassword)}
                            {...getFieldProps('newPassword')}
                        />
                        <TextField
                            required
                            sx={{ mt: 2 }}
                            type="password"
                            label={(touched.rePassword && errors.rePassword) || '새 비밀번호 확인'}
                            variant="outlined"
                            size="small"
                            error={!!(touched.rePassword && errors.rePassword)}
                            {...getFieldProps('rePassword')}
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>취소</Button>
                    <CircularButton variant='contained' type="submit" loading={isSubmitting}>확인</CircularButton>
                </DialogActions>
            </form>
        </Dialog>
	);
}

export default PasswordChangeModal;