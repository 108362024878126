import React, { useContext, useState } from 'react';
import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import { AuthContext } from 'contexts/auth.context';
import { useNavigate } from 'react-router-dom';
import colors from 'constants/colors';
import { useSpace } from 'contexts/space_v2.context';


function AuthMenuButton() {
    const { me, logout } = useContext(AuthContext);
    const space = useSpace();

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = () => {
        logout()
        .then(() => {
            navigate('/auth/signin');
        })
    }

    const handleMyspacesClick = () => {
        navigate('/?tab=myspaces');
    }

    if (!me) return;

	return (
        <>
            <Box sx={{ cursor: 'pointer', display: 'flex' }} onClick={handleClick}>
                <Box sx={{ mr: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Typography variant='body1' sx={{ color: colors.textPrimary }}>{me?.nickname}</Typography>
                    {space && <Typography variant='body2' color={'text.secondary'}>{space.name}</Typography>}    
                </Box>
                <KeyboardArrowDownIcon fontSize='small' color={'primary'} />
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem sx={{ width: 200 }} onClick={() => { navigate('/?tab=myaccount' )}}>
                    <ListItemIcon><AccountCircleIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>내 계정</ListItemText>
                </MenuItem>
                <MenuItem sx={{ width: 200 }} onClick={() => { navigate('/?tab=myalarms' )}}>
                    <ListItemIcon><NotificationsNoneIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>알림</ListItemText>
                </MenuItem>

                <Divider />
                <MenuItem onClick={handleMyspacesClick}>
                    <ListItemIcon><HexagonOutlinedIcon fontSize='small' /></ListItemIcon>
                    <ListItemText>내 스페이스</ListItemText>
                </MenuItem>

                <Divider />
                <MenuItem onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <LogoutIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>로그아웃</ListItemText>
                </MenuItem>
            </Menu>
        </>
	);
}

export default AuthMenuButton;