import { createContext, useContext, useReducer, useState } from "react";


const ItemsContext = createContext(null);
const ItemsDispatchContext = createContext(null);
const ShowItemDetailContext = createContext(null);


export function ItemsProvider({ items, children }) {
    const [ itemsValue, dispatch ] = useReducer(reducer, items);

    // 제품 상세
    const [ selected, setSelected ] = useState(null);
    const [ itemDetailDialogOpen, setItemDetailDialogOpen ] = useState(false);
    const onItemSelected = (item) => {
        setSelected(null);
        
        // apifetch('/item/get_item?id=' + item.id)
        // .then((data) => {
        //     setSelected(data);
        //     setItemDetailDialogOpen(true);
        // })
        // .catch(basicErrorHandler);
    }
    // end: 제품 상세

    return (
        <ItemsContext.Provider value={itemsValue}>
            <ItemsDispatchContext.Provider value={dispatch}>
                <ShowItemDetailContext.Provider value={onItemSelected}>
                    <>
                        {children}

                        {/* <ItemDetailDialog
                            open={itemDetailDialogOpen}
                            setOpen={setItemDetailDialogOpen}
                            item={selected}
                        /> */}
                    </>
                </ShowItemDetailContext.Provider>
            </ItemsDispatchContext.Provider>
        </ItemsContext.Provider>
    )
}

export function useItems() {
    return useContext(ItemsContext);
}

export function useItemsDispath() {
    return useContext(ItemsDispatchContext);
}

export function useShowItemDetail() {
    return useContext(ShowItemDetailContext);
}


function reducer(items, action) {
    switch (action.type) {
        case 'row_modified': {
            const { id, value } = action;

            const idx = items.findIndex(el => el.id === id);

            const result = [
                ...items,
            ];
            result.splice(idx, 1, value);
            return result;
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}