import React, { useState, useMemo } from 'react';
import { Box, Typography, Link as MuiLink, Button } from '@mui/material';
import Title from 'components/Title';
import { useSearchParams } from 'react-router-dom';
import colors from 'constants/colors';
import useSearchActionobj from 'api/action/useSearchActionobj_v2';
import ActionobjectModal from './ActionobjectModal';
import ExportActionHistoryModal from './ExportActionHistoryModal';
import Filter from './Filter';
import Nodata from 'components/Nodata';
import { actioncode_color_pipe, actioncode_pipe, bucket_name_pipe, datetime_pipe, item_name_pipe, number_format_pipe } from 'pipes';
import BucketModal from 'views/bucket/BucketModal';
import useDetectLast from 'hooks/useDetectLast';
import ItemDetailDialog from 'components/item/detail/ItemDetailDialog';
import PlaceDetailDialog from 'components/place/detail/PlaceDetailDialog';
import TraderDetailDialog from 'components/trader/TraderDetailDialog';
import { useSpace } from 'contexts/space_v2.context';


const ActionHistoryPage = () => {
	const space = useSpace();
	
	const [ searchParams, setSearchParams ] = useSearchParams();
	const [ pageTrigger, setPageTrigger ] = useState();

    const params = useMemo(() => {
        const keyword = searchParams.get('keyword') || '';
		const placeId = searchParams.get('placeId') || '';
		const itemId = searchParams.get('itemId') || '';
		const categoryId = searchParams.get('categoryId') || '';
		const traderId = searchParams.get('traderId') || '';
		const code = searchParams.get('code') || '';
		const processedFrom = searchParams.get('processedFrom') || '';
		const processedTo = searchParams.get('processedTo') || '';

        return ({
			keyword,
			placeId,
			itemId,
			categoryId,
			traderId,
			code,
			processedFrom,
			processedTo,
			isProcessed: 'Y',	// 실행된 것만
        });
    }, [ searchParams ]);

	const { loading, rows, mayMore, fetched } = useSearchActionobj(space?.id, params, pageTrigger);

	const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

	// actionobject
    const [objModalOpen, setObjModalOpen] = useState(false);
    const [selectedObjId, setSelectedObjId] = useState();
    const onActionobjectClick = (obj) => () => {
        setObjModalOpen(true);
        setSelectedObjId(obj.id);
    }
    // end: actionobject

	// export
    const [ exportModalOpen , setExportModalOpen ] = useState(false);
    const handleExport = () => {
        setExportModalOpen(true);
    }

    const reload = () => {
        setSearchParams({
            ...params,
			trigger: new Date().getTime(),		// searchParams 을 변경시 필요함
        })
    }

	// bucket
    const [bucketModalOpen, setBucketModalOpen] = useState(false);
    const [selectedBucketId, setSelectedBucketId] = useState();
    const onBucketClick = (bucket) => () => {
        setSelectedBucketId(bucket.id);
        setBucketModalOpen(true)
	}
    // end: bucket

	// item
	const [ itemSelected, setItemSelected ] = useState(null);
	const [ itemDetailOpen, setItemDetailOpen ] = useState(false);
	const openItemDetail = (item) => { 
		if (item) {
			setItemSelected(item);
			setItemDetailOpen(true);
		}
	}

	// place
	const [ placeSelected, setPlaceSelected ] = useState(null);
	const [ placeDetailOpen, setPlaceDetailOpen ] = useState(false);
	const openPlaceDetail = (place) => {
		if (place) {
			setPlaceSelected(place);
			setPlaceDetailOpen(true);
		}
	}

	// trader
	const [ traderSelected, setTraderSelected ] = useState(null);
	const [ traderDetailOpen, setTraderDetailOpen ] = useState(false);
	const openTraderDetail = (trader) => {
		if (trader) {
			setTraderSelected(trader);
			setTraderDetailOpen(true);
		}
	}

	return (
		<Box>
			<Title>액션 히스토리</Title>

			{/* Guide */}
			<Box style={{ marginTop: '8px', borderLeft: '4px solid #ff6333', padding: '0.7rem 1rem', backgroundColor: colors.primary + '22' }}>
				<Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}>수행된 액션 히스토리를 조회합니다.</Typography>
				<Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}>창고 내 제품 수량과 액션 히스토리는 서로 연관되어 있어서 데이터의 불일치가 일어나지 않도록 설계되었습니다.</Typography>
				<Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}><span style={{ color: colors.secondaryDark }}>액션을 잘못 수행</span>하셨거나 <span style={{ color: colors.secondaryDark}}>과거의 히스토리 상태</span>로 돌아가려면 돌아가고 싶은 액션의 순번을 클릭 후에 <span style={{ color: colors.secondaryDark }}>"과거로 돌아가기"</span> 기능을 이용하세요.</Typography>
			</Box>

			<Box sx={{ mt: 3 }}>
				<Filter />

				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button variant='contained' color={'info'} onClick={handleExport}>내려받기</Button>
				</Box>

				{rows && <div className="mt-1 table-responsive">
					<table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
						<thead>
							<tr>
								<th scope="col">순번</th>
								<th scope="col">구분</th>
								<th scope="col">요청자</th>
								<th scope="col">수행자</th>
								<th scope="col">입출고명세서</th>
								<th scope="col">제품</th>
								<th scope="col">속성</th>
								<th scope="col">창고</th>
								<th scope="col">창고(이동)</th>
								<th scope="col">거래처</th>
								<th scope="col">수량</th>
								<th scope="col">남은수량</th>
								<th scope="col">남은수량(이동)</th>
								<th scope="col">요청일시</th>
								<th scope="col">수행일시</th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row, index) => <tr key={row.id} ref={index === rows.length - 1 ? lastElementRef : null}>
								<th scope='row'><MuiLink sx={{ cursor: 'pointer' }} onClick={onActionobjectClick(row)}>{row.order}</MuiLink></th>
								<td sx={{ color: actioncode_color_pipe(row.code) }}>{actioncode_pipe(row.code)}</td>
								<td>{row.user.nickname}</td>
								<td>{row.executor?.nickname}</td>
								<td>{row.bucket.id ? <MuiLink sx={{ cursor: 'pointer' }} onClick={onBucketClick(row.bucket)}>{bucket_name_pipe(row.bucket)}</MuiLink> : '-'}</td>
								<td style={{ textAlign: 'start' }}><MuiLink sx={{ cursor: 'pointer' }} onClick={() => openItemDetail(row.item)}>{item_name_pipe(row.item)}</MuiLink></td>
								<td style={{ textAlign: 'start' }}>{row.item.valSummaries}</td>
								<td><MuiLink sx={{ cursor: 'pointer' }} onClick={() => openPlaceDetail(row.place)}>{row.place.name}</MuiLink></td>
								<td>{row.toPlace && <MuiLink sx={{ cursor: 'pointer' }} onClick={() => openPlaceDetail(row.toPlace)}>{row.toPlace.name}</MuiLink>}</td>
								<td>{row.trader && <MuiLink sx={{ cursor: 'pointer' }} onClick={() => openTraderDetail(row.trader)}>{row.trader.name}</MuiLink>}</td>
								<td style={{ textAlign: 'right' }}>{number_format_pipe(row.amount)}</td>
								<td style={{ textAlign: 'right' }}>{number_format_pipe(row.hAmount)}</td>
								<td style={{ textAlign: 'right' }}>{row.code !== 'adjustment' && number_format_pipe(row.tAmount)}</td>
								<td>{datetime_pipe(row.createdAt)}</td>
								<td>{datetime_pipe(row.processedAt)}</td>
							</tr>)}
						</tbody>
					</table>

					{rows.length ===0 && <Nodata>{'히스토리가 없습니다.'}</Nodata>}
				</div>}
			</Box>

			<ActionobjectModal open={objModalOpen} setOpen={setObjModalOpen} objId={selectedObjId} needReload={reload} />
			<ExportActionHistoryModal open={exportModalOpen} setOpen={setExportModalOpen} spaceId={space.id} params={params} />
			<BucketModal bucketId={selectedBucketId} open={bucketModalOpen} setOpen={setBucketModalOpen} />
			<ItemDetailDialog open={itemDetailOpen} setOpen={setItemDetailOpen} id={itemSelected?.id} />
			<PlaceDetailDialog open={placeDetailOpen} setOpen={setPlaceDetailOpen} id={placeSelected?.id} />
			<TraderDetailDialog open={traderDetailOpen} setOpen={setTraderDetailOpen} id={traderSelected?.id} />
		</Box>
	);
}

export default ActionHistoryPage;