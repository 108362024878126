import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, DialogContentText, DialogTitle, Typography, TextField, FormControlLabel, Checkbox, Box } from '@mui/material';
import API from 'api';
import { AuthCode } from 'constants/auth-codes.constants';
import colors from 'constants/colors';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';


const ExportActionHistoryModal = ({ open, setOpen, spaceId, params }) => {
    const spaceAuthinfo = useSpaceAuthinfo();


    const [ withOffset, setWithOffset ] = useState(false);
    const [ orderOffset, setOrderOffset ] = useState(1);

    const exportExcel = () => {
        if (withOffset && orderOffset) {
            params['orderOffset'] = orderOffset;
        }
        const body = {
            spaceId,
            code: "action_history",
            params,
            withExcel: true,
        }
        if (withOffset && orderOffset) {
            body['orderOffset'] = +orderOffset;
        }
        API.post(`/data/generate-data-export-url-grade3`, body)
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

    const exportCSV = () => {
        if (withOffset && orderOffset) {
            params['orderOffset'] = +orderOffset;
        }
        const body = {
            spaceId,
            code: "action_history",
            params,
        }
        API.post(`/data/generate-data-export-url-grade3`, body)
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

	return (
		<Dialog
            fullWidth
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>내려받기: 액션 히스토리</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>{'설정한 조건으로 "액션 히스토리" 를 내려받습니다.\n데스크탑 환경에서만 기능합니다.'}</DialogContentText>

                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel control={<Checkbox checked={withOffset} onChange={event => {
                        setWithOffset(!withOffset)
                    }} />} label={<span style={{ color: colors.textSecondary }}>{`시작순번 설정하기`}</span>} />
                    
                    <TextField
                        disabled={!withOffset}
                        type="number"
                        margin='normal'
                        label={'시작순번'}
                        variant="outlined"
                        size="small"
                        helperText={'설정하지 않으면 1번 순번부터 가져옵니다.'}
                        style={{ marginTop: 0, width: '320px' }}
                        value={orderOffset}
                        onChange={event => setOrderOffset(event.target.value)}
                    />
                </Box>
            </DialogContent>

            {spaceAuthinfo[AuthCode.schemaWritable] ? <DialogActions sx={{ px: 3 }}>
                <Button onClick={exportCSV} color="info" variant="contained">CSV파일로 내려받기</Button>
                <Button onClick={exportExcel} variant="contained">액셀파일로 내려받기</Button>
            </DialogActions> : <Typography variant="body2" color="red" style={{ margin: '16px auto' }}>기능을 이용할 권한이 없습니다.</Typography>}
        </Dialog>
	);
}

export default ExportActionHistoryModal;