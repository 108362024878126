import { Typography } from "@mui/material";

const BootstrapPage = () => {
    return (
        <div className="container py-4 d-flex flex-column">
            <div>
                <div>기본 글자크기</div>
                <div style={{ fontSize: '14px' }}>글자크기 14px</div>
            </div>

            <div className="table-responsive">
                <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">First</th>
                            <th scope="col">Last</th>
                            <th scope="col">Handle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td colSpan="2">Larry the Bird</td>
                            <td>@twitter</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <form className="mt-5">
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                    <input type="email" className="form-control" id="exampleInputEmail1" />
                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input type="password" className="form-control" id="exampleInputPassword1" />
                </div>
                <div className="mb-3 form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>

            <div className="mt-5">
                <div className="input-group input-group-sm mb-3">
                    <span className="input-group-text" id="basic-addon1" style={{ fontSize: 12 }}>@</span>
                    <input type="text" className="form-control" placeholder="Username" style={{ fontSize: 12 }} />
                </div>

                <div className="input-group input-group-sm mb-3">
                    <input type="text" className="form-control" placeholder="Recipient's username" />
                    <span className="input-group-text" id="basic-addon2">@example.com</span>
                </div>

                <label htmlFor="basic-url" className="form-label">Your vanity URL</label>
                <div className="input-group input-group-sm mb-3">
                    <span className="input-group-text" id="basic-addon3" style={{ flex: 1 }}>https://example.com/users/</span>
                    <input type="text" className="form-control" id="basic-url" />
                </div>

                <div className="input-group input-group-sm mb-3">
                    <span className="input-group-text">$</span>
                    <input type="text" className="form-control" />
                    <span className="input-group-text">.00</span>
                </div>

                <div className="input-group input-group-sm mb-3">
                    <input type="text" className="form-control" placeholder="Username" />
                    <span className="input-group-text">@</span>
                    <input type="text" className="form-control" placeholder="Server" />
                </div>

                <div className="input-group input-group-sm">
                    <span className="input-group-text">With textarea</span>
                    <textarea className="form-control"></textarea>
                </div>
            </div>
            
        </div>
    );
}

export default BootstrapPage;