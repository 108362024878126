import React, { useContext } from 'react';
import { Box, Typography, Paper, Link as MuiLink, FormControlLabel, Checkbox, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import { MemoryContext } from 'contexts/memory.context';
import { AppContext } from 'contexts/app.context';
import { AuthContext } from 'contexts/auth.context';
import ErrorText from 'components/ErrorText';


const PasswordSignupPage = () => {
    const { showAlert } = useContext(AppContext);
    const { syncAuth } = useContext(AuthContext);
    const { routeAfterLogin } = useContext(MemoryContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            nickname: '',
            password: '',
            passwordConfirm: '',
            privacyTerm: false,
        },
        validationSchema: yup.object({
            email: yup.string().email('이메일이 유효하지 않습니다.').required('필수입력입니다.'),
            nickname: yup.string().required('필수입력입니다.').min(2, '2자 이상 입력해주세요.').max(16, '16자 이하로 입력해주세요.'),
            password: yup.string().required('필수입력입니다.').matches(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/, '8자 이상, 숫자/영문자 최소 1자 이상 입력해주세요.'),
            passwordConfirm: yup.string().oneOf([yup.ref('password'), ''], '비밀번호 확인란이 일치하지 않습니다.')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (values.password !== values.passwordConfirm) return showAlert('비밀번호 확인란이 일치하지 않습니다.');
            if (!values.privacyTerm) return showAlert('개인정보처리방침에 동의해주세요.');

            const body = values;
            API.post('/auth/password-signup', body)
            .then(() => {
                return syncAuth();
            })
            .then(routeAfterLogin)
            .finally(() => { setSubmitting(false); });
        },
    });
    const { values, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

	return (
		<Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <form onSubmit={handleSubmit}>
                <Paper sx={{ padding: 4, width: '400px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='h6' sx={{ alignSelf: 'center', mt: 1, mb: 4 }}>회원가입</Typography>
                    
                    <Typography variant='overline' sx={{ color: colors.textSecondary }}><span style={{ fontWeight: 'bold' }}>"이메일 인증"</span> 절차가 있으니 이메일을 정확히 입력해 주세요.</Typography>

                    <div className="input-group input-group-sm">
						{/* 이메일 */}
						<span className='input-group-text' style={{ width: '96px' }}>이메일</span>
						<input autoFocus required className='form-control' type='emal' {...getFieldProps('email')} />
					</div>
					<ErrorText error={touched.email && errors.email} />

                    <div className="input-group input-group-sm">
						{/* 닉네임 */}
						<span className='input-group-text' style={{ width: '96px' }}>닉네임</span>
						<input required className='form-control' {...getFieldProps('nickname')} />
					</div>
					<ErrorText error={touched.nickname && errors.nickname} />

                    <div className="input-group input-group-sm">
						{/* 비밀번호 */}
						<span className='input-group-text' style={{ width: '96px' }}>비밀번호</span>
						<input required className='form-control' type='password' {...getFieldProps('password')} />
					</div>
					<ErrorText error={touched.password && errors.password} />

                    <div className="input-group input-group-sm">
						{/* 비밀번호 */}
						<span className='input-group-text' style={{ width: '96px' }}>비밀번호 확인</span>
						<input required className='form-control' type='password' {...getFieldProps('passwordConfirm')} />
					</div>
					<ErrorText error={touched.passwordConfirm && errors.passwordConfirm} />
                    
                    <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <FormControlLabel control={<Checkbox checked={values.privacyTerm} onChange={event => { setFieldValue('privacyTerm', event.target.checked); }} />} label="개인정보처리방침" />
                            <Button component={MuiLink} href={`${process.env.REACT_APP_HOST}/policy/privacy-terms`} target="_blank">보기</Button>
                        </Box>
                    </Box>
                    
                    <Box sx={{ mt: 5, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                        <MuiLink component={Link} to={'/auth/password-signin'} sx={{ fontSize: '.9em', color: colors.primaryDark }} underline={'none'}>로그인하기</MuiLink>
                        <Button type="submit" variant="contained" color="primary">회원가입</Button>
                    </Box>
                </Paper>
            </form>
		</Box>
	);
}


export default PasswordSignupPage;