import React, { useState, useContext } from 'react';
import { Box, Button, Card, CardContent, Grid, ListItemText, MenuItem, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import PlaceFormModal from './PlaceFormModal';
import Guide from 'components/Guide';
import ContextMenu from 'components/ContextMenu';
import Title from 'components/Title';
import ExportPlacesModal from 'views/place/ExportPlaceModal';
import { useSpace, useSpaceDispath } from 'contexts/space_v2.context';


const PlaceItem = ({ place }) => {
    const { showDialog, showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const dispatch = useSpaceDispath();

    const [anchorel, setAnchorel] = useState(null);
    const open = Boolean(anchorel);
    const handleIconClick = (event) => {
        setAnchorel(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorel(null);
    };

    const onModifyClick = () => {
        handleClose();
        setFormOpen(true);
    }

    const onRemoveClick = () => {
        handleClose();
        
        showDialog({
            message: '삭제시 복원할 수 없습니다. 정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/place/${place.id}?spaceId=${space.id}`)
                    .then(() => {
                        showSnackbar('삭제했습니다.');
                        handleClose();
                        dispatch({
                            type: 'place_removed',
                            id: place.id,
                        })
                    })
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    const [ formOpen, setFormOpen ] = useState(false);

    return (
        <>
            <Card variant='outlined'>
                <CardContent sx={{ position: 'relative' }}>
                    <Typography variant='body1' sx={{ fontSize: 14 }} color="text.primary" gutterBottom>{place.name}</Typography>
                    <EditIcon sx={{ position: 'absolute', right: '12px', top: '12px', cursor: 'pointer' }} fontSize="small" color="action" onClick={handleIconClick} />
                    <Typography variant="caption">순번: {place.order}</Typography>
                </CardContent>
            </Card>
            <ContextMenu
                anchorEl={anchorel}
                open={open}
                onClose={handleClose}
            >
                <MenuItem sx={{ width: 200 }} onClick={onModifyClick}>
                    <ListItemText>수정</ListItemText>
                </MenuItem>
                <MenuItem sx={{ width: 200 }} onClick={onRemoveClick}>
                    <ListItemText>삭제</ListItemText>
                </MenuItem>
            </ContextMenu>

            <PlaceFormModal open={formOpen} handleClose={() => { setFormOpen(false); }} place={place} />
        </>
    );
}

const Places = () => {
    const space = useSpace();
    
    const [ formOpen, setFormOpen ] = useState(false);
    const [ selected, setSelected ] = useState();
    const handleClose = () => {
        setFormOpen(false);
    }
    
    const handleAddPlaceClick = () => {
        setSelected(null);
        setFormOpen(true);
    }

    // export
    const [ exportModalOpen , setExportModalOpen ] = useState(false);
    const handleExport = () => {
        setExportModalOpen(true);
    }

    if (!space) return null;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Title>창고</Title>
            
            {space.places.length === 0 && <Guide style={{ marginTop: '24px' }}>창고를 생성하세요.</Guide>}
            <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
                {space.places.map(place => <Grid item key={place.id} xs={6} md={4} lg={3} xl={2}>
                    <PlaceItem 
                        place={place}
                    />
                </Grid>)}
            </Grid>

            <Box sx={{ mt: 2, display: 'flex' }}>
                <Button variant="contained" color="primary" onClick={handleAddPlaceClick}>창고 생성</Button>
                {space.places.length > 0 && <Button sx={{ ml: 1 }} variant="contained" color="info" onClick={handleExport}>내려받기</Button>}
            </Box>

            <PlaceFormModal open={formOpen} handleClose={handleClose} place={selected} />

            <ExportPlacesModal open={exportModalOpen} setOpen={setExportModalOpen} />
        </Box>
	);
}

export default Places;