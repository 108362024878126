import { useEffect, useState } from "react";
import API from "api";


export default function useTraderReleaseTrend(params) {
    const [ datasets, setDatasets ] = useState(null);
    const [ resultParams, setResultParams ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (!params.spaceId || !params.groupby || !params.from || !params.to) return;

        let controller = new AbortController();

        setLoading(true);
        API.get(`/statistics/item-release-traders`, { params, signal: controller.signal })
        .then(data => {
            setDatasets(data.datasets);
            setResultParams(data.params);
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); setDatasets(null); setResultParams(null); }
    }, [ params ]);

    return { loading, datasets, resultParams };
}