import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { Box, TableContainer, Table, TableHead, TableRow, TableBody, Paper } from '@mui/material';
import API from 'api';
import Loading from 'components/Loading';
import Title from 'components/Title';
import useGetCoupangSales from 'api/commerce/useGetCoupangSales';
import { subMonths } from 'date-fns';
import DataListWrapper from 'components/DataListWrapper';
import SaleRow from './SaleRow';
import { StyledTableCell } from 'components/app-tables';
import AddActionModal from './AddActionModal';
import ItemSelectionModal from 'views/item/ItemSelectionModal';
import { useParams } from 'react-router-dom';
import { AppContext } from 'contexts/app.context';
import { useSpace } from 'contexts/space_v2.context';


const CoupangSalesPage = () => {
	const { showSnackbar } = useContext(AppContext);
	const space = useSpace();

	const { commerceId } = useParams();

	// --- commerce ---
	const [ commerce, setCommerce ] = useState();
	useEffect(() => {
		if (!(commerceId && space)) return;
		
		API.get(`/commerce/commerce/${commerceId}?spaceId=${space.id}`)
		.then(setCommerce);
		return () => { setCommerce(null); }
	}, [ commerceId, space ]);
	// --- end: commerce

	// --- sales ---
	const [ yearmonth, setYearmonth ] = useState(subMonths(new Date, 1));		// 최초입력: 지난달
	const [ pageTrigger, setPageTrigger ] = useState();
	const { loading, error, rows, mayMore, fetched } = useGetCoupangSales({ spaceId: space.id, vendorId: commerce?.coupangAuth.vendorId, yearmonth }, pageTrigger);
	const orderitems = useMemo(() => {
		const result = [];
		for (let order of rows) {
			for (let item of order.items) {
				result.push({
					orderId: order.orderId,
					saleType: order.saleType,
					saleDate: order.saleDate,
					...item,
				})
			}
		}
		return result;
	}, [ rows ]);

	// pagination
    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && mayMore) {
                setPageTrigger(new Date().getTime());
            }
        });
        if (node) observer.current.observe(node);
    }, [ loading, mayMore ]);
    // --- end: sales ---

	// --- CoupangProducts ---
	const [ coupangProducts, setCoupangProducts ] = useState([]);
	const [ coupnagProductsTrigger, setCoupangProductsTrigger ] = useState();
	useEffect(() => {
		if (!(commerce && space)) return;
		
		API.get(`/commerce/coupang/coupang-products/${commerce.coupangAuth.vendorId}?spaceId=${space.id}`)
		.then(setCoupangProducts);

		return () => { setCoupangProducts([]); }
	}, [ commerce, space, coupnagProductsTrigger ]);
	// --- end: CoupangProducts ---

	// --- add action ---
	const orderItemSelected = useRef();
	const [ addActionModalOpen, setAddActionModalOpen ] = useState(false);
	const onAddActionClick = (orderitem, item) => {
        orderItemSelected.current = {
			...orderitem,
			item,
		};
		setAddActionModalOpen(true);
    }
	const handleAddAction = (placeId) => {
		if (!orderItemSelected.current) return;

		const body = {
			spaceId: space.id,
			cpOrderId: orderItemSelected.current.orderId,
			cpProductId: orderItemSelected.current.productId,
			placeId,
			amount: +orderItemSelected.current.quantity,
		}
		API.post('/commerce/coupang/add-action-from-sale', body)
		.then(() => {
			showSnackbar('액션을 추가했습니다.');
		})
	}
	// --- end: add action ---

	// --- connect product ---
	const productRef = useRef();
	
	const [ itemSearchModalOpen, setItemSearchModalOpen ] = useState(false);
	const onConnectProductClick = (orderitem) => {
		productRef.current = orderitem;
		setItemSearchModalOpen(true);
	}

	const handleConnectProduct = (item) => {
		if (!productRef.current) return;

		const body = {
			spaceId: space.id,
			itemId: item.id,
			vendorId: commerce.coupangAuth.vendorId,
			productId: productRef.current.productId,
		};
		API.post('/commerce/coupang/connect-product', body)
		.then(() => {
			setItemSearchModalOpen(false);
			productRef.current = null;
			showSnackbar('제품이 연결되었습니다.');
			setCoupangProductsTrigger(new Date().getTime());
		});
	}

	const onDisconnectProductClick = (coupangProduct) => {
		API.delete(`/commerce/coupang/disconnect-product/${coupangProduct.productId}?spaceId=${space.id}`)
		.then(() => {
			showSnackbar('연결이 해제되었습니다.');
			setCoupangProductsTrigger(new Date().getTime());
		})
	}
	// --- end: connect product ---

	if (!commerce) return (<Loading />);

	return (
		<Box>
            <Title>{`커머스(${commerce.alias}) 매출내역`}</Title>

			<DataListWrapper
				style={{ marginTop: '24px' }}
				loading={loading}
				fetched={fetched}
				length={rows?.length}
				nodata={'조회된 매출내역이 없습니다.'}
			>
				<TableContainer component={Paper}>
                    <Table size="small" style={{ minWidth: '800px' }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>주문번호</StyledTableCell>
								<StyledTableCell>구분</StyledTableCell>
                                <StyledTableCell>결제완료일</StyledTableCell>
								<StyledTableCell>쿠팡상품명</StyledTableCell>
								<StyledTableCell>수량</StyledTableCell>
								<StyledTableCell>연결된 앤트포켓 제품</StyledTableCell>
								<StyledTableCell>관리</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderitems.map((row, index) => (
								<SaleRow
									key={index}
									orderitem={row}
									elRef={(index + 1 < rows.length) ? null : lastElementRef}
									coupangProducts={coupangProducts}
									onAddActionClick={onAddActionClick}
									handleConnectProduct={onConnectProductClick}
									handleDiscconectProduct={onDisconnectProductClick}
								/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
			</DataListWrapper>

			<AddActionModal 
				open={addActionModalOpen}
				setOpen={setAddActionModalOpen}
				orderitem={orderItemSelected.current}
				places={space.places}
				handleSubmit={handleAddAction}
			/>

			<ItemSelectionModal open={itemSearchModalOpen} setOpen={setItemSearchModalOpen} handleSelectItem={handleConnectProduct} />
		</Box>
	);
}

export default CoupangSalesPage;