import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, MenuItem, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import { useSpace, useSpaceDispath } from 'contexts/space_v2.context';


const PropFormModal = ({ open, handleClose, categoryId, prop }) => {
    const { showSnackbar, showDialog } = useContext(AppContext);

    const space = useSpace();
    const dispatch = useSpaceDispath();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: prop?.id,
            categoryId,
            name: prop?.name || '',
            type: prop?.type || '',
            order: prop?.order,
        },
        validationSchema: yup.object({
            name: yup.string().required('필수입력입니다.'),
            type: yup.string().required('필수선택입니다.'),
            order: prop ? yup.number().integer('정수를 입력해주세요.').required('필수입력입니다.') : yup.number().integer('정수를 입력해주세요.').nullable(),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = {
                spaceId: space.id,
                data: values,
            };

            if (prop) {
                API.put('/prop', body)
                .then(() => {
                    showSnackbar('속성이 수정되었습니다.');
                    dispatch({
                        type: 'prop_modified',
                        categoryId,
                        propId: prop.id,
                        value: values,
                    })
                    handleClose();
                })
                .finally(() => { setSubmitting(false); });
            }
            else {
                API.post('/prop', body)
                .then((data) => {
                    showSnackbar('속성이 추가되었습니다.');
                    dispatch({
                        type: 'prop_added',
                        categoryId,
                        value: data
                    })
                    handleClose();
                })
                .finally(() => { setSubmitting(false); });
            }
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{prop ? '속성 수정' : '속성 추가'}</DialogTitle>

                <DialogContent>
                    <DialogContentText>{'예) 신발제조업의 경우, 사이즈 또는 색상이 될 수 있습니다.'}</DialogContentText>

                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        label={'속성명'}
                        fullWidth
                        variant="standard"
                        error={!!(touched.name && errors.name)}
                        helperText={(touched.name && errors.name) || ' '}
                        {...getFieldProps('name')}
                    />

                    {!prop && <TextField
                        select
                        fullWidth
                        required
                        margin="normal"
                        variant="standard"
                        label={'속성의 값 타입'}
                        error={!!(touched.type && errors.type)}
                        helperText={(touched.type && errors.type) || '2.73, 200 x 300 등과 같이 문자가 포함되어있거나 정수가 아니면 "텍스트"를 선택하세요.'}
                        {...getFieldProps('type')}
                    >
                        <MenuItem value={'string'}>{'텍스트'}</MenuItem>
                        <MenuItem value={'number'}>{'정수'}</MenuItem>
                    </TextField>}

                    {!!prop && <TextField
                        fullWidth
                        type="number"
                        margin='normal'
                        label={(touched.order && errors.order) || '순번'}
                        variant="standard"
                        size="small"
                        error={!!(touched.order && errors.order)}
                        {...getFieldProps('order')}
                    />}

                </DialogContent>

                <DialogActions>
                    {/* {!!prop && <Button type="button" color="error" onClick={handleRemove}>삭제</Button>} */}
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>확인</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}

export default PropFormModal;