import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import { useSpace } from 'contexts/space_v2.context';


const InvitationFormModal = ({ open, handleClose }) => {
    const { showSnackbar, showDialog } = useContext(AppContext);
    const space = useSpace();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            spaceId: space.id,
        },
        validationSchema: yup.object({
            email: yup.string().required('필수입력입니다.').email('이메일 형식이 아닙니다.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = {
                spaceId: space.id,
                data: {
                    spaceId: space.id,
                    email: values.email,
                },
            };
            API.post('/request/send-space-invitation', body)
            .then((data) => {
                showSnackbar('스페이스에 초대했습니다.');
                handleClose();
                resetForm();
            })
            .finally(() => { setSubmitting(false); });
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, getFieldProps, handleSubmit, touched, errors } = formik;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>스페이스 초대</DialogTitle>

                <DialogContent >
                    <DialogContentText>초대장을 받은 사용자가 확인 후 회원이 됩니다.</DialogContentText>

                    <TextField
                        autoFocus
                        required
                        type="email"
                        margin="normal"
                        label={'초대회원의 가입 이메일'}
                        fullWidth
                        variant="standard"
                        error={!!errors.email}
                        helperText={(touched.email && errors.email) || ' '}
                        {...getFieldProps('email')}
                    />
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>보내기</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}

export default InvitationFormModal;