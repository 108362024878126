import React, { useState, useMemo } from 'react';
import { Box, Typography, Link as MuiLink, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import colors from 'constants/colors';
import { FileUploader } from 'react-drag-drop-files';
import useReadExcelFile from 'hooks/useReadExcelFile';
import BatchRegister from './BatchRegister';
import { useSpace } from 'contexts/space_v2.context';


const excelFileHeaders = [
	{
		key: 'name',
		name: '제품명',
		isReq: true,
	},
	{
		key: 'code',
		name: '제품코드',
		isReq: false,
	}
];

const BatchRegisterPage = () => {
	const space = useSpace();

	const [searchParams] = useSearchParams();

	// category
	const categoryId = searchParams.get('categoryId');
	const category = useMemo(() => { 
		if (space) return space.categories.find(el => el.id == categoryId) 
	}, [ space, categoryId ]);

	const headers = useMemo(() => {
        if (!space || !categoryId) return null;
        const category = space.categories.find(el => el.id == categoryId);
        const props = category.props;

		const reqHeaders = excelFileHeaders.map(el => ({ key: el.key, name: el.name, isReq: el.isReq, division: 'base' }));
		const propNames = props.map(el => ({ key: el.id, name: el.name, isReq: false, division: 'prop', type: el.type }));
		const places = space.places.map(el => ({ key: el.id, name: el.name, isReq: false, division: 'place' }));
		return ([
			...reqHeaders,
			...propNames,
			...places,
		]);
    }, [ space, categoryId ]);
	// end: category

	// 랜더링이 늦은 이슈
	// 데이터량이 많을때 화면에 그려지는 속도가 느리다. 이유를 보니, 엑셀파일을 읽는게 느린게 아니라, 테이블 뷰를 그리는데 오래 걸림 (이를 해결하기 위해 Mui 테이블을 쓰지 않고 사용해 보는게 좋을 듯)
	// 제품이 200개일경우, 개발환경에서는 많이 느리지만 실서버에서는 느리게 늦겨지지 않는다.
	const [ file, setFile ] = useState();

	const { body, error } = useReadExcelFile(headers, file);

	const handleChange = (file) => {
        setFile(file);
    };
	// end: excel

	return (
		<Box>
			{category && <Typography variant='body1' fontWeight={'bold'}>제품 카테고리: {category.name}</Typography>}
			<Box display={'flex'} alignItems={'flex-end'}>
				<div style={{ width: '320px', height: '80px', alignItems: 'center', justifyContent: 'center', display: 'flex', border: '2px dashed #c6c8b5' }}>
					<FileUploader
						handleChange={handleChange}
						name="file"
						types={['xlsx']}
						hoverTitle={" "}
						maxSize="3"
						onSizeError={() => { alert('3MByte 이하의 파일만 가능합니다.')}}
						children={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{
							file ? file.name 
							: <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
								<Typography variant='body2'>파일 열기</Typography>
								<Typography variant='body2' color={colors.textSecondary}>엑셀파일을 여기에 드랍해 주세요.</Typography>
							</Box>
						}</div>}
					/>
				</div>
				{file && <Button sx={{ mx: 1 }} variant='outlined' size='small' onClick={() => { setFile(null); }}>파일 선택 해제</Button>}
			</Box>
			{error && <Typography variant='body2' color={colors.error}>{error}</Typography>}
			<Box display={'flex'} justifyContent={'space-between'}>
				<MuiLink download href={`${process.env.REACT_APP_HOST}/data-export/download-item-form-excel2?spaceId=${space.id}&categoryId=${categoryId}`}>엑셀 양식 다운로드</MuiLink>
			</Box>

			{body && <BatchRegister
				categoryId={categoryId}
				headers={headers}
				initialRows={body}
			/>}
		</Box>
	);
}

export default BatchRegisterPage;