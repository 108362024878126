import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Button } from '@mui/material';
import colors from 'constants/colors';
import ItemTable from './ItemTable';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import CircularButton from 'components/CircularButton';
import useSearchItem from 'api/item/useSearchItem';
import { useSpace } from 'contexts/space_v2.context';


const ItemSelectionModal = ({ open, setOpen, handleSelectItem }) => {
    const space = useSpace();

    const [ page, setPage ] = useState(0);
	const [ params, setParams ] = useState({
		spaceId: space?.id,
	});

    const [ keyword, setKeyword ] = useState('');
	
    const { loading, error, rows, mayMore, totalCount, rowsPerPage } = useSearchItem(page, space?.id, params);

    const handleSearch = (event) => {
        event.preventDefault();

        setParams({
			keyword,
			spaceId: space?.id,
        });
		setPage(0);
    }

	return (
		<Dialog fullWidth={true} maxWidth={'md'} scroll='paper' open={open} onClose={() => { setOpen(false); }}>
            <DialogTitle>제품 선택</DialogTitle>

            <DialogContent>
                <form onSubmit={handleSearch}>
                    <Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                            size='small'
                            sx={{ minWidth: '180px' }}
                            label={'키워드'}
                            variant="outlined"
                            onChange={event => { setKeyword(event.target.value) }}
                            value={keyword || ''}
                        />

                        <CircularButton type="submit" style={{ marginLeft: '8px' }} variant='contained' onClick={handleSearch}>검색</CircularButton>
                    </Box>
                </form>
                
                {!loading ? <>
                    {rows && <>
                        {rows.length > 0 ? <ItemTable
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsPerPage={rowsPerPage} 
                            page={page} 
                            setPage={setPage}
                            places={space.places}
                            appMode={'single_select'}
                            onSelect={handleSelectItem}
                        /> : <Nodata>제품이 없습니다.</Nodata>}
                    </>}
                </> : <Loading />}
                {error && <div>{error}</div>}
            </DialogContent>
            
            <DialogActions>
                <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>닫기</Button>
            </DialogActions>
        </Dialog>
	);
}


export default ItemSelectionModal;