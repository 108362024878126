import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Switch, Divider } from '@mui/material';
import API from 'api';
import colors from 'constants/colors';
import Loading from 'components/Loading';
import pageStyles from 'css/page-style.module.css';


const MyAlarmSetup = () => {
    const [ methodInfo, setMethodInfo ] = useState();

	useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        API.get('/alarm/my-alarm-setup')
        .then(data => {
            const methodExceptions = data.alarmMethodExceptions;

            let value;
            const methods = [];
            value = !methodExceptions.find(item => item.method === 'email');
            methods.push({ key: 'email', name: '이메일', value });
            setMethodInfo(methods);
        });
    }

	const handleMethodChange = (method, value) => {
        if (value) {
            API.post('/alarm/alarmmethod-exception', { method })
            .then(() => { loadData(); })
        }
        else {
            API.delete(`/alarm/alarmmethod-exception/${method}`)
            .then(() => { loadData(); })   
        }
    }

	if (!methodInfo) return (<Loading />);

	return (
		<Paper sx={{ p: 2 }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', mb: 1 }}>알림</Typography>
            <Divider />

            <Box className={pageStyles.section}>
                <Typography variant='subtitle2'>알림방법</Typography>
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1' sx={{ flex: 1 }}>{'인앱'}</Typography>
                        <Switch checked={true} disabled />
                    </Box>
                    {methodInfo.map(row => <Box key={row.key} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1' sx={{ flex: 1 }}>{row.name}</Typography>
                        <Switch checked={row.value} onChange={() => { handleMethodChange(row.key, row.value); }} />
                    </Box>)}
                </Box>
            </Box>
			
		</Paper>
	);
}

export default MyAlarmSetup;