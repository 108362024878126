import React from 'react';
import SimpleHistory from 'components/action/simple-history/SimpleHistory';
import { useTrader } from 'contexts/trader.context';



const TraderActions = () => {
    const trader = useTrader();

    if (!trader) return null;

	return (
		<SimpleHistory intend={'trader'} traderId={trader.id} />
	);
}

export default TraderActions;