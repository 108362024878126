import { Typography } from "@mui/material";
import colors from "constants/colors";

export default function ErrorText({ error }) {
    return (
        <>
            {error
                ? <Typography variant="body2" sx={{ color: colors.errorDark }}>{error}</Typography> 
                : <Typography variant="body2">&nbsp;</Typography>
            }
        </>
    );
}