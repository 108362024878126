import React, { useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import PlaceItemsTable from './PlaceItemsTable';
import SimpleHistory from 'components/action/simple-history/SimpleHistory';


const Place = ({ place }) => {
	const [ itemOrder, setItemOrder ] = useState('order_asc');
	const [ showAllItems, setShowAllItems ] = useState(false);

	const handleShowAllItems = (event) => {
		setShowAllItems(val => !val);
	}

	const filtered = useMemo(() => {
		let compareFn;
		switch (itemOrder) {
			case 'order_asc':
				compareFn = (a, b) => a.order - b.order
				break;
			case 'order_desc':
				compareFn = (a, b) => b.order - a.order
				break;
			case 'amount_desc':
				compareFn = (a, b) => a.amount - b.amount
			case 'order_asc':
				compareFn = (a, b) => b.amount - a.amount
				break;
		}


		return place.items.filter((row) => {
			if (!showAllItems) return row.rAmount !== null;
			else return true;
		}).sort(compareFn);
	}, [ place, showAllItems, itemOrder ]);

    const row = (label, value) => (
		<div className={pageStyles.row}>
			<div className={pageStyles.label}>
				<Typography variant="body2" className={pageStyles.labelText}>{label}</Typography>
			</div>
			<div className={pageStyles.content}>
				<Typography variant="body2">{value}</Typography>
			</div>
		</div>
	);

	return (
		<Box>
			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
				</Box>
				<div className={pageStyles.table}>
					{row('거래처명', place.name)}
                    {row('순번', place.order)}
				</div>
			</div>

			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'flex-end'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>제품</Typography>
					
					<ToggleButtonGroup
						sx={{ ml: 'auto', mr: 4 }}
						size='small'
						color="primary"
						value={itemOrder}
						exclusive
						onChange={(e, newAlignment) => { setItemOrder(newAlignment); }}
					>
						<ToggleButton value="order_asc">순번↑</ToggleButton>
						<ToggleButton value="order_desc">순번↓</ToggleButton>
						<ToggleButton value="amount_desc">수량↓</ToggleButton>
						<ToggleButton value="amount_asc">수량↑</ToggleButton>
					</ToggleButtonGroup>
					<FormControlLabel control={<Checkbox sx={{ ml: 2 }} checked={showAllItems} onChange={handleShowAllItems} />} label="모든 제품 보기" />
				</Box>
				
				{filtered && <PlaceItemsTable rows={filtered} />}
			</div>

            <div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>액션 히스토리</Typography>
				</Box>
                <SimpleHistory intend={'place'} placeId={place.id} />
            </div>
		</Box>
	);
}

export default Place;