import { useEffect, useRef, useState } from "react";
import API from "api";


export default function useSearchActionobj(spaceId, params, pageTrigger) {
    const page = useRef(0);

    const [ rows, setRows ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        page.current = 0;
    }, [ spaceId, params ]);

    useEffect(() => {
        if (!mayMore) return;
        if (!spaceId || !params) return;

        let controller = new AbortController();
        setLoading(true);
        const body = {
            spaceId,
            parameters: {
                ...params,
                page: page.current,
            }
        }
        API.post('/action/search', body, { signal: controller.signal })
        .then(data => {
            page.current = page.current + 1;
            setRows(prevs => prevs.concat(data.rows));
            setMayMore(data.rows.length === data.rowsPerPage);
            setFetched(true);
        })
        .finally(() => {
            setLoading(false);
        });
        
        return () => { controller.abort(); };
    }, [ spaceId, params, pageTrigger, mayMore ]);

    return { loading, rows, mayMore, fetched };
}