import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import colors from 'constants/colors';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: colors.primaryDark,
        color: theme.palette.common.white,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: colors.lime + '77',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export {
    StyledTableCell, 
    StyledTableRow,
}