import React from 'react';
import { Box, Button, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { bucket_name_pipe, bucket_state_pipe, datetime_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import TablePaginationActions from 'components/TablePaginationActions';


export default function BucketTable({ rows, count, rowsPerPage, page, setPage, onBucketSelected, handleRemove }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>작성일시</StyledTableCell>
                            <StyledTableCell>타이틀</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>선택</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>
                                <StyledTableCell>{bucket_name_pipe(row)}</StyledTableCell>
                                <StyledTableCell>{bucket_state_pipe(row.state)}</StyledTableCell>
                                <StyledTableCell>
                                    <Button variant="outlined" size="small" onClick={() => { onBucketSelected(row); }}>계속 작성하기</Button>
                                    <Button variant="outlined" size="small" sx={{ ml: 1 }} color={'error'} onClick={handleRemove(row)}>작성 취소</Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}