import React, { Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import { item_name_pipe } from 'pipes';
import SimpleHistory from 'views/action/simple-history/SimpleHistory';


const Item = ({ item }) => {
	const row = (label, value) => (
		<div className={pageStyles.row}>
			<div className={pageStyles.label}>
				<Typography variant="body2" className={pageStyles.labelText}>{label}</Typography>
			</div>
			<div className={pageStyles.content}>
				<Typography variant="body2">{value}</Typography>
			</div>
		</div>
	); 

	return (
		<Box>
			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
				</Box>
				<div className={pageStyles.table}>
					{row('제품', item_name_pipe(item))}
                    {row('코드', item.code || '-')}
                    {row('순번', item.order)}
                    {item.props.map(prop => {
                        const value = prop.type === 'string' ? prop.values.val : prop.values.numVal;
                        return (
                            <Fragment key={prop.id}>
                                {row(prop.name, value)}
                            </Fragment>
                        );
                    })}
					
				</div>
			</div>

			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>창고 내 수량</Typography>
				</Box>
				<div className={pageStyles.table}>
                    {item.places.map(place => {
                        return (
                            <Fragment key={place.id}>
                                {row(place.name, place.placeItems.amount)}
                            </Fragment>
                        );
                    })}
				</div>
			</div>

            <div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>액션 히스토리</Typography>
				</Box>
                <SimpleHistory intend={'item'} itemId={item.id} />
            </div>
		</Box>
	);
}

export default Item;