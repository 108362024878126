import React from 'react';
import SimpleHistory from 'components/action/simple-history/SimpleHistory';
import { usePlace } from 'contexts/place.context';



const PlaceActions = () => {
    const place = usePlace();

    if (!place) return null;

	return (
		<SimpleHistory intend={'place'} placeId={place.id} />
	);
}

export default PlaceActions;