import React from 'react';
import Places from './Places';
import styles from 'styles.module.css';
import { Box } from '@mui/material';


const PlacePage = () => {
	return (
		<Box className={styles.page}>
			<Places />
		</Box>
	);
}

export { PlacePage };