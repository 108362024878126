import React, { useState, useEffect, useContext, useRef, useMemo, Fragment } from 'react';
import { Box, Typography, Link as MuiLink, Divider, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import styles from 'styles.module.css';
import { number_format_pipe, plan_name_pipe } from 'pipes';
import { Link } from 'react-router-dom';
import { spaceAuthCodes } from 'constants/auth-codes.constants';



const SpaceSummary = ({ space, memberCount, itemCount, myAuths }) => {
    const [ myauthModalOpen, setMyAuthModalOpen ] = useState(false);

	return (
		<Box>
            <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <MuiLink variant='h6' color={'textPrimary'} component={Link} to={'/space/account/space'}>스페이스</MuiLink>
                </Box>
                <Divider />
            </Box>

            <div className={styles.table} style={{ marginTop: '24px' }}>
                {Row('스페이스명', space.name)}
                {Row('스페이스 코드', space.uuid)}
                {Row('플랜', plan_name_pipe(space.plan.plan))}
                {Row('멤버수', number_format_pipe(memberCount))}
                {Row('제품수', number_format_pipe(itemCount))}
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Typography variant="body2" className={styles.labelText}>{'나의권한'}</Typography>
                    </div>
                    <div className={styles.content}>
                        <MuiLink component={Typography} variant='body2' sx={{cursor: 'pointer'}} onClick={() => { setMyAuthModalOpen(true); }}>{`${myAuths.length}개`}</MuiLink>
                    </div>
                </div>
            </div>


            <Dialog onClose={() => { setMyAuthModalOpen(false); }} open={myauthModalOpen}>
                <DialogTitle>나의 권한</DialogTitle>
                
                <DialogContent>
                    {myAuths.map(auth => <Box key={auth} display={'flex'} my={1}>
                        <Typography variant='body1' width={'100px'} fontWeight={500}>{spaceAuthCodes.find(el => el.key === auth).name_kr}</Typography>
                        <Typography variant='body1' color={'textSecondary'}>{spaceAuthCodes.find(el => el.key === auth).describe_kr}</Typography>
                    </Box>)}
                </DialogContent>
            </Dialog>
		</Box>
	);
}

const Row = (label, value) => (<div className={styles.row}>
    <div className={styles.label}>
        <Typography variant="body2" className={styles.labelText}>{label}</Typography>
    </div>
    <div className={styles.content}>
        <Typography variant="body2">{value}</Typography>
    </div>
</div>)

export default SpaceSummary;