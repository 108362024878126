import React, { useState, createContext, useEffect } from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import PlaceStatus from './PlaceStatus';
import Title from 'components/Title';
import colors from 'constants/colors';
import { Link, useSearchParams } from 'react-router-dom';
import styles from 'styles.module.css';
import { useSpace } from 'contexts/space_v2.context';


const PlacePageContext = createContext({});

const PlaceStatusPage = () => {
    const [ leftPlace, setLeftPlace ] = useState();
    const [ rightPlace, setRightPlace ] = useState();

    const space = useSpace();

    const [ searchParams ] = useSearchParams();
    useEffect(() => {
        if (space && searchParams.get('initialPlaceId')) {
            setLeftPlace(space.places.find(el => el.id == searchParams.get('initialPlaceId')));
        }
    }, [ space ]);
    

	return (
        <PlacePageContext.Provider
            value={{
                leftPlace,
                rightPlace,

                setLeftPlace,
                setRightPlace,
            }}
        >
            <Box className={styles.page}>
                <Title>재고 관리</Title>

                {/* Guide */}
                <Box style={{ marginTop: '8px', borderLeft: '4px solid #ff6333', padding: '0.7rem 1rem', backgroundColor: colors.primary + '22' }}>
                    <Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}>창고 내 제품에 대해 액션("입고", "출고, "조정, "이동") 작업을 수행합니다.</Typography>
                    <Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}>수행된 모든 액션은 <MuiLink component={Link} to="/space/history/action">액션 히스토리</MuiLink>에서 확인하실 수 있습니다.</Typography>
                    <MuiLink variant='body2' component={Link} to="/space/action">액션 자세히 알아보기</MuiLink>
                </Box>

                <Box style={{ marginTop: '8px', borderLeft: '4px solid #ff6333', padding: '0.7rem 1rem', backgroundColor: colors.primary + '22' }}>
                    <Typography variant="body2" style={{ lineHeight: '1.7em', whiteSpace: 'pre-line' }}>창고 내 제품의 초기수량을 지정하지 않으면 바탕이 회색으로 음영처리되며 0개로 가정합니다. 초기수량은 "조정" 액션으로 설정하실 수 있습니다.</Typography>
                </Box>

                {/* status */}
                <Box mt={1}>
                    <PlaceStatus />
                </Box>
                
            </Box>
        </PlacePageContext.Provider>
	);
}

export { PlaceStatusPage, PlacePageContext };