import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import { useSpace, useSpaceDispath } from 'contexts/space_v2.context';


const PlaceFormModal = ({ open, handleClose, place }) => {
    const { showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const dispatch = useSpaceDispath();


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: place?.id,
            spaceId: space?.id,
            name: place?.name || '',
            order: place?.order,
        },
        validationSchema: yup.object({
            name: yup.string().required('필수입력입니다.'),
            order: place ? yup.number().integer('정수를 입력해주세요.').required('필수입력입니다.') : yup.number().integer('정수를 입력해주세요.').nullable(),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = {
                spaceId: space.id,
                data: values,
            };
            
            if (!place) {
                API.post('/place', body)
                .then((data) => {
                    showSnackbar('새로 만들었습니다.');
                    handleClose();
                    dispatch({
                        type: 'place_added',
                        value: data
                    });
                })
                .finally(() => { setSubmitting(false); });
            }
            else {
                API.put('/place', body)
                .then(() => {
                    showSnackbar('수정했습니다.');
                    handleClose();
                    dispatch({
                        type: 'place_modified',
                        id: place.id,
                        value: values
                    })
                })
                .finally(() => { setSubmitting(false); });
            }
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, getFieldProps, handleSubmit, touched, errors } = formik;

    if (!space) return null;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {place ? place.name + ' 수정' : '창고 생성'}
                </DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        label={'창고명'}
                        fullWidth
                        variant="standard"
                        error={!!errors.name}
                        helperText={(touched.name && errors.name) || ' '}
                        {...getFieldProps('name')}
                    />

                    {place && <TextField
                        margin="normal"
                        type="number"
                        label={'순번'}
                        fullWidth
                        variant="standard"
                        error={!!errors.order}
                        helperText={(touched.order && errors.order) || ' '}
                        {...getFieldProps('order')}
                    />}
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>확인</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}


export default PlaceFormModal;