import React from 'react';
import Loading from './Loading';
import Nodata from './Nodata';


const DataListWrapper = ({ style, loading, fetched, children, length, nodata }) => {
    return (
        <div style={{ ...style }}>
            {fetched && <>
                {length > 0 ? <>
                    {children}
                </> : <>
                    {typeof nodata === 'string' ? <Nodata>{nodata || '데이터가 없습니다.'}</Nodata> : nodata}
                </>}
                {loading && (<Loading />)}
            </>}
        </div>
    );
}

export default DataListWrapper;