import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import { AuthContext } from 'contexts/auth.context';


const SpaceFormModal = ({ open, handleClose, space, getMyspaces }) => {
    const { showSnackbar } = useContext(AppContext);
    const { syncAuth } = useContext(AuthContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: space?.id,
            name: space?.name || '',
            order: space?.order,
        },
        validationSchema: yup.object({
            name: yup.string().required('필수입력입니다.'),
            order: space ? yup.number().integer('정수를 입력해주세요.').required('필수입력입니다.') : yup.number().integer('정수를 입력해주세요.').nullable(),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = {
                spaceId: space?.id,
                data: values,
            };
            
            if (!space) {
                API.post('/space', body)
                .then((data) => {
                    showSnackbar('스페이스를 새로 만들었습니다.');
                    getMyspaces();
                    syncAuth();
                    handleClose();
                })
                .finally(() => { setSubmitting(false); });
            }
            else {
                API.put('/space', body)
                .then((data) => {
                    showSnackbar('수정했습니다.');
                    getMyspaces();
                    handleClose();
                })
                .finally(() => { setSubmitting(false); });
            }
            
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {space ? space.name + ' 수정' : '새로 만들기'}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>스페이스명은 <span style={{ fontWeight: 'bold' }}>회사명 또는 팀명</span> 을 입력하시면 됩니다.</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        label={(touched.name && errors.name) || '스페이스명'}
                        fullWidth
                        variant="standard"
                        error={!!(touched.name && errors.name)}
                        {...getFieldProps('name')}
                    />
                    
                    {space && <>
                        <TextField
                            type="number"
                            margin='normal'
                            fullWidth
                            label={(touched.order && errors.order) || '순번'}
                            variant="standard"
                            size="small"
                            error={!!(touched.order && errors.order)}
                            {...getFieldProps('order')}
                        />
                    </>}
                </DialogContent>

                <DialogActions>
                    {/* 스페이스 삭제시 스페이스 관련내용이 모두 삭제되는데.. 너무 위험하므로 일단 노출 안함 */}
                    {/* {space && <Button type="button" color="error" onClick={handleRemove}>삭제</Button>} */}
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>취소</Button>
                    <Button type="submit" onClick={handleSubmit}>확인</Button>
                </DialogActions>
            </form>
        </Dialog>
	);
}

export default SpaceFormModal;