import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Container, Paper, Chip, Stack, Divider } from '@mui/material';
import colors from 'constants/colors';
import useGetAlarms from 'api/alarm/useGetAlarms';
import { AuthContext } from 'contexts/auth.context';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import { datetime_pipe } from 'pipes';
import API from 'api';
import { alarm_code_pipe } from 'constants/alarm.constants';


// 알림은 사용자 관점에서 중요하다. 추후에 코드 별로 상세히 표현 및 컨트롤 할 수 있도록

const MyAlarms = () => {
	const { me } = useContext(AuthContext);
	
	const [ page, setPage ] = useState(0);
	const { loading, rows, mayMore, fetched, totalCount, rowsInPage } = useGetAlarms({ page, setPage, me });

	// pagination
	const observer = useRef();
	const lastElementRef = useCallback(node => {
		if (loading) return;
		if (observer.current) observer.current.disconnect();
		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && mayMore) {
				setPage(val => val + 1);
			}
		});
		if (node) observer.current.observe(node);
	}, [ loading, mayMore ]);
	// end: pagination

	// 나갈때 모두 읽음처리
	useEffect(() => {
		return () => {
			API.put('/alarm/read-all-alarms');
		}
	}, []);
	
	if (!fetched) return null;

	return (
		<Box sx={{ py: 4, backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Container maxWidth="md">
                <Typography variant='h6' sx={{ mb: 1 }}>알림내역</Typography>

				{totalCount > 0 ? <Paper sx={{ p: 2 }}>
					<Stack
						direction={'column'}
						spacing={1}
						divider={<Divider flexItem />}
					>
						{rows.map((row, index) => <Box key={row.id} ref={(index + 1 < rows.length) ? null : lastElementRef}>
							<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
								<Typography variant='body1' color={'primary.dark'}>{alarm_code_pipe(row.code)}</Typography>
								{!row.read && <Chip label="new" color="secondary" size='small' sx={{ ml: 1 }} />}
								<Typography variant='caption' color={'text.secondary'} sx={{ ml: 'auto' }}>{datetime_pipe(row.createdAt)}</Typography>
							</Box>
							<Box sx={{ mt: 2, display: 'flex', alignItems: 'flex-end' }}>
								<Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>{row.message}</Typography>
							</Box>
						</Box>)}
					</Stack>
					
					{loading && (<Loading />)}
				</Paper> : <Nodata>알림이 없습니다.</Nodata>}
			</Container>
		</Box>
	);
}

export default MyAlarms;