import React, { createContext, useState, useEffect, useContext } from 'react';
import { AppContext } from './app.context';
import API, { setupInterceptor } from 'api';
import { AuthContext } from './auth.context';
import { useLocation, useNavigate } from 'react-router-dom';
import Guide from 'components/Guide';


const MemoryContext = createContext({});

// 모든 api 는 MemoryContextProvider 아래에서 호출되어야 함
const MemoryContextProvider = ({ children }) => {
	const { showAlert, showDialog } = useContext(AppContext);
	const { syncAuth } = useContext(AuthContext);

	const location = useLocation();
	const navigate = useNavigate();

	// login redirect
	const [ loginRedirectURL, setLoginRedirectURL ] = useState('/');
	const routeAfterLogin = () => {
		// let route = '/myspaces';
		// const exceptionRoutes = [ '/auth/signin', '/sauth/ignup', '/auth/password-signin', '/auth/password-signup' ];
		// if (exceptionRoutes.indexOf(loginRedirectURL) === -1) route = loginRedirectURL;

		navigate('/');
	}
	// end: login redirect

	// setup interceptor
    const [ canApi, setCanApi ] = useState(false);
    useEffect(() => {
		if (!canApi) {
			setupInterceptor({
				showAlert,
				showDialog,
				location,
				navigate,
				setLoginRedirectURL
			});
			setCanApi(true);
		}
    }, [ canApi ]);
    // end: setup interceptor

	const [ appCommonInfo, setAppCommonInfo ] = useState();
	useEffect(() => {
		if (canApi) {
			syncAuth();

			// get app common info
			API.get('/common/app-common-info')
			.then(setAppCommonInfo);
		}
	}, [ canApi ]);

    const logDB = ({ division, code, message, meta }) => {
		if (!canApi) {
			console.error('api is yet initiated.');
			return;
		}
		API.post('/common/app-log', {
			division, code, message, meta
		});
	}

	if (appCommonInfo?.settings.under_maintenance === 'Y') return (<Guide>현재 서버점검중입니다.</Guide>);

	return (
		<MemoryContext.Provider
			value={{
				logDB,

				setLoginRedirectURL,
				routeAfterLogin,

				appCommonInfo,
			}}
		>
			{canApi && children}
		</MemoryContext.Provider>
	);
};

export {
	MemoryContext,
	MemoryContextProvider
};