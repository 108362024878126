import React, { useState } from 'react';
import { Box, Link, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { actioncode_color_pipe, actioncode_pipe, bucket_name_pipe, datetime_pipe, number_format_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import BucketModal from 'views/bucket/BucketModal';
import TablePaginationActions from 'components/TablePaginationActions';


export default function ObjTable({ intend, rows, count, rowsPerPage, page, setPage }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // bucket
    const [bucketModalOpen, setBucketModalOpen] = useState(false);
    const [selectedBucketId, setSelectedBucketId] = useState();
    const onBucketClick = (bucket) => () => {
        setSelectedBucketId(bucket.id);
        setBucketModalOpen(true)
	}
    // end: bucket

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '1280px' }} size='small'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>순번</StyledTableCell>
                            <StyledTableCell>구분</StyledTableCell>
                            {intend !== 'item' && <StyledTableCell>제품</StyledTableCell>}
                            <StyledTableCell>요청자</StyledTableCell>
                            <StyledTableCell>수행자</StyledTableCell>
                            <StyledTableCell>입출고명세서</StyledTableCell>
                            <StyledTableCell>창고</StyledTableCell>
                            <StyledTableCell>창고(이동)</StyledTableCell>
                            <StyledTableCell>거래처</StyledTableCell>
                            <StyledTableCell align='right'>수량</StyledTableCell>
                            <StyledTableCell align='right'>남은수량</StyledTableCell>
                            <StyledTableCell>요청일시</StyledTableCell>
                            <StyledTableCell>수행일시</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th">{row.order}</StyledTableCell>
                                <StyledTableCell sx={{ color: actioncode_color_pipe(row.code) }}>{actioncode_pipe(row.code)}</StyledTableCell>
                                {intend !== 'item' && <StyledTableCell>{row.item.name}{row.item.code && ` ${row.item.code}`}</StyledTableCell>}
                                <StyledTableCell>{row.user.nickname}</StyledTableCell>
                                <StyledTableCell>{row.executor?.nickname}</StyledTableCell>
                                <StyledTableCell>{row.bucket.id ? <Link href="#" onClick={onBucketClick(row.bucket)}>{bucket_name_pipe(row.bucket)}</Link> : '-'}</StyledTableCell>
                                <StyledTableCell>{row.place.name || '-'}</StyledTableCell>
                                <StyledTableCell>{row.toPlace.name || '-'}</StyledTableCell>
                                <StyledTableCell>{row.trader.name || '-'}</StyledTableCell>
                                <StyledTableCell align='right'>{number_format_pipe(row.amount)}</StyledTableCell>
                                <StyledTableCell align='right'>{number_format_pipe(row.hAmount)}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.createdAt)}</StyledTableCell>
                                <StyledTableCell>{datetime_pipe(row.processedAt)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />

            <BucketModal bucketId={selectedBucketId} open={bucketModalOpen} setOpen={setBucketModalOpen} />
        </Box>
    );
}