import { AuthCode } from "constants/auth-codes.constants";
import { AuthContext } from "contexts/auth.context";
import { useSpace } from "contexts/space_v2.context";
import { useContext, useMemo } from "react";


export default function () {
    const { me } = useContext(AuthContext);
    const space = useSpace();

    const spaceAuthinfo = useMemo(() => {
        const info = {};
        if (me && space) {
            for (let [ key, authcode ] of Object.entries(AuthCode)) {
                info[authcode] = !!me.auths.find(auth => auth.code === authcode && auth.refId === space.id);
            }
        }
        return info;
    }, [ me, space ]);


    return spaceAuthinfo;
}