import React, { useState, useEffect } from 'react';
import { Container, useMediaQuery, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import API from 'api';
import colors from 'constants/colors';
import Ticket from './Ticket';
import Guide from 'components/Guide';
import Loading from 'components/Loading';
import Title from 'components/Title';


const TicketPage = () => {
	const isHandset = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const [tickets, setTickets] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        API.get('/ticket/my-tickets')
        .then(setTickets)
        .finally(() => {
            setLoading(false);
        });
    }

    const [ categories, setCategories ] = useState();
    useEffect(() => {
        API.get('/common/inquiry-categories')
        .then(setCategories);
    }, []);

    if (!categories) return (<Loading />);

	return (
        <Box sx={{ flex: 1, bgColor: colors.pageGray }}>
            <Container style={{ paddingTop: isHandset ? '12px' : '24px', paddingBottom: isHandset ? '24px' : '40px' }}>
                <Title>1:1 문의</Title>
                
                <Button type="button" variant="contained" component={Link} to={'/?tab=inquiry'} state={{ intend: 'form' }} sx={{ mt: 3 }}>문의하기</Button>

                <div style={{ marginTop: '8px' }}>
                    {loading ? (<Loading />) : <>
                        {tickets?.length > 0 ? <>
                            {tickets.map(item => <Ticket key={item.id} ticket={item} loadData={loadData} categories={categories} />)}
                        </> : <Guide>{'"문의하기" 버튼을 클릭하세요\n평일기준 24시간 이내로 답변드리겠습니다. ^^'}</Guide>}
                    </>}
                </div>
            </Container>
        </Box>
    );
}

export default TicketPage;