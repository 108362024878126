import React, { useState, useMemo } from 'react';
import { Box, ToggleButtonGroup, ToggleButton, Checkbox, FormControlLabel } from '@mui/material';
import PlaceItemsTable from 'views/place/PlaceItemsTable';
import { usePlace } from 'contexts/place.context';
import useGetPlace from 'api/place/useGetPlace';
import { useSpace } from 'contexts/space_v2.context';



const PlaceItems = () => {
    const space = useSpace();

    const place = usePlace();

    const { loading, fetched, row } = useGetPlace(space?.id, place?.id);

	const [ itemOrder, setItemOrder ] = useState('order_asc');
	const [ showAllItems, setShowAllItems ] = useState(false);

	const handleShowAllItems = (event) => {
		setShowAllItems(val => !val);
	}

	const filtered = useMemo(() => {
        if (!row) return null;

		let compareFn;
		switch (itemOrder) {
			case 'order_asc':
				compareFn = (a, b) => a.order - b.order
				break;
			case 'order_desc':
				compareFn = (a, b) => b.order - a.order
				break;
			case 'amount_desc':
				compareFn = (a, b) => a.amount - b.amount
			case 'order_asc':
				compareFn = (a, b) => b.amount - a.amount
				break;
		}


		return row.items.filter((row) => {
			if (!showAllItems) return row.rAmount !== null;
			else return true;
		}).sort(compareFn);
	}, [ row, showAllItems, itemOrder ]);

    if (!filtered) return null;

	return (
        <Box>
            <Box display={'flex'} alignItems={'flex-end'} marginBottom={1}>
                <ToggleButtonGroup
                    sx={{ ml: 'auto', mr: 4 }}
                    size='small'
                    color="primary"
                    value={itemOrder}
                    exclusive
                    onChange={(e, newAlignment) => { setItemOrder(newAlignment); }}
                >
                    <ToggleButton value="order_asc">순번↑</ToggleButton>
                    <ToggleButton value="order_desc">순번↓</ToggleButton>
                    <ToggleButton value="amount_desc">수량↓</ToggleButton>
                    <ToggleButton value="amount_asc">수량↑</ToggleButton>
                </ToggleButtonGroup>
                <FormControlLabel control={<Checkbox sx={{ ml: 2 }} checked={showAllItems} onChange={handleShowAllItems} />} label="모든 제품 보기" />
            </Box>

            {filtered && <PlaceItemsTable rows={filtered} />}
        </Box>
	);
}

export default PlaceItems;