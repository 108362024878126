import React, { createContext, useContext, useState } from 'react';
import API from 'api';
import { AppContext } from './app.context';
import { useNavigate, useSearchParams } from 'react-router-dom';


const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
	const { showSnackbar } = useContext(AppContext);

	const [ me, setMe ] = useState(null);

	const [ searchParams ] = useSearchParams();
	const navigate = useNavigate();

	const syncAuth = async () => {
		try {
			// API.get('/test/get', { params: {foo: 'FOO'} })
			// .then(() => {
				
			// })
			API.get('/auth/myinfo')
			.then(data => {
				setMe(data);

				// 랜더링 페이지에서 리다이렉트해서 올 경우
				if (!data) {
					if (searchParams.get('go_if_not_signed') === 'Y') {
						navigate('/auth/signin');
					}
				}
			});
		}
		catch(error) {
			console.error(error);
			setMe(null);
		}
	}

	const logout = async () => {
		await API.post('/auth/signout');
		showSnackbar('로그아웃했습니다.');

		await syncAuth();
	}

	return (
		<AuthContext.Provider
			value={{    
				me,
				syncAuth,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export {
	AuthContext,
	AuthContextProvider
};