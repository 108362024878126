import React from 'react';
import { Button, Typography, TextField, Divider, Paper } from '@mui/material';
import colors from 'constants/colors';
import { Box, styled } from '@mui/system';


const Label = styled('div')(({theme}) => ({
    width: '60px',
    backgroundColor: '#f0f0f0',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
        width: '100px',
    },
    [theme.breakpoints.up('md')]: {
        width: '140px',
    },
    '& .MuiTypography-root': {
        color: colors.textSecondary,
    }
}));

const Content = styled('div')(({theme}) => ({
    padding: '8px 16px',
    flex: 1,
}));

const InputRow = styled('div')(({theme}) => ({
    display: 'flex',
    marginBottom: '4px',
}));

const InputContent = styled(TextField)(({theme}) => ({
    margin: '0 8px',
    flex: 1,
}));


const KeyValuePage = () => {
    return (
        <Box>
            <Box sx={{ padding: '16px 0', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>구매자정보</Typography>
                <div style={{ borderTop: '3px solid #cccccc' }}>
                    <Box sx={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
                        <Label>
                            <Typography variant="body1">이름</Typography>
                        </Label>
                        <Content>
                            <Typography variant="body1">양지훈</Typography>
                        </Content>
                    </Box>
                    <Box sx={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
                        <Label>
                            <Typography variant="body1">이메일</Typography>
                        </Label>
                        <Content>
                            <Typography variant="body1">yangji24@nate.com</Typography>
                        </Content>
                    </Box>
                    <Box sx={{ display: 'flex', borderBottom: '1px solid #cccccc' }}>
                        <Label>
                            <Typography variant="body1">신발사이즈</Typography>
                        </Label>
                        <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="body1" style={{ color: colors.textSecondary }}>[미지정]</Typography>
                            <Button color="primary" size="small" variant="outlined">설정하기</Button>
                        </Content>
                    </Box>
                </div>
            </Box>

            <Divider />
                        
            <Box sx={{ padding: '16px 0', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>받는사람정보</Typography>
                <InputRow>
                    <Label>
                        <Typography variant="body1">이름</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        required={true}
                        size="small"
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body1">주소</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        placeholder="검색버튼을 누르세요."
                        required={true}
                        size="small"
                        disabled={true}
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                    <Button variant="outlined" size="small" color="primary">검색</Button>
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body1">상세주소</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        required={true}
                        size="small"
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body1">연락처</Typography>
                    </Label>
                    <InputContent
                        variant="outlined"
                        required={true}
                        size="small"
                        // onChange={handleChange('nickname')}
                        // onBlur={handleBlur('nickname')}
                        // helperText={touched.nickname && errors.nickname || ' '}
                        // error={!!(touched.nickname && errors.nickname)}
                        // value={values.nickname}
                    />
                </InputRow>
                <InputRow>
                    <Label>
                        <Typography variant="body1">배송 요청사항</Typography>
                    </Label>
                    <InputContent
                        multiline
                        variant="outlined"
                        placeholder="요청사항을 입력하세요."
                        required={true}
                        size="small"
                        rows={4}
                        // onChange={handleChange('content')}
                        // onBlur={handleBlur('content')}
                        // helperText={touched.content && errors.content || ' '}
                        // error={!!(touched.content && errors.content)}
                        // value={values.content}
                    />
                </InputRow>
            </Box>
        </Box>
    );
}

export default KeyValuePage;