import React, { useMemo } from 'react';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { item_name_pipe } from 'pipes';
import { Button, Stack, Tooltip, Typography } from '@mui/material';



const SaleRow = ({
    coupangProducts,
    orderitem,
    elRef,
    onAddActionClick,
    handleConnectProduct,
    handleDiscconectProduct
}) => {
    const coupangProduct = useMemo(() => {
        return coupangProducts.find(el => el.productId == orderitem.productId);
    }, [ coupangProducts ]);

    let division = '-';
    switch(orderitem.saleType) {
        case 'SALE': division = '주문 건'; break;
        case 'REFUND': division = '반품 건'; break;
        default: division = '-';
    }

	return (
		<StyledTableRow ref={elRef}>
            <StyledTableCell>{orderitem.orderId}</StyledTableCell>
            <StyledTableCell>{division}</StyledTableCell>
            <StyledTableCell>{orderitem.saleDate}</StyledTableCell>
            <StyledTableCell>
                <Typography variant='body2'>{orderitem.productName}</Typography>
            </StyledTableCell>
            <StyledTableCell>
                <Typography variant='body2'>{orderitem.quantity}</Typography>
            </StyledTableCell>
            <StyledTableCell>{coupangProduct ? item_name_pipe(coupangProduct.item) : '-'}</StyledTableCell>
            <StyledTableCell>
                <Stack direction={'row'} spacing={1}>
                    <Tooltip title={coupangProduct ? '' : '먼저 앤트포켓 제품과 연결해주세요.'}>
                        <span>
                            <Button variant="outlined" size="small" onClick={() => onAddActionClick(orderitem, coupangProduct.item)} disabled={!coupangProduct}>출고 기록하기</Button>
                        </span>
                    </Tooltip>
                    <Tooltip title={coupangProduct ? '이미 연결된 상품입니다.' : ''}>
                        <span>
                            <Button variant="outlined" size="small" onClick={() => { handleConnectProduct(orderitem) }} disabled={!!coupangProduct}>앤트포켓 제품 연결</Button>
                        </span>
                    </Tooltip>
                    <Tooltip title={coupangProduct ? '' : '연결되지 않은 상품입니다.'}>
                        <span>
                            <Button variant="outlined" size="small" onClick={() => { handleDiscconectProduct(coupangProduct) }} disabled={!coupangProduct}>제품 연결 해지</Button>
                        </span>
                    </Tooltip>
                </Stack>
            </StyledTableCell>
        </StyledTableRow>
	);
}

export default SaleRow;