import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button } from '@mui/material';
import API from 'api';


const ExportItemsModal = ({ open, setOpen, spaceId, categoryId }) => {
    const exportExcel = () => {
        const paramsParams = JSON.stringify({ categoryId });
        const params = { spaceId, code: 'all_items', params: paramsParams, withExcel: true };
        API.get(`/data/generate-data-export-url`, { params })
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

    const exportCSV = () => {
        const paramsParams = JSON.stringify({ categoryId });
        const params = { spaceId, code: 'all_items', params: paramsParams };
        API.get(`/data/generate-data-export-url`, { params })
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

	return (
		<Dialog
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>내려받기: 제품</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>{'카테고리에 속하는 모든 제품을 내려받습니다.\n데스크탑 환경에서만 기능합니다.'}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
                <Button onClick={exportCSV} color="info" variant="contained">CSV파일로 내려받기</Button>
                <Button onClick={exportExcel} variant="contained">액셀파일로 내려받기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default ExportItemsModal;