import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import TraderDetail from './TraderDetail';
import { TraderProvider } from 'contexts/trader.context';
import { useSpace } from 'contexts/space_v2.context';



const TraderDetailDialog = ({ id, open, setOpen }) => {
	const handleClose = () => { setOpen(false); }

    const space = useSpace();

    const trader = useMemo(() => {
        if (space && id) {
            return space.traders.find(el => el.id == id);
        }
    }, [ space, id ]);

    if (!trader) return null;

	return (
        <TraderProvider trader={trader}>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
            >
                <DialogContent sx={{ minHeight: 560 }}>
                    <TraderDetail />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>닫기</Button>
                </DialogActions>
            </Dialog>
        </TraderProvider>
	);
}

export default TraderDetailDialog;