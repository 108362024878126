import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, FormControlLabel, Checkbox, Button } from '@mui/material';
import API from 'api';
import CircularButton from 'components/CircularButton';
import colors from 'constants/colors';
import { AppContext } from 'contexts/app.context';
import { useSpace } from 'contexts/space_v2.context';


const RevertActionobjectDialog = ({ actionobject, open, setOpen, revertDone }) => {
    const { showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const [ loading, setLoading ] = useState(false);
    const [ includeOffset, setIncludeOffset ] = useState(false);

    const revertHistory = () => {
        setLoading(true);

        const body = { spaceId: space.id, objectId: actionobject.id, excludeOffset: !includeOffset };
        API.post('/action/revert', body)
        .then(() => {
            setOpen(false);
            showSnackbar('과거로 돌아갔습니다.');
            revertDone();
        })
        .finally(() => { setLoading(false); })
    }

	return (
		<Dialog
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>액션: 과거로 돌아가기</DialogTitle>
            <DialogContent style={{ minWidth: '240px' }}>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                    {`지정한신 액션이 가장 최근의 액션이 되도록 과거로 돌아갑니다.\n창고 내 제품 수량 또한 지정하신 액션의 시점으로 돌아갑니다.\n지정하신 액션(순번: ${actionobject.order})보다 나중에 수행된 모든 액션이 취소 및 삭제됩니다.\n진행하시겠습니까?\n\n* 최대 1,000번 이전의 과거까지 돌아갈 수 있습니다.\n* 오래된 순번으로 돌아갈수록 시간이 최대 5분 가까이 걸릴 수 있습니다.`}
                </DialogContentText>
                <FormControlLabel
                    sx={{ mt: 2 }}
                    label={`지정한 액션(순번: ${actionobject.order})을 포함해서 삭제하겠습니다.`}
                    control={<Checkbox checked={includeOffset} onChange={event => setIncludeOffset(event.target.checked)} />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setOpen(false); }} style={{ color: colors.textSecondary }}>아니오</Button>
                <CircularButton onClick={revertHistory} loading={loading} color="primary" autoFocus>예</CircularButton>
            </DialogActions>
        </Dialog>
	);
}

export default RevertActionobjectDialog;