import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio, TextField, Box } from '@mui/material';
import visibilities from './visibilities';


const VisibilityModal = ({ open, setOpen, paper, handleSubmit }) => {
    const [ visibility, setVisibility ] = useState(paper.visibility);
    const [ password, setPassword ] = useState('');

    const onSubmit = () => {
        setOpen(false);
        handleSubmit(paper.uuid, visibility, password);
    }

	return (
		<Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>조회권한 설정</DialogTitle>

            <DialogContent>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <FormControl sx={{ mt: 4 }}>
                        <FormLabel>조회권한</FormLabel>
                        <RadioGroup
                            value={visibility}
                            onChange={event => {
                                setVisibility(event.target.value);
                                setPassword('');
                            }}
                        >
                            {visibilities.map(item => 
                                <FormControlLabel
                                    key={item.key} value={item.key} control={<Radio />} label={'[' + item.name + '] ' + item.description}
                                />)}
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        margin="dense"
                        label={'비밀번호'}
                        variant="outlined"
                        size="small"
                        disabled={visibility !== 'protected'}
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />
                </Box>
                
            </DialogContent>

            <DialogActions>
                <Button onClick={() => { setOpen(false); }}>닫기</Button>
                <Button variant='contained' onClick={onSubmit}>설정하기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default VisibilityModal;