import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Chart from 'chart.js/auto';


const StoreReleaseChart = ({ groupby, datasets }) => {
    const elXAsis = (el) => {
        if (groupby === 'month') {
            return el.year + '-' + el.month;
        }
        else if (groupby === 'week') {
            return el.year + '-' + el.week;
        }
        else {
            return el.year + '-' + el.month + '-' + el.day;;
        }
    };


    const storeDataset = useMemo(() => {
        if (datasets['store']) {
            const data = datasets['store'].map(el => 
                ({
                    xAsis: elXAsis(el),
                    amount: +el.amount,
                })
            );
    
            return ({
                label: '입고',
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: data,
            });
        }
    }, [ datasets ]);

    const releaseDataset = useMemo(() => {
        if (datasets['release']) {
            const data = datasets['release'].map(el => 
                ({
                    xAsis: elXAsis(el),
                    amount: +el.amount,
                })
            );
    
            return ({
                label: '출고',
                backgroundColor: 'blue',
                borderColor: 'blue',
                data: data,
            });
        }
    }, [ datasets ]);
    
    useEffect(() => {
        const ctx = document.getElementById('myChart');

        // const labels = [
        //     'January',
        //     'February',
        //     'March',
        //     'April',
        //     'May',
        //     'June',
        // ];
        const datasets = [];
        if (storeDataset) datasets.push(storeDataset);
        if (releaseDataset) datasets.push(releaseDataset);
        
        const data = {
            // labels: labels,
            datasets,
        };
        
        const config = {
            type: 'line',
            data: data,
            options: {
                parsing: {
                    xAxisKey: 'xAsis',
                    yAxisKey: 'amount',
                },
            }
        };
        // console.log(config);

        // const config = {
        //     type: 'line',
        //     data,
        //     options: {
        //         parsing: {
        //             xAxisKey: 'month',
        //             yAxisKey: 'amount'
        //         }
        //     }
        // };

        // const config = {
        //     type: 'bar',
        //     data: {
        //         datasets: [{
        //             data: [{id: '10', value: 1500}, {id: '9', value: 500}]
        //         }]
        //     },
        //     options: {
        //         parsing: {
        //             xAxisKey: 'id',
        //             yAxisKey: 'value'
        //         }
        //     }
        // };

        const chart = new Chart(ctx, config);

        return (() => {
            chart.destroy();
        })
    }, []);

	return (
		<Box>
            <canvas id="myChart"></canvas>
		</Box>
	);
}

export default StoreReleaseChart;