import { useCallback, useRef } from "react";


export default function (loading, mayMore, setPageTrigger) {
    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && mayMore) {
                setPageTrigger(new Date().getTime());
            }
        });
        if (node) observer.current.observe(node);
    }, [ loading, mayMore]);

    return lastElementRef;
}