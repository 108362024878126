import { createContext, useContext, useReducer } from "react";


const TraderContext = createContext(null);
const TraderDispatchContext = createContext(null);


export function TraderProvider({ trader, children }) {
    if (!trader) throw new Error('need trader');

    const [ traderValue, dispatch ] = useReducer(reducer, trader);

    return (
        <TraderContext.Provider value={traderValue}>
            <TraderDispatchContext.Provider value={dispatch}>
                {children}
            </TraderDispatchContext.Provider>
        </TraderContext.Provider>
    )
}

export function useTrader() {
    return useContext(TraderContext);
}

export function useTraderDispath() {
    return useContext(TraderDispatchContext);
}


function reducer(trader, action) {
    switch (action.type) {
        case 'modified': {
            const { value } = action;

            return ({
                ...value,
            })
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}