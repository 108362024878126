import ApiTestPage from './views/development/api/ApiTestPage';
import MainLayout from './layouts/MainLayout';
import TypographyPage from 'views/development/view/TypographyPage';
import ColorPage from 'views/development/view/ColorPage';
import ButtonPage from 'views/development/view/ButtonPage';
import TablePage from 'views/development/view/TablePage';
import InputPage from 'views/development/view/InputPage';
import KeyValuePage from 'views/development/view/KeyValuePage';
import GuidePage from 'views/development/view/GuidePage';
import ModalPage from 'views/development/view/ModalPage';
import App from 'App';
import Page404 from 'components/Page404';
import SigninPage from 'views/auth/signin/SigninPage';
import PasswordSignupPage from 'views/auth/signup/PasswordSignupPage';
import PasswordSigninPage from 'views/auth/signin/PasswordSigninPage';
import TransferList from 'views/development/view/TransferListPage';
import { ItemPage } from 'views/schema/item/ItemPage_v2';
import EnhancedTablePage from 'views/development/view/EnhancedTablePage';
import TraderPage from 'views/trader/TraderPage';
import ActionHistoryPage from 'views/action/history/ActionHistoryPage_v2';
import ItemSearchPage from 'views/item/ItemSearchPage_v2';
import SpacePage from 'views/space/SpacePage';
import HomePage from 'views/home/HomePage';
import SpaceAuthPage from 'views/space-auth/SpaceAuthPage';
import { PlaceStatusPage } from 'views/place/status/PlaceStatusPage';
import { PlacePage } from 'views/schema/place/PlacePage';
import FindPasswordPage from 'views/auth/find-password/FindPasswordPage';
import StoreReleaseStatisticsPage from 'views/statistics/store-release/StoreReleaseStatisticsPage_v2';
import ItemAmountStatsticsPage from 'views/statistics/item-amount/ItemAmountStatisticsPage_v2';
import ItemSummariesPage from 'views/statistics/item-summaries/ItemSummariesPage_v2';
import BucketWritePage from 'views/bucket/write/BucketWritePage';
import BucketHistoryPage from 'views/bucket/history/BucketHistoryPage_v2';
import PlaceHistoryPage from 'views/place/history/PlaceHistoryPage';
import TraderHistoryPage from 'views/trader/history/TraderHistoryPage';
import SpaceHomePage from 'views/space/SpaceHomePage';
import SchemaHomePage from 'views/schema/SchemaHomePage';
import ActionHomePage from 'views/action/ActionHomepage';
import HistoryHomePage from 'views/HistoryHomePage';
import StatisticsHomePage from 'views/statistics/StatisticsHomePage';
import CommercePage from 'views/commerce/CommencePage';
// import CoupangProductPage from 'views/commerce/coupang/product/CoupangProductPage';
import CoupangSalesPage from 'views/commerce/coupang/sales/CoupangSalesPage';
import UsagePage from 'views/billing/usage/UsagePage';
import SpaceBackupLogPage from 'views/space/backup-log/SpaceBackupLogPage';
import TraderReleaseStatistics from 'views/statistics/trader-release/TraderReleaseStatisticsPage';
import TraderReleaseAmount from 'views/statistics/trader-release/amount/TraderReleaseAmount_v2';
import TraderReleaseTrend from 'views/statistics/trader-release/trend/TraderReleaseTrend_v2';
import DashboardPage from 'views/dashboard/DashboardPage';
import BatchRegisterPage from 'views/schema/item/item/batch-register/BatchRegisterPage';
import MyPapersPage from 'views/paper/my-papers/MyPapersPage';
import BootstrapPage from 'views/development/view/BootstrapPage';

  
const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "auth",
                children: [
                    {
                        path: "signin",
                        element: <SigninPage />,
                    },
                    {
                        path: "password-signin",
                        element: <PasswordSigninPage />,
                    },
                    {
                        path: "password-signup",
                        element: <PasswordSignupPage />,
                    },
                    {
                        path: "find-password",
                        element: <FindPasswordPage />,
                    },
                ],
            },
            {
                path: "space",
                element: <MainLayout />,
                children: [
                    {
                        path: "dashboard",
                        element: <DashboardPage />,
                        breadcrumb: "대시보드",
                    },
                    {
                        path: "account",
                        breadcrumb: "사용자",
                        children: [
                            {
                                path: 'space',
                                element: <SpacePage />,
                                breadcrumb: '스페이스',
                            },
                            {
                                path: 'auth',
                                element: <SpaceAuthPage />,
                                breadcrumb: '권한',
                            },
                        ]
                    },
                    {
                        path: "schema",
                        breadcrumb: "데이터 관리",
                        children: [
                            {
                                path: "place",
                                element: <PlacePage />,
                                breadcrumb: "창고",
                            },
                            {
                                path: "item/batch-register",
                                element: <BatchRegisterPage />,
                                breadcrumb: "일괄등록"
                            },
                            {
                                path: "item",
                                element: <ItemPage />,
                                breadcrumb: "제품",
                            },
                            {
                                path: "trader",
                                element: <TraderPage />,
                                breadcrumb: "거래처",
                            },
                            {
                                index: true,
                                element: <SchemaHomePage />,
                            }
                        ]
                    },
                    {
                        path: "action",
                        breadcrumb: "액션",
                        children: [
                            {
                                path: "place",
                                element: <PlaceStatusPage />,
                                breadcrumb: "재고 관리",
                            },
                            {
                                index: true,
                                element: <ActionHomePage />,
                            }
                        ]
                    },
                    {
                        path: "bucket",
                        breadcrumb: "액션",
                        children: [
                            {
                                path: "write",
                                element: <BucketWritePage />,
                                breadcrumb: "입출고명세서 작성",
                            },
                            {
                                path: "history",
                                element: <BucketHistoryPage />,
                                breadcrumb: "입출고명세서 내역",
                            },
                        ]
                    },
                    {
                        path: "history",
                        breadcrumb: "히스토리",
                        children: [
                            {
                                path: "action",
                                element: <ActionHistoryPage />,
                                breadcrumb: "액션 히스토리",
                            },
                            {
                                path: "bucket",
                                element: <ActionHistoryPage />,
                                breadcrumb: "입출고명세서",
                            },
                            {
                                path: "item",
                                element: <ItemSearchPage />,
                                breadcrumb: "제품 현황",
                            },
                            {
                                path: "place",
                                element: <PlaceHistoryPage />,
                                breadcrumb: "창고 현황",
                            },
                            {
                                path: "trader",
                                element: <TraderHistoryPage />,
                                breadcrumb: "거래처 현황",
                            },
                            {
                                index: true,
                                element: <HistoryHomePage />,
                            }
                        ]
                    },
                    {
                        path: 'statistics',
                        breadcrumb: '통계',
                        children: [
                            {
                                path: 'store-release',
                                element: <StoreReleaseStatisticsPage />,
                                breadcrumb: '입출고',
                            },
                            {
                                path: 'item-summaries',
                                element: <ItemSummariesPage />,
                                breadcrumb: '제품별 요약',
                            },
                            {
                                path: 'item-amount',
                                element: <ItemAmountStatsticsPage />,
                                breadcrumb: '재고량',
                            },
                            {
                                path: 'trader-release',
                                element: <TraderReleaseStatistics />,
                                breadcrumb: '거래처별 판매량',
                                children: [
                                    {
                                        path: 'amount',
                                        element: <TraderReleaseAmount />,
                                        breadcrumb: '기간내 판매량',
                                    },
                                    {
                                        path: 'trend',
                                        element: <TraderReleaseTrend />,
                                        breadcrumb: '판매랑 추이',
                                    },
                                ]
                            },
                            {
                                index: true,
                                element: <StatisticsHomePage />,
                            }
                        ]
                    },
                    {
                        path: 'billing',
                        breadcrumb: '요금',
                        children: [
                            {
                                path: 'usage',
                                element: <UsagePage />,
                                breadcrumb: '사용량',
                            },
                        ]
                    },
                    {
                        path: "space-backup-log",
                        breadcrumb: "백업",
                        element: <SpaceBackupLogPage />
                    },
                    {
                        path: "paper",
                        breadcrumb: "페이퍼",
                        children: [
                            {
                                path: 'my-papers',
                                element: <MyPapersPage />,
                                breadcrumb: '내가 만든 페이퍼',
                            },
                        ]
                    },
                    {
                        path: "commerce",
                        breadcrumb: "커머스",
                        children: [
                            // {
                            //     path: 'coupang/:commerceId/products',
                            //     element: <CoupangProductPage />,
                            //     breadcrumb: '쿠팡상품',
                            // },
                            {
                                path: 'coupang/:commerceId/sales',
                                element: <CoupangSalesPage />,
                                breadcrumb: '쿠팡주문',
                            },
                            {
                                index: true,
                                element: <CommercePage />,
                            }
                        ]
                    },
                    {
                        path: "development",
                        children: [
                            {
                                path: "api",
                                children: [
                                    {
                                        path: "api",
                                        element: <ApiTestPage />,
                                    }
                                ]
                            },
                            {
                                path: 'view',
                                children: [
                                    {
                                        path: "typography",
                                        element: <TypographyPage />,
                                    },
                                    {
                                        path: "color",
                                        element: <ColorPage />,
                                    },
                                    {
                                        path: "button",
                                        element: <ButtonPage />,
                                    },
                                    {
                                        path: "table",
                                        element: <TablePage />,
                                    },
                                    {
                                        path: "enhanced-table",
                                        element: <EnhancedTablePage />,
                                    },
                                    {
                                        path: "input",
                                        element: <InputPage />,
                                    },
                                    {
                                        path: "key-value",
                                        element: <KeyValuePage />,
                                    },
                                    {
                                        path: "modal",
                                        element: <ModalPage />,
                                    },
                                    {
                                        path: "guide",
                                        element: <GuidePage />,
                                    },
                                    {
                                        path: "transferList",
                                        element: <TransferList />,
                                    },
                                    {
                                        path: "bootstrap",
                                        element: <BootstrapPage />,
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        index: true,
                        element: <SpaceHomePage />,
                    }
                ]
            },
            {
                index: true,
                element: <HomePage />,
            }
        ]
    },
    {
        path: "*",
        element: <Page404 />
    },
];

export default routes;