import React, { useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import TabPanel from 'components/TabPanel';
import Subtitle1 from 'components/document/Subtitle1';
import colors from 'constants/colors';
import { usePlace } from 'contexts/place.context';
import PlaceInfo from './PlaceInfo';
import PlaceActions from './PlaceActions';
import PlaceItems from './PlaceItems';


const TabInfo = [
    { key: 'info', name: '정보', component: <PlaceInfo />  },
    { key: 'placeItems', name: '제품', component: <PlaceItems /> },
    { key: 'actions', name: '액션', component: <PlaceActions /> },
];

const PlaceDetail = ({ tab }) => {
    const place = usePlace();

    const tabInitial = useMemo(() => {
        let idx = 0; 
        if (tab) {
            let tIdx = TabInfo.map(el => el.key).indexOf(tab);
            if (tIdx > -1) idx = tIdx;
        }
        return idx;
    }, [ tab ]);

    const [ tabIndex, setTabIndex ] = useState(tabInitial);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    if (!place) return null;

	return (
		<Box>
            <Subtitle1>창고 상세 <span style={{ fontSize: '.8em', color: colors.textSecondary }}>{place.name}</span></Subtitle1>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={+tabIndex} onChange={handleTabChange}> 
                    {TabInfo.map(item => <Tab key={item.key} label={item.name} />)}
                </Tabs>
            </Box>

            <Box sx={{ py: 2 }}>
                {TabInfo.map((item, index) => <TabPanel key={item.key} value={tabIndex} index={index}>
                    <span>{item.component}</span>
                </TabPanel>)}
            </Box>
        </Box>
	);
}

export default PlaceDetail;