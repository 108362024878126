import React from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import colors from 'constants/colors';
import globals from 'constants/globals';



const RenderingPage = () => {
	const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/auth/signin');
    }

	return (
		<Box sx={{ height: '100vh', backgroundColor: colors.lime, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img alt="logo" sx={{ }} src={`${process.env.REACT_APP_HOST}/images/antpocket_64x64.png`} />

            <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>누구나 손쉽게&nbsp;</Typography>
                    <Typography sx={{ fontFamily: 'Audiowide', fontWeight: 'bold' }}>{globals.appName}</Typography>
                </Box>
                
                <Typography sx={{ mt: 1 }} variant='body2'>스마트한 재고 관리 시스템</Typography>
            </Box>
            
            <Button sx={{ mt: 4, backgroundColor: colors.primaryDark }} variant="contained" size="large" onClick={handleLoginClick}>시작하기 →</Button>
            <Link style={{ marginTop: '16px', color: '#777777', fontSize: '.9em' }} href={`${process.env.REACT_APP_HOST}/pricing`}>요금정책 보기</Link>

            <div style={{ position: 'absolute', bottom: '24px', left: 0, right: 0, display: 'flex', justifyContent: 'center' }}>
                <Link target='_blank' style={{ position: 'absolute', bottom: 0, color: '#777777', fontSize: '.9em' }} href={`${process.env.REACT_APP_HOST}/policy/privacy-terms`}>개인정보처리방침</Link>
            </div>
		</Box>
	);
}

export default RenderingPage;