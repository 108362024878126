import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button } from '@mui/material';
import API from 'api';
import { useSpace } from 'contexts/space_v2.context';


const ExportPlacesModal = ({ open, setOpen }) => {
    const space = useSpace();

    const exportExcel = () => {
        const params = { spaceId: space.id, code: 'all_places', withExcel: true };
        API.get(`/data/generate-data-export-url`, { params })
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

    const exportCSV = () => {
        const params = { spaceId: space.id, code: 'all_places' };
        API.get(`/data/generate-data-export-url`, { params })
        .then((url) => {
            setOpen(false);
            window.open(url, '_blank');
        });
    }

    if (!space) return null;

	return (
		<Dialog
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>내려받기: 창고</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>{'모든 창고를 내려받습니다.\n데스크탑 환경에서만 기능합니다.'}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
                <Button onClick={exportCSV} color="info" variant="contained">CSV파일로 내려받기</Button>
                <Button onClick={exportExcel} variant="contained">액셀파일로 내려받기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default ExportPlacesModal;