import React, { useState, useContext, useMemo } from 'react';
import { Box, Typography, Paper, Stack, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Divider, ListItem, ListItemButton, ListItemText, Checkbox, List, DialogContentText, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import { actioncode_color_pipe, actioncode_pipe, bucket_name_pipe, datetime_pipe, item_name_pipe, number_format_pipe } from 'pipes';
import ActionPanel from './ActionPannel';
import colors from 'constants/colors';
import { useNavigate } from 'react-router-dom';
import { formatISO, parse } from 'date-fns';



const BucketWithAction = ({ bucket, reloadBucket }) => {
	const { showSnackbar, showAlert, showDialog } = useContext(AppContext);
    const navigate = useNavigate();

	const viewInfo = useMemo(() => {
		return ([
			{
				label: '작성자',
				value: bucket.user.nickname,
			},
			{
				label: '생성일시',
				value: datetime_pipe(bucket.createdAt),
			},
			{
				label: '업데이트일시',
				value: datetime_pipe(bucket.updatedAt),
			},
		]);
	}, [ bucket ]);

    // bucket
    const [ bucketModalOpen, setBucketModalOpen ] = useState(false);
    const [ bucketName, setBucketName ] = useState('');
    const handleModifyBucketName = () => {
        if (!bucketName) return showAlert('타이틀을 입력해주세요.');
        const body = { 
            ...bucket,
            name: bucketName,
        };
        API.put('/bucket/bucket', body)
        .then(() => {
            setBucketModalOpen(false);
            showSnackbar('타이틀을 수정했습니다.');
            reloadBucket();
        });
    }
    const [ memoModalOpen, setMemoModalOpen ] = useState(false);
    const [ memo, setMemo ] = useState(bucket.memo || '');
    const handleModifybucketMemo = () => {
        const body = {
            ...bucket,
            memo
        };
        API.put('/bucket/bucket', body)
        .then(() => {
            setMemoModalOpen(false);
            showSnackbar('메모했습니다.')
            reloadBucket();
        })
    }

    const [ processTimeModalOpen, setProcessTimeModalOpen] = useState(false);
    const [ processTime, setProcessTime ] = useState(bucket.processTime);
    const handleModifyProcessTime = () => {
        const processedAt = processTime ? formatISO(parse(processTime, 'yyyy-MM-dd', new Date())) : null;

        const body = { 
            ...bucket,
            processTime: processedAt,
        };
        API.put('/bucket/bucket', body)
        .then(() => {
            setProcessTimeModalOpen(false);
            showSnackbar('수행일시를 수정했습니다.');
            reloadBucket();
        });
    }
    // end: bucket

    // checked info
    const [ checked, setChecked ] = useState([]);
    const handleObjClick = (obj) => () => {
        const idx = checked.indexOf(obj);
        setChecked(checked => {
            const result = [...checked];
            if (idx > -1) result.splice(idx, 1);
            else result.push(obj);
            return result;
        });
    }

    const handleRemoveObjs = () => {
        if (checked.length === 0) return;
        const objIds = checked.map(el => el.id).join(',');
        API.delete(`/bucket/actions?objIds=${objIds}`)
        .then(() => {
            showSnackbar('제외했습니다.');
            setChecked([]);
            reloadBucket();
        });
    }
    // end: checked info

    const handleSubmitBucket = () => {
        showDialog({
            message: `액션 ${bucket.actionObjects.length}건에 대한 입출고명세서를 제출하시겠습니까?`,
            first: {
                text: '확인',
                onClick: () => {
                    API.put(`/bucket/submit/${bucket.id}`)
                    .then(() => {
                        showSnackbar('제출했습니다.');
                        navigate('/space/bucket/history');
                    }) 
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    // 이미 제출된 버킷에 대해서 수정후 확인버튼을 클릭했을 경우
    const handleConfirmBucket = () => {
        navigate('/space/bucket/history');
    }

	return (
		<Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{bucket_name_pipe(bucket)}</Typography>
                <EditIcon sx={{ cursor: 'pointer' }} fontSize='small' onClick={() => { setBucketModalOpen(true); }} />
            </Box>
			
			<Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '480px', maxWidth: '100%' }}>
                    <Paper variant="outlined">
                        <Stack sx={{ p: 1 }}>
                            {viewInfo.map((info, idx) => <Box key={idx} sx={{ display: 'flex' }}>
                                <Box sx={{ flex: 1, textAlign: 'right', mr: 1 }}>
                                    <Typography variant="body2">{info.label}</Typography>
                                </Box>
                                <Box sx={{ flex: 2, textAlign: 'left' }}>
                                    <Typography variant="body2">{info.value}</Typography>
                                </Box>
                            </Box>)}
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flex: 1, textAlign: 'right', mr: 1 }}>
                                    <Typography variant="body2">{'수행일시'}</Typography>
                                </Box>
                                <Box sx={{ flex: 2, textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body2">{bucket.processTime ? datetime_pipe(bucket.processTime) : '자동설정'}</Typography>
                                    <EditIcon sx={{ cursor: 'pointer' }} fontSize='small' onClick={() => { setProcessTimeModalOpen(true); }} />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flex: 1, textAlign: 'right', mr: 1 }}>
                                    <Typography variant="body2">{'메모'}</Typography>
                                </Box>
                                <Box sx={{ flex: 2, textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body2" whiteSpace={'pre-line'}>{bucket.memo || '-'}</Typography>
                                    <EditIcon sx={{ cursor: 'pointer' }} fontSize='small' onClick={() => { setMemoModalOpen(true); }} />
                                </Box>
                            </Box>
                        </Stack>
                        
                        <Divider />
                        <List dense sx={{ height: '500px', overflowY: 'auto' }}>
                            {bucket.actionObjects.map((obj) => {
                                const action = obj.action;
                                let primary = item_name_pipe(action.item);
                                if (action.item.values) primary += ` (${action.item.values})`;
                                
                                let secondary = action.place.name;
                                if (action.toPlace) secondary += ` => ${action.toPlace.name}`;
                                if (action.trader) secondary += `, ${action.trader.name}`;

                                return (
                                    <ListItem
                                        key={obj.id}
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                onChange={handleObjClick(obj)}
                                                checked={checked.indexOf(obj) !== -1}
                                            />
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton onClick={handleObjClick(obj)}>
                                            <ListItemText 
                                                primary={<span><span style={{ color: actioncode_color_pipe(action.code) }}>{actioncode_pipe(action.code)}</span> {primary} x {number_format_pipe(action.amount)}개</span>}
                                                secondary={secondary} 
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Paper>
                    <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
                        {['making', 'rejected'].indexOf(bucket.state) > -1 && <Button onClick={handleSubmitBucket} variant='contained' sx={{ mr: 1 }} color="primary">제출</Button>}
                        {bucket.state === 'submitted' && <Button onClick={handleConfirmBucket} variant='contained' sx={{ mr: 1 }} color="primary">확인</Button>}
                        <Button onClick={handleRemoveObjs} variant='outlined' disabled={checked.length === 0} sx={{ color: colors.error, borderColor: colors.error }}>선택제외</Button>
                    </Box>
                </Box>

                <Box sx={{ flex: 1, ml: 3 }}>
                    <ActionPanel
                        bucket={bucket}
                        actionTriggered={reloadBucket}
                    />
                </Box>
			</Box>

			<Dialog open={bucketModalOpen} onClose={() => { setBucketModalOpen(false); }}>
                <DialogTitle>입출고명세서 타이틀 수정</DialogTitle>
                <DialogContent sx={{ minWidth: '400px' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="입출고명세서 타이틀을 입력하세요."
                        type="text"
                        fullWidth
                        variant="standard"
                        value={bucketName}
                        onChange={event => setBucketName(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setBucketModalOpen(false); }}>취소</Button>
                    <Button onClick={handleModifyBucketName}>확인</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={memoModalOpen} onClose={() => { setMemoModalOpen(false); }}>
                <DialogTitle>메모 수정</DialogTitle>
                <DialogContent sx={{ minWidth: '400px' }}>
                    <TextField
                        autoFocus
                        multiline
                        rows={4}
                        margin="dense"
                        label="메모를 입력하세요."
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={memo}
                        onChange={event => setMemo(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setMemoModalOpen(false); }}>취소</Button>
                    <Button onClick={handleModifybucketMemo}>확인</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={processTimeModalOpen} onClose={() => { setProcessTimeModalOpen(false); }}>
                <DialogTitle>입출고명세서 수행일시 수정</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <DialogContentText sx={{ mb: 3 }}>{'수행일시를 지정하지 않으면, 수행 당시 시각으로 자동설정됩니다.'}</DialogContentText>
                    <input
                        type="date"
                        className='form-control'
                        value={processTime || ''}
                        onChange={(event) => { setProcessTime(event.target.value); }}
                        placeholder='수행일시'
                    />
                    <Button sx={{ mt: 1 }} variant="outlined" disabled={!processTime} onClick={() => { setProcessTime(null); }}>수행일시 지정하지 않기</Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setProcessTimeModalOpen(false); }}>취소</Button>
                    <Button onClick={handleModifyProcessTime}>확인</Button>
                </DialogActions>
            </Dialog>
		</Box>
	);
}

export default BucketWithAction;