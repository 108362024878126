import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@mui/material';
import API from 'api';
import Loading from 'components/Loading';
import Bucket from './Bucket';
import colors from 'constants/colors';
import { useSpace } from 'contexts/space_v2.context';


const BucketModal = ({ bucketId, open, setOpen }) => {
    const space = useSpace();

    const handleClose = () => { setOpen(false); }

    const [ bucket, setBucket ] = useState();
    const [ loading, setLoading ] = useState(false);
    useEffect(() => {
        if (bucketId) {
            setLoading(true);
            API.get(`/bucket/${bucketId}?spaceId=${space.id}`)
            .then(bucket => {
                setBucket(bucket);
            })
            .finally(() => { setLoading(false); })
        }
    }, [bucketId]);

    if (!bucket) return null;

	return (
		<Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>입출고명세서 <span style={{ fontSize: '.8em', color: colors.textSecondary }}>{bucket.name || `입출고명세서_${bucket.id}`}</span></DialogTitle>
            <DialogContent>
                {!loading && bucket ? <Bucket bucket={bucket} /> : <Loading />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>닫기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default BucketModal;