import React, { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { iso_datetime_pipe, item_name_pipe } from 'pipes';
import getFromToGroupby from '../getFromToGroupby';
import useItemAmountStatistics from 'api/statistics/useItemAmountStatistics';
import Loading from 'components/Loading';
import ItemAmountChart from './ItemAmountChart';
import Filter from './Filter';
import { useSpace } from 'contexts/space_v2.context';


const ItemAmountStatsticsPage = () => {
    const [ searchParams ] = useSearchParams();

    const space = useSpace();

    const params = useMemo(() => {
        const sampleby = searchParams.get('sampleby') || 'month';
        const placeId = searchParams.get('placeId');
        const itemId = searchParams.get('itemId');
        let from = searchParams.get('from');
        let to = searchParams.get('to');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby(sampleby, from, to);

        return ({
            spaceId: space?.id,
            sampleby,
            from: iso_datetime_pipe(from_a),
            to: iso_datetime_pipe(to_a),
            placeId,
            itemId,
        });
    }, [ space, searchParams ]);

    const { loading, row } = useItemAmountStatistics(params);

    const [ item, setItem ] = useState(null);

    const resultDescribe = useMemo(() => {
        if (!row || !space) return '';

        const { first, last } = row;
        
        const sampleby_t = params.sampleby === 'month' ? '월별' : (params.sampleby === 'week' ? '주간별' : '일별');
        const fromto_t = `${(new Date(first).toLocaleDateString())} ~ ${(new Date(last).toLocaleDateString())}`;
        
        let text = `기간구분: ${sampleby_t}\n 기간: ${fromto_t}`;
        if (params.placeId) {
            const placename = space.places.find(el => el.id == params.placeId).name;
            text += `\n창고: ${placename}`;
        }
        if (params.itemId) {
            if (item) text += `\n제품: ${item_name_pipe(item)}`;
            else text += `\n제품: 선택됨`;
        }
        
        return text;
    }, [ row, space ]);

	return (
		<Box>
            <Filter
                searchDisabled={loading}
                item={item}
                setItem={setItem}
            />

            <Box>
                <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>{resultDescribe}</Typography>
            </Box>

            {!loading ? <>
                {!!row && <>
					<ItemAmountChart
						sampleby={row.sampleby}
						data={row.data}
					/>
                </>}
            </> : (<Loading />)}
		</Box>
	);
}

export default ItemAmountStatsticsPage;