import React, { useState} from 'react';
import { Box, Typography, Button, Link as MuiLink, Paper, TextField } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import CircularButton from 'components/CircularButton';


const FindPasswordPage = () => {
	const navigate = useNavigate();

    const [ state, setState ] = useState('ready');      // ready, done

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: yup.object({
            email: yup.string().email('이메일 형식에 맞지 않습니다.').required('이메일을 입력해주세요.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const body = values;
            API.post('/auth/init-my-password', body)
            .then((data) => {
                setState('done');
            })
            .finally(() => { setSubmitting(false); });
        },
    });
    const { values, handleChange, handleBlur, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

	return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ height: '100vh', backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Paper sx={{ padding: 4, width: '320px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='h6' sx={{ alignSelf: 'center', mt: 1, mb: 4 }}>비밀번호 찾기</Typography>
                    
                    {state === 'ready' ? <TextField
                        type="email"
                        label={'이메일'}
                        variant="outlined"
                        size="small"
                        error={!!(touched.email && errors.email)}
                        helperText={(touched.email && errors.email) || ' '}
                        {...getFieldProps('email')}
                        autoFocus
                    /> : <Typography variant='body2' sx={{ mb: 1, color: colors.secondaryDark, whiteSpace: 'pre-line', fontSize: '.8em' }}>{`새로운 비밀번호가 입력하신 이메일(${values.email}) 로 전송되었습니다.\n이메일을 확인해주세요.`}</Typography>}
                    
                    <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='body2' sx={{ mb: 1 }}>비밀번호 찾기 과정</Typography>
                        <Typography variant='body2' color={'text.secondary'} sx={{ fontSize: '.8em' }}>제출하신 이메일에 해당하는 계정의 비밀번호를 초기화 후에, 초기화된 비밀번호를 해당 이메일로 전송해 드립니다.</Typography>
                        <Typography variant='body2' color={'text.secondary'} sx={{ mt: 1, fontSize: '.8em' }}>초기화된 비밀번호를 이용하여 로그인 하세요. 이후 보안을 위해 비밀번호를 다시 변경해주세요.</Typography>
                    </Box>
                    
                    <Box sx={{ mt: 5, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                        <MuiLink component={Link} to={'/auth/password-signin'} sx={{ fontSize: '.9em', color: colors.primaryDark }} underline={'none'}>로그인</MuiLink>
                        {state === 'ready' && <CircularButton type="submit" variant="contained" color="primary" loading={isSubmitting}>찾기</CircularButton>}
                    </Box>
                </Paper>
            </Box>
        </form>
	);
}

export default FindPasswordPage;