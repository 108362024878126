import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import API from 'api';
import Loading from 'components/Loading';
import Item from './item';


const ItemModal = ({ itemId, open, setOpen }) => {
    const handleClose = () => { setOpen(false); }

    const [ item, setItem ] = useState();
    const [ loading, setLoading ] = useState(false);
    useEffect(() => {
        if (itemId) {
            setLoading(true);
            API.get(`/item/${itemId}`)
            .then(item => {
                setItem(item);
            })
            .finally(() => { setLoading(false); })
        }
    }, [itemId]);

	return (
		<Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                {!loading && item ? <Item item={item} /> : <Loading />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>닫기</Button>
            </DialogActions>
        </Dialog>
	);
}

export default ItemModal;