import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import { PlaceProvider } from 'contexts/place.context';
import PlaceDetail from './PlaceDetail';
import { useSpace } from 'contexts/space_v2.context';



const PlaceDetailDialog = ({ id, open, setOpen }) => {
	const handleClose = () => { setOpen(false); }

    const space = useSpace();

    const place = useMemo(() => {
        if (space && id) {
            return space.places.find(el => el.id == id);
        }
    }, [ space, id ]);

    if (!place) return null;

	return (
        <PlaceProvider place={place}>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
            >
                <DialogContent sx={{ minHeight: 560 }}>
                    <PlaceDetail />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>닫기</Button>
                </DialogActions>
            </Dialog>
        </PlaceProvider>
	);
}

export default PlaceDetailDialog;