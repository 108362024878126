import React, { useState, useMemo } from 'react';
import { Box, Button, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { item_props_pipe, number_format_pipe } from 'pipes';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import ItemModal from './itemModal';
import TablePaginationActions from 'components/TablePaginationActions';


export default function ItemTable({ rows, totalCount, rowsPerPage, page, setPage, places, appMode, onSelect }) {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const withPlaces = useMemo(() => {
        return rows.map(row => {
            const result = { 
                ...row,
            };

            result.places = places.map(place => {
                const target = row.places.find(el => el.id === place.id);

                return ({
                    ...place,
                    amount: target ? target.placeItems.amount : '-'
                });
            })
            
            return result;
        })
    }, [ rows, places ]);

    const [ itemId, setItemId ] = useState();
    const [ modalOpen, setModalOpen ] = useState(false);
    const handleItemDetail = (item) => () => {
        setItemId(item.id);
        setModalOpen(true);
    }

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table size="small" style={{ minWidth: appMode === 'single_select' ? '600px' : '800px'}}>
                    <TableHead>
                        <TableRow>
                            {appMode !== 'single_select' && <StyledTableCell>순번</StyledTableCell>}
                            <StyledTableCell>제품명</StyledTableCell>
                            <StyledTableCell>제품코드</StyledTableCell>
                            <StyledTableCell>추가속성</StyledTableCell>
                            {appMode !== 'single_select' && places.map(place => <StyledTableCell key={place.id}>{`창고: ${place.name}`}</StyledTableCell>)}
                            {appMode !== 'single_select' && <StyledTableCell>보기</StyledTableCell>}
                            {appMode === 'single_select' && <StyledTableCell>선택</StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {withPlaces.map((row) => (
                            <StyledTableRow key={row.id}>
                                {appMode !== 'single_select' && <StyledTableCell>{row.order}</StyledTableCell>}
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell>{row.code || '-'}</StyledTableCell>
                                <StyledTableCell>{row.valSummaries}</StyledTableCell>
                                {appMode !== 'single_select' && row.places.map(place => <StyledTableCell key={place.id}>{number_format_pipe(place.amount)}</StyledTableCell>)}
                                {appMode !== 'single_select' && <StyledTableCell><Button variant="outlined" size="small" onClick={handleItemDetail(row)}>보기</Button></StyledTableCell>}
                                {appMode === 'single_select' && <StyledTableCell><Button variant="outlined" size="small" onClick={() => { onSelect(row); }}>선택</Button></StyledTableCell>}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />

            <ItemModal itemId={itemId} open={modalOpen} setOpen={setModalOpen} />
        </Box>
    );
}