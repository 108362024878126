import React, { useState, useEffect } from 'react';
import API from 'api';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import ItemDetail from './ItemDetail';
import { ItemProvider } from 'contexts/item.context';



const ItemDetailDialog = ({ id, open, setOpen }) => {
	const handleClose = () => { setOpen(false); }

    const [ item, setItem ] = useState(null);
    
    useEffect(() => {
        if (!id) return;

        API.get(`/item/${id}`)
        .then(setItem);

        return () => { setItem(null); }
    }, [ id ]);

    if (!item) return null;

	return (
        <ItemProvider item={item}>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
            >
                <DialogContent sx={{ minHeight: 560 }}>
                    <ItemDetail />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>닫기</Button>
                </DialogActions>
            </Dialog>
        </ItemProvider>
	);
}

export default ItemDetailDialog;