import React, { useState, useMemo } from 'react';
import { Box, Button, Stack } from '@mui/material';
import CircularButton from 'components/CircularButton';
import Title from 'components/Title';
import { Link, useSearchParams } from 'react-router-dom';
import useSearchItem from 'api/item/useSearchItem_v2';
import Guide from 'components/Guide';
import colors from 'constants/colors';
import { useSpace } from 'contexts/space_v2.context';
import useDetectLast from 'hooks/useDetectLast';
import { number_format_pipe } from 'pipes';
import Nodata from 'components/Nodata';
import ItemDetailDialog from 'components/item/detail/ItemDetailDialog';


const ItemSearchPage = () => {
	const space = useSpace();

    const [searchParams, setSearchParams] = useSearchParams();

    const [ keyword, setKeyword ] = useState('');
    const [ pageTrigger, setPageTrigger ] = useState();

    const params = useMemo(() => {
        if (!space) return null;

        const keyword = searchParams.get('keyword') || '';
        setKeyword(keyword);
        
        return ({
			keyword,
			spaceId: space?.id,
        });
    }, [ searchParams, space ]);

    const { loading, rows, mayMore, fetched } = useSearchItem(params, pageTrigger);

    const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

	const handleSearch = (event) => {
        event.preventDefault();

        setSearchParams({
            keyword,
            trigger: new Date().getTime(),
        });
    }

    const withPlaces = useMemo(() => {
        if (!rows || !space) return null;

        return rows.map(row => {
            const result = { 
                ...row,
            };

            result.places = space.places.map(place => {
                const target = row.places.find(el => el.id === place.id);

                return ({
                    ...place,
                    amount: target ? target.placeItems.amount : '-'
                });
            })
            
            return result;
        })
    }, [ rows, space ]);

    // 제품보기
    const [ itemId, setItemId ] = useState();
    const [ modalOpen, setModalOpen ] = useState(false);
    const handleItemDetail = (item) => () => {
        setItemId(item.id);
        setModalOpen(true);
    }

    if (!space || !withPlaces) return null;

	return (
		<Box>
            <Title>제품</Title>

            <form onSubmit={handleSearch} style={{ marginTop: '24px', marginBottom: '8px' }}>
                <Stack spacing={1} direction={'row'} >
                    <div className="input-group input-group-sm" style={{ width: '240px'}}>
                        {/* 키워드 */}
                        <span className="input-group-text">키워드</span>
                        <input className="form-control" placeholder='제품명 또는 제품코드 입력' value={keyword} onChange={event => setKeyword(event.target.value)} />
                    </div>
                    <CircularButton size='small' type="submit" variant='contained' loading={loading}>검색</CircularButton>
                </Stack>
                
            </form>
            
            <Guide><span style={{ color: colors.secondaryDark }}>URL 로 내보내기</span>: 스페이스 제품현황을 한 페이지에서 확인하세요. <Link to={'/space/paper/my-papers'}>바로가기</Link></Guide>

            <div className="mt-4 table-responsive">
                <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">순번</th>
                            <th scope="col">제품명</th>
                            <th scope="col">제품코드</th>
                            <th scope="col">추가속성</th>
                            {space.places.map(place => <th key={place.id}>{`창고: ${place.name}`}</th>)}
                            <th scope="col">보기</th>
                        </tr>
                    </thead>
                    <tbody>
                        {withPlaces.map((row, index) => <tr key={row.id} ref={index = rows.length - 1 ? lastElementRef : null}>
                            <th scope="row">{row.order}</th>
                            <td>{row.name}</td>
                            <td>{row.code}</td>
                            <td>{row.valSummaries}</td>
                            {row.places.map(place => <td key={place.id}>{number_format_pipe(place.amount)}</td>)}
                            <td>
                                <Button variant="outlined" size="small" onClick={handleItemDetail(row)}>보기</Button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>

                {withPlaces.length === 0 && <Nodata>조회된 제품이 없습니다.</Nodata>}
            </div>

            <ItemDetailDialog open={modalOpen} setOpen={setModalOpen} id={itemId} />
		</Box>
	);
}

export default ItemSearchPage;