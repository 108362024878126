import React, { useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import colors from 'constants/colors';
import Loading from 'components/Loading';
import Title from 'components/Title';
import Guide from 'components/Guide';
import useGetSpaceCommerces from 'api/commerce/useGetSpaceCommerce';
import Commerce from './Commerce';
import CommerceFormModal from './CommerceFormModal';
import { useSpace } from 'contexts/space_v2.context';


const CommercePage = () => {
	const space = useSpace();

    const [ trigger, setTrigger ] = useState();
	const { loading, fetched, rows } = useGetSpaceCommerces(space?.id, trigger);
    const reloadCommerce = () => { setTrigger(new Date().getTime()); }

	const [ modalOpen, setModalOpen ] = useState(false);
    const handleClose = () => { setModalOpen(false); }
    
	const handleAddCommerce = () => {
		setModalOpen(true);
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Title>커머스 계정</Title>

            {fetched && rows.length === 0 && <Guide style={{ marginTop: '24px' }}>커머스를 등록하세요.</Guide>}

            {loading && <Loading />}

			{fetched && <>
				<Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
					{rows.map((commerce) => <Grid item key={commerce.id} xs={6} md={4} lg={3} xl={2}>
						<Commerce commerce={commerce} reloadCommerce={reloadCommerce} />
					</Grid>)}
				</Grid>

				<Box sx={{ display: 'flex' }}>
					<Button variant="contained" color="primary" style={{ backgroundColor: colors.coupangColor }}  onClick={handleAddCommerce}>커머스 등록</Button>
				</Box>
                <Guide style={{ marginTop: '16px' }}>커머스 계정을 연동하면 해당 커머스의 해당 커머스의 매출내역을 출고 액션으로 처리할 수 있습니다.<br/>현재 쿠팡만 연동이 가능합니다.</Guide>
			</>}

            <CommerceFormModal
                open={modalOpen}
                handleClose={handleClose}
                spaceId={space.id}
                reloadCommerce={reloadCommerce}
            />
        </Box>
	);
}

export default CommercePage;