import { useEffect, useRef, useState } from "react";
import API from "api";


export default function useSearchItem(params, pageTrigger) {
    const page = useRef(0);

    const [ rows, setRows ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setFetched(false);
        setMayMore(true);
        page.current = 0;
    }, [ params ]);

    useEffect(() => {
        if (!params) return;
        if (!mayMore) return;

        let controller = new AbortController();
        setLoading(true);

        const params_a = {
            ...params,
            page: page.current,
        };
        
        API.get('/item/search', { params: params_a, signal: controller.signal })
        .then(data => {
            setRows(prevs => prevs.concat(data.rows));
            setMayMore(data.rows.length === data.rowsPerPage);
            setFetched(true);
            page.current = page.current + 1;
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); };
    }, [ params, pageTrigger, mayMore ]);

    return { loading, rows, mayMore, fetched };
}