import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Paper, Divider, Button } from '@mui/material';
import API from 'api';
import pageStyles from 'css/page-style.module.css';
import Nodata from 'components/Nodata';
import { AppContext } from 'contexts/app.context';


const MyBillings = () => {
    const [ billings, setBillings ] = useState();

    const { showSnackbar, showDialog } = useContext(AppContext);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        API.get('/plan/my-tossbillings')
        .then(data => setBillings(data));
    }

    const handleMakePrimary = (billing) => () => {
        API.put('/plan/make-tossbilling-primary', { customerKey: billing.customerKey })
        .then(() => {
            showSnackbar('주요결제방법으로 설정했습니다.');
            loadData();
        })
    }

    const handleRemoveBilling = (billing) => () => {
        showDialog({
            message: `결제방법을 삭제하시겠습니까?`,
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/plan/remove-tossbilling/${billing.customerKey}`)
                    .then(() => {
                        showSnackbar('결제방법을 삭제했습니다.');
                        loadData();
                    })
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    const handleRegisterBilling = () => {
        window.open(`${process.env.REACT_APP_HOST}/pricing/toss-billing-register`);
    }

	if (!billings) return null;

	return (
		<Paper sx={{ p: 2 }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', mb: 1 }}>결제방법</Typography>
            <Divider />

            <Box className={pageStyles.section}>
                {/* <Typography variant='subtitle2'>알림방법</Typography> */}
                <Box sx={{ mt: 2 }}>
                    {billings.map(billing => <Box key={billing.customerKey} sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography variant='body1'>{billing.card?.number}</Typography>
                            {billing.isPrimary && <Typography variant='body2' color="secondary">주요결제방법</Typography>}
                            {billing.state === 'invalid' && <Typography variant='body2' color="red">결제시도가 실패된 카드입니다. 원인: 잔액부족 또는 카드기간만료 또는 정지된카드 또는 카드사에 의한 거절</Typography>}
                        </Box>
                        <Box>
                            {!billing.isPrimary && <Button sx={{ mr: 2 }} type="button" variant="outlined" size="small" color={'success'} onClick={handleMakePrimary(billing)}>주요결제방법으로 설정</Button>}
                            <Button type="button" variant="outlined" size="small" color="error" onClick={handleRemoveBilling(billing)}>삭제하기</Button>
                        </Box>
                    </Box>)}
                    {billings.length === 0 && <Nodata>결제방법이 없습니다.</Nodata>}

                    <Button sx={{ mt: 2 }} type="button" variant="outlined" size="small" onClick={handleRegisterBilling}>결제방법 등록</Button>
                </Box>
            </Box>
		</Paper>
	);
}

export default MyBillings;