import { useEffect, useState } from "react";
import API from "api";


export default function useGetSpaceCommerces(spaceId, trigger) {
    const [ rows, setRows ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        if (!spaceId) return;

        let controller = new AbortController();
        
        setLoading(true);
        API.get(`/commerce/space-commerces/${spaceId}`, { signal: controller.signal })
        .then(data => {
            setRows(data);
            setFetched(true);
        })
        .catch(e => {
            console.log(e);
            // if (axios.isCancel(e)) {
            //     console.warn('api canceled');
            //     return;
            // }
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); }
    }, [ spaceId, trigger ]);

    return { loading, fetched, rows };
}