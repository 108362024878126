import React, { useState, useEffect } from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import styles from 'styles.module.css';
import API from 'api';
import colors from 'constants/colors';
import Loading from 'components/Loading';
import Title from 'components/Title';
import { date_pipe, number_format_pipe, plan_name_pipe } from 'pipes';
import stylesB from 'css/three-column-table.module.css';
import { useSpace } from 'contexts/space_v2.context';


// 엑셀에 정리한 내용이 모두 포함되어야 함
// API 부터 개발 -> 
// 사용량 기준: 데이터(스페이스 멤버, 제품, 카테고리, 창고, 거래처)
// 다운로드: 스키마: 무제한, 액션 다운로드
// 자동백업
const UsagePage = () => {
	const space = useSpace();
	
	const [ data, setData ] = useState();
	
	useEffect(() => {
		if (!space) return;
		API.get(`/plan/plan-status/${space.id}`)
		.then((result) => {
			setData(result);
		})
	}, [ space ]);

	const PricingLink = <MuiLink sx={{ mt: 1, alignSelf: 'flex-start' }} target="_blank" href={`${process.env.REACT_APP_HOST}/pricing?space=${space.id}`}>요금정책 확인해보기</MuiLink>
	// const PricingLink = <div></div>;		// 미노출

	if (!data) return (<Loading />);

	return (
		<Box className={styles.page}>
            <div className={styles.section}>
                <Title>요금정책</Title>

				<div className={styles.table} style={{ marginTop: '24px' }}>
					<div className={styles.row}>
						<div className={styles.label}>
							<Typography variant="body2" className={styles.labelText}>스페이스</Typography>
						</div>
						<div className={styles.content}>
							<Typography variant="body2">{space.name}</Typography>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							<Typography variant="body2" className={styles.labelText}>사용중인 플랜</Typography>
						</div>
						<div className={styles.content}>
							<Typography variant="body2">{plan_name_pipe(data.plan.plan)}</Typography>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							<Typography variant="body2" className={styles.labelText}>플랜 시작일</Typography>
						</div>
						<div className={styles.content}>
							<Typography variant="body2">{date_pipe(data.plan.subscribedAt)}</Typography>
						</div>
					</div>
				</div>
				{PricingLink}
			</div>

			<div className={styles.section}>
				<Title>데이터저장 (Volume)</Title>
				
				<div className={stylesB.table} style={{ marginTop: '24px' }}>
					<div className={stylesB.row} style={{ backgroundColor: colors.lime }}>
						<div className={stylesB.header}><Typography variant='body2'>측정항목</Typography></div>
						<div className={stylesB.header}><Typography variant='body2'>플랜제한범위</Typography></div>
						<div className={stylesB.header}><Typography variant='body2'>현재사용량</Typography></div>
						<div className={stylesB.header}><Typography variant='body2'>남은사용량</Typography></div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>스페이스 멤버</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.memberVolume.max)}</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.memberVolume.count)}</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">{number_format_pipe(data.memberVolume.remains)}</Typography>
						</div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>제품</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.itemVolume.max)}</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.itemVolume.count)}</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">{number_format_pipe(data.itemVolume.remains)}</Typography>
						</div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>카테고리</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.categoryVolume.max)}</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.categoryVolume.count)}</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">{number_format_pipe(data.categoryVolume.remains)}</Typography>
						</div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>창고</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.placeVolume.max)}</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.placeVolume.count)}</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">{number_format_pipe(data.placeVolume.remains)}</Typography>
						</div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>거래처</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.traderVolume.max)}</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.traderVolume.count)}</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">{number_format_pipe(data.traderVolume.remains)}</Typography>
						</div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>액션</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.actionVolume.max)} / 한달</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.actionVolume.count)}</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">{number_format_pipe(data.actionVolume.remains)}</Typography>
						</div>
					</div>
				</div>
				{PricingLink}
			</div>

			<div className={styles.section}>
				<Title>다운로드 (Download)</Title>
				
				<div className={stylesB.table} style={{ marginTop: '24px' }}>
					<div className={stylesB.row} style={{ backgroundColor: colors.lime }}>
						<div className={stylesB.header}><Typography variant='body2'>측정항목</Typography></div>
						<div className={stylesB.header}><Typography variant='body2'>플랜제한범위</Typography></div>
						<div className={stylesB.header}><Typography variant='body2'>현재사용량</Typography></div>
						<div className={stylesB.header}><Typography variant='body2'>남은사용량</Typography></div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>데이터 스키마 다운로드</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>제한없음</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>-</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">-</Typography>
						</div>
					</div>
					<div className={stylesB.row}>
						<div className={stylesB.label}>
							<Typography variant="body2" className={stylesB.labelText}>액션 다운로드</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>최대 {number_format_pipe(data.actionDownload.max)}건 / 하루</Typography>
						</div>
						<div className={stylesB.sublabel}>
							<Typography variant="body2" className={stylesB.labelText}>{number_format_pipe(data.actionDownload.count)}</Typography>
						</div>
						<div className={stylesB.content}>
							<Typography variant="body2">{number_format_pipe(data.actionDownload.remains)}</Typography>
						</div>
					</div>
				</div>
				{PricingLink}
			</div>
		</Box>
	);
}

export default UsagePage;