import { useEffect, useState } from "react";
import API from "api";


export default function useStoreReleaseStatistics(params) {
    const [ row, setRow ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (!params.spaceId || !params.codes || !params.groupby || !params.from || !params.to ) return;

        let controller = new AbortController();
        setLoading(true);
        API.get(`/statistics/store-release`, { params, signal: controller.signal })
        .then(data => {
            setRow(data);
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); setRow(null) };
    }, [ params ]);

    return { loading, row };
}