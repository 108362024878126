import React from 'react';
import { Box, Typography } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import InfoRow from 'components/InfoRow';
import { useTrader } from 'contexts/trader.context';


const TraderInfo = () => {
    const trader = useTrader();

    if (!trader) return null;

	return (
        <div className={pageStyles.section}>
            <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
            </Box>
            <div className={pageStyles.table}>
                {InfoRow('거래처명', trader.name)}
                {InfoRow('순번', trader.order)}
            </div>
        </div>
	);
}

export default TraderInfo;