import React, { useState, useEffect } from 'react';
import { Box, Link as MuiLink, TableContainer, Table, TableHead, TableRow, Paper, TableBody } from '@mui/material';
import styles from 'styles.module.css';
import API from 'api';
import Loading from 'components/Loading';
import Title from 'components/Title';
import Nodata from 'components/Nodata';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { date_pipe } from 'pipes';
import Guide from 'components/Guide';
import { useSpace } from 'contexts/space_v2.context';



const SpaceBackupLogPage = () => {
	const space = useSpace();

	const [ logs, setLogs ] = useState();
	useEffect(() => {
		if (space) {
			API.get(`/space/${space.id}/backup-logs`)
			.then(setLogs)
		}
	}, [ space ]);

	return (
		<Box className={styles.page}>
            <Title>백업 로그</Title>

			<Guide style={{ marginTop: '8px' }}>{'비지니스 플랜을 시작한 후 일주일 후부터 일주일 마다 자동백업됩니다.\n* 액션은 지난 백업 이후부터 이어서 진행됩니다.'}</Guide>

			{logs ? <>
				{logs.length > 0 ? <Box sx={{ mt: 3 }}>
					<TableContainer component={Paper}>
						<Table style={{ minWidth: '1520px' }} size='small'>
							<TableHead>
								<TableRow>
									<StyledTableCell>백업일</StyledTableCell>
									<StyledTableCell>파일</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{logs.map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell component="th">{date_pipe(row.createdAt)}</StyledTableCell>
										<StyledTableCell><MuiLink download target="_blank" href={row.fileinfo?.downloadURL}>{row.fileinfo?.meta?.originalname}</MuiLink></StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box> : <Nodata>백업로그가 없습니다.</Nodata>}
			</> : <Loading />}
		</Box>
	);
}

export default SpaceBackupLogPage;