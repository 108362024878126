module.exports = {
    appName: 'Antpocket',

    ticketCategories: [
        { key: 'claim', name: '클레임' },
        { key: 'service', name: '서비스' },
        { key: 'bug', name: '버그' },
    ],

    ticketStates: [
        { key: 'opened', name: '해결진행중' },
        { key: 'closed', name: '종료됨' },
    ],

    actionCodes: [
        { code: 'store', name: '입고' },
        { code: 'release', name: '출고' },
        { code: 'move', name: '이동' },
        { code: 'adjustment', name: '조정' },
    ],
    
    bucketStates: [
        { key: 'making', name: '작성중' },
        { key: 'submitted', name: '확인대기' },
        { key: 'confirmed', name: '수행완료' },
        { key: 'rejected',name: '반려' },
    ],
}