import { CircularProgress } from '@mui/material';
import React from 'react';


const Loading = ({ color }) => {
    return (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
            <CircularProgress color={color || 'primary'} />
        </div>
        
    );
}

export default Loading;