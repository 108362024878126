import React, { useState } from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { StyledTableCell, StyledTableRow } from 'components/app-tables';
import { item_name_pipe, number_format_pipe } from 'pipes';
import TablePaginationActions from 'components/TablePaginationActions';


export default function PlaceItemsTable({ rows }) {
    const rowsPerPage = 10;

    const [ page, setPage ] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table size="small" style={{ minWidth: '600px' }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>순번</StyledTableCell>
                            <StyledTableCell>제품</StyledTableCell>
                            <StyledTableCell>추가속성</StyledTableCell>
                            <StyledTableCell>창고 내 수량</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.order}</StyledTableCell>
                                <StyledTableCell>{item_name_pipe(row)}</StyledTableCell>
                                <StyledTableCell>{row.values || '-'}</StyledTableCell>
                                <StyledTableCell>{row.rAmount === null ? '-' : number_format_pipe(row.rAmount)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 31 * emptyRows }}>
                                <StyledTableCell colSpan={5} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[ rowsPerPage ]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    );
}