import { useCallback, useMemo, useState } from 'react';
import { addMonths, endOfWeek, format, isSameDay, parse, setDate, startOfWeek, subDays, subMonths, subWeeks } from 'date-fns';
import { Box, Typography } from '@mui/material';
import colors from 'constants/colors';



const PeriodOptions = [
	{ key: 'today', name: '오늘' },
	{ key: 'yesterday', name: '어제' },
	{ key: 'thisweek', name: '이번주' },
	{ key: 'lastweek', name: '지난주' },
	{ key: 'thismonth', name: '이번달' },
	{ key: 'lastmonth', name: '지난달' },
];

const PeriodInput = ({ initialStartDate, initialEndDate, onPeriodChanged, options }) => {
    const [ sDate, setSDate ] = useState(initialStartDate);
    const [ eDate, setEDate ] = useState(initialEndDate);

	const today = useMemo(() => (new Date()), []);

    const handleTerm = useCallback(dateText => { 
		let from, to;
		switch (dateText) {
			case 'today':
				setSDate(today);
				setEDate(today);
                onPeriodChanged(today, today);
				break;
			case 'yesterday': 
				const yesterday = subDays(today, 1);
				setSDate(yesterday);
				setEDate(yesterday);
                onPeriodChanged(yesterday, yesterday);
				break;
			case 'thisweek': 
				from = startOfWeek(today);
				to = endOfWeek(today);
				setSDate(from);
				setEDate(to);
                onPeriodChanged(from, to);
				break;
			case 'lastweek': 
				const lastWeekDate = subWeeks(today, 1);
				from = startOfWeek(lastWeekDate);
				to = endOfWeek(lastWeekDate);
				setSDate(from);
				setEDate(to);
                onPeriodChanged(from, to);
				break;
			case 'thismonth': 
				from = new Date(today);
				from = setDate(from, 1);
				to = addMonths(from, 1);
				to = subDays(to, 1);
				setSDate(from);
				setEDate(to);
                onPeriodChanged(from, to);
				break;
			case 'lastmonth': 
				from = subMonths(today, 1)
				from = setDate(from, 1);
				to = addMonths(from, 1);
				to = subDays(to, 1);
				setSDate(from);
				setEDate(to);
                onPeriodChanged(from, to);
				break;
			case 'clear':
				setSDate(null);
				setEDate(null);
                onPeriodChanged(null, null);
				break;
			default:
				break;
		}
	}, [ today ]);

	const match = useCallback(dateText => {
		let from, to;
		switch (dateText) {
			case 'today':
				return isSameDay(sDate, today) && isSameDay(eDate, today);
			case 'yesterday':
				const yesterday = subDays(today, 1);
				return isSameDay(sDate, yesterday) && isSameDay(eDate, yesterday);
			case 'thisweek': 
				from = startOfWeek(today);
				to = endOfWeek(today);
				return isSameDay(sDate, from) && isSameDay(eDate, to);
			case 'lastweek': 
				const lastWeekDate = subWeeks(today, 1);
				from = startOfWeek(lastWeekDate);
				to = endOfWeek(lastWeekDate);
				return isSameDay(sDate, from) && isSameDay(eDate, to);
			case 'thismonth': 
				from = new Date(today);
				from = setDate(from, 1);
				to = addMonths(from, 1);
				to = subDays(to, 1);
				return isSameDay(sDate, from) && isSameDay(eDate, to);
			case 'lastmonth': 
				from = subMonths(today, 1)
				from = setDate(from, 1);
				to = addMonths(from, 1);
				to = subDays(to, 1);
				return isSameDay(sDate, from) && isSameDay(eDate, to);
			default:
				break;
		}
	}, [ today, sDate, eDate ]);

	const Options = (<>
		{PeriodOptions.map(option => 
			<button
				key={option.key} type='button'
				className={match(option.key) ? 'btn btn-sm btn-secondary' : 'btn btn-sm btn-outline-secondary'}
				onClick={() => handleTerm(option.key)}>{option.name}
			</button>
		)}
		<button type='button' className='btn btn-sm btn-outline-secondary' onClick={() => handleTerm('clear')}>날짜해제</button>
	</>);

    return (
		<Box>
			<Box display={'flex'}>
				<div className="input-group input-group-sm" style={{ maxWidth: '640px' }}>
					<input
						type="date"
						className='form-control'
						value={sDate ? format(sDate, 'yyyy-MM-dd') : ''}
						onChange={event => {
							const date = event.target.value ? parse(event.target.value, 'yyyy-MM-dd', new Date()) : null;
							setSDate(date);
							onPeriodChanged(date, eDate);
						}}
					/>
					<span className="input-group-text ">~</span>
					<input
						type="date"
						className='form-control'
						value={eDate ? format(eDate, 'yyyy-MM-dd'): ''}
						onChange={event => {
							const date = event.target.value ? parse(event.target.value, 'yyyy-MM-dd', new Date()) : null;
							setEDate(date);
							onPeriodChanged(sDate, date);
						}}
					/>
					
					{Options}
				</div>

				{!!options?.baseDateText && <Typography variant='caption' sx={{ ml: 1, alignSelf: 'flex-end', color: colors.textSecondary, whiteSpace: 'nowrap' }}>{options.baseDateText}</Typography>}
			</Box>
		</Box>
		
    )
}

export default PeriodInput;