import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Chart } from 'chart.js';


const ChartColors = ['rgb(38,152,228)', 'rgb(255,85,120)', 'rgb(57,185,184)', 'rgb(255,148,66)', 'rgb(144,89,249)', 'rgb(255,198,88)', 'rgb(194,196,200)'];

const TraderReleaseChart = ({ groupby, datasets }) => {
    const elXAsis = (el) => {
        if (groupby === 'month') {
            return el.year + '-' + el.month;
        }
        else if (groupby === 'week') {
            return el.year + '-' + el.week;
        }
        else {
            return el.year + '-' + el.month + '-' + el.day;;
        }
    };
	

    useEffect(() => {
        const ctx = document.getElementById('myChart');

        const chartdata = datasets.map((dataset, idx) => {
            const data = dataset.rows.map(row => {
                return ({
                    xAsis: elXAsis(row),
                    amount: row.amount,
                });
            })

            return ({
                label: dataset.trader.name,
                backgroundColor: ChartColors[idx % ChartColors.length],
                data,
            });
        })

        const config = {
            type: 'line',
            data: {
                datasets: chartdata,
            },
            options: {
                parsing: {
                    xAxisKey: 'xAsis',
                    yAxisKey: 'amount',
                },
            }
        };

        const chart = new Chart(ctx, config);

        return (() => {
            chart.destroy();
        })
    }, [ datasets ]);

	return (
		<Box>
            <canvas id="myChart"></canvas>
		</Box>
	);
}



export default TraderReleaseChart;