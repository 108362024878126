import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Button } from '@mui/material';
import colors from 'constants/colors';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import CircularButton from 'components/CircularButton';
import useSearchItem from 'api/item/useSearchItem_v2';
import useDetectLast from 'hooks/useDetectLast';
import { useSpace } from 'contexts/space_v2.context';


const ItemSelectionDialog = ({ open, setOpen, onSelect }) => {
    const space = useSpace();

    const [ pageTrigger, setPageTrigger ] = useState();
	const [ params, setParams ] = useState(null);

    const [ keyword, setKeyword ] = useState('');
	
    const { loading, rows, mayMore, fetched } = useSearchItem(params, pageTrigger);

    const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

    useEffect(() => {
        if (open)  {
            setParams({
                keyword,
                spaceId: space?.id,
            }); 
        }
        return () => { setParams(null) }
    }, [ open ]);

    const handleSearch = (event) => {
        event.preventDefault();

        setParams({
			keyword,
			spaceId: space?.id,
        });
    }

    const onSelectClick = (row) => {
        setOpen(false);
        onSelect(row);
    }

	return (
		<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={() => { setOpen(false); }}>
            <DialogTitle>제품 선택</DialogTitle>

            <DialogContent>
                <form onSubmit={handleSearch}>
                    <Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                            size='small'
                            sx={{ minWidth: '180px' }}
                            label={'키워드'}
                            variant="outlined"
                            onChange={event => { setKeyword(event.target.value) }}
                            value={keyword || ''}
                        />

                        <CircularButton type="submit" style={{ marginLeft: '8px' }} variant='contained' onClick={handleSearch}>검색</CircularButton>
                    </Box>
                </form>
                
                {rows && <>
                    <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                        <thead>
                            <tr>
                                <th scope="col">제품명</th>
                                <th scope="col">제품코드</th>
                                <th scope="col">추가속성</th>
                                <th scope="col">선택</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => <tr key={row.id} ref={index = rows.length - 1 ? lastElementRef : null}>
                                <td>{row.name}</td>
                                <td>{row.code}</td>
                                <td>{row.valSummaries}</td>
                                <td><Button variant='outlined' size='small' onClick={() => onSelectClick(row)}>선택</Button></td>
                            </tr>)}
                        </tbody>
                    </table>
                </>}

                {loading && (<Loading />)}

                {rows.length === 0 && <Nodata>조회된 제품이 없습니다.</Nodata>}

            </DialogContent>
            
            <DialogActions>
                <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>닫기</Button>
            </DialogActions>
        </Dialog>
	);
}


export default ItemSelectionDialog;