import React from 'react';
import { Box } from '@mui/material';
import docuStyles from 'css/docu-style.module.css';
import H4 from 'components/document/H4';
import H5 from 'components/document/H5';
import Body1 from 'components/document/Body1';


const StatisticsHomePage = () => {
	return (
		<Box>
            <H4>통계</H4>

            <Box className={docuStyles.section}>
                <H5>제품별 요약</H5>
                <Body1>지정하신 기간에 제품별로 입고, 출고, 조정, 이동 이 얼마나 이루어졌는지 확인합니다.</Body1>
            </Box>

            <Box className={docuStyles.section}>
                <H5>입출고 통계</H5>
                <Body1>기간별 입출고 통계정보를 한눈에 확인합니다.</Body1>
            </Box>

            <Box className={docuStyles.section}>
                <H5>재고량 통계</H5>
                <Body1>기간별 재고량 통계정보를 한눈에 확인합니다.</Body1>
            </Box>
		</Box>
	);
}

export default StatisticsHomePage;