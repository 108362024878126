import { Box, Link as MuiLink, Select } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Nodata from './Nodata';


const SelectSpace = () => {
    return (
        <>
            <Nodata>현재 선택된 스페이스가 없습니다.</Nodata>
            <Box sx={{ mt: 1 }}>
                <Link to={'/'} component={MuiLink}>스페이스 선택하기</Link>
            </Box>
        </>
    );
}

export default SelectSpace;