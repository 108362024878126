import React, { useEffect, useMemo, useState } from 'react';
import { ListItemText, Collapse, MenuList, MenuItem, Divider, Typography, Box } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ApiIcon from '@mui/icons-material/Api';
import colors from 'constants/colors';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import { AuthCode } from 'constants/auth-codes.constants';
import WarehouseOutlined from '@mui/icons-material/WarehouseOutlined';
import StackedLineChartOutlined from '@mui/icons-material/StackedLineChartOutlined';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import AssessmentOutlined from '@mui/icons-material/AssessmentOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAddOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import FacetoryIcon from '@mui/icons-material/FactoryOutlined';
import WbShadeIcon from '@mui/icons-material/WbShadeOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ArticleIcon from '@mui/icons-material/Article';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';
import { useSpace } from 'contexts/space_v2.context';


// link 가 false 이면 link 가 안됨
const dashboardMenu = [
    { 
        route: '/space/dashboard',
        link: true,
        name: '대시보드',
        icon: <DashboardIcon fontSize='small' />,
    },
];

const userMenus = [
    { 
        route: '/space/account/space',
        link: true,
        name: '스페이스',
        icon: <HexagonOutlinedIcon fontSize='small' />,
        children: [],
    },
    {
        route: '/space/account/auth',
        link: true,
        name: '회원 및 권한',
        icon: <SupervisedUserCircleOutlinedIcon fontSize='small' />,
        children: [],
    },
    { 
        route: '/?tab=inquiry',
        link: true,
        name: '문의',
        icon: <HelpOutlineIcon fontSize='small' />,
        children: [],
    },
];

const schemaMenus = [
    { 
        route: '/space/schema/item',
        link: true,
        name: '제품',
        icon: <Inventory2OutlinedIcon fontSize='small' />,
        children: [],
    },
    {
        route: '/space/schema/place',
        link: true,
        name: '창고',
        icon: <HouseOutlinedIcon fontSize='small' />,
    },
    {
        route: '/space/schema/trader',
        link: true,
        name: '거래처',
        icon: <CorporateFareIcon fontSize='small' />,
    },
];

const actionMenus = [
    {
        route: '/space/action/place',
        link: true,
        name: '재고 관리',
        auth: AuthCode.actionConfirmable,
        icon: <WarehouseOutlined fontSize='small' />,
    },
    {
        route: '/space/bucket/write',
        link: true,
        name: '입출고명세서 작성',
        icon: <NoteAddIcon fontSize='small' />,
    },
    {
        route: '/space/bucket/history',
        link: true,
        name: '입출고명세서 내역',
        auth: AuthCode.actionConfirmable,
        icon: <FileCopyIcon fontSize='small' />,
    },
];

const historyMenus = [
    { 
        route: '/space/history/action',
        link: true,
        name: '액션 히스토리',
        icon: <WorkHistoryOutlinedIcon fontSize='small' />,
        children: [],
    },
    {
        route: '/space/history/item',
        link: true,
        name: '제품 현황',
        icon: <ListAltOutlinedIcon fontSize='small' />,
        children: [],
    },
    { 
        route: '/space/history/place',
        link: true,
        name: '창고 현황',
        icon: <FacetoryIcon fontSize='small' />,
        children: [],
    },
    {
        route: '/space/history/trader',
        link: true,
        name: '거래처 현황',
        icon: <WbShadeIcon fontSize='small' />,
        children: [],
    },
    {
        route: '/space/paper/my-papers',
        link: true,
        name: '페이퍼',
        icon: <ArticleIcon fontSize='small' />,
        children: [],
    },
];

const statisticsMenus = [
    {
        route: '/space/statistics/item-summaries',
        link: true,
        name: '제품별 요약',
        icon: <AssessmentOutlined fontSize='small' />,
        children: [],
    },
    {
        route: '/space/statistics/store-release',
        link: true,
        name: '입출고 통계',
        icon: <StackedLineChartOutlined fontSize='small' />,
        children: [],
    },
    {
        route: '/space/statistics/item-amount',
        link: true,
        name: '재고량 통계',
        icon: <BarChartOutlined fontSize='small' />,
        children: [],
    },
    {
        route: '/space/statistics/trader-release',
        link: true,
        name: '거래처별 판매량',
        icon: <TrendingUpIcon fontSize='small' />,
        children: [],
    },
];

const commerceMenus = [
    { 
        route: '/space/commerce',
        link: true,
        name: '커머스',
        icon: <HexagonOutlinedIcon fontSize='small' />,
        children: [],
    },
];

const developmentMenus = [
    {
        route: '/space/development/view',
        link: false,
        name: 'View',
        icon: (<LandscapeOutlinedIcon fontSize='small' />),
        children: [
            {
                route: '/space/development/view/typography',
                name: 'Typography',
                link: true,
            },
            {
                route: '/space/development/view/color',
                link: true,
                name: 'Color',
            },
            {
                route: '/space/development/view/button',
                link: true,
                name: 'Button',
            },
            {
                route: '/space/development/view/table',
                link: true,
                name: 'Table',
            },
            {
                route: '/space/development/view/enhanced-table',
                link: true,
                name: 'EnhancedTable',
            },
            {
                route: '/space/development/view/input',
                link: true,
                name: 'Input',
            },
            {
                route: '/space/development/view/key-value',
                link: true,
                name: 'Key Value',
            },
            {
                route: '/space/development/view/modal',
                link: true,
                name: 'Modal',
            },
            {
                route: '/space/development/view/guide',
                link: true,
                name: 'Guide',
            },
            {
                route: '/space/development/view/transferList',
                link: true,
                name: 'TransferList',
            },
            {
                route: '/space/development/view/bootstrap',
                link: true,
                name: 'Bootstrap',
            },
        ]
    },
    {
        route: '/space/development/api',
        link: false,
        name: 'API',
        icon: (<ApiIcon fontSize='small' />),
        children: [
            {
                route: '/space/development/api/api',
                link: true,
                name: 'API',
            },
        ]
    },
];


function SidebarMenus({ setNavigationOpen }) {
    const space = useSpace();
    const spaceAuthinfo = useSpaceAuthinfo();
    
    if (!space || !spaceAuthinfo) return null;

	return (
        <>
            <Box display={'flex'} py={1} justifyContent={'center'}><Typography variant='body2' sx={{ color: colors.textSecondary, fontWeight: 'bold' }}>{space.name}</Typography></Box>
            <Divider />

            <MenuList dense>
                {dashboardMenu.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}

                <Typography variant='overline' sx={{ ml: 2, mb: 1 }}>{'사용자'}</Typography>
                {userMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}
                
                {spaceAuthinfo[AuthCode.schemaWritable] && <Box>
                    <Divider />
                    <Typography variant='overline' sx={{ ml: 2, mb: 1 }}>{'데이터 관리'}</Typography>
                    {schemaMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}
                </Box>}

                <Divider />
                <Typography variant='overline' sx={{ ml: 2, mb: 1 }}>{'입출고'}</Typography>
                {actionMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}

                <Divider />
                <Typography variant='overline' sx={{ ml: 2, mb: 1 }}>{'히스토리'}</Typography>
                {historyMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}

                <Divider />
                <Typography variant='overline' sx={{ ml: 2, mb: 1 }}>{'통계'}</Typography>
                {statisticsMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}

                <Divider />
                {spaceAuthinfo[AuthCode.schemaWritable] && <Box>
                    <Typography variant='overline' sx={{ ml: 2, mb: 1 }}>{'커머스'}</Typography>
                    {commerceMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}
                </Box>}

                {process.env.NODE_ENV === 'development' && <Box>
                    <Divider />
                    <Typography variant='overline' sx={{ ml: 2, mb: 1 }}>{'Development'}</Typography>
                    {developmentMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} setNavigationOpen={setNavigationOpen} />)}
                </Box>}
            </MenuList>
        </>
	);
}


const HirachicalMenuItem = ({ menu, setNavigationOpen }) => {
    const spaceAuthinfo = useSpaceAuthinfo();

    const navigate = useNavigate();

    const haveChildren = useMemo(() => {
        return menu.children && menu.children.length > 0
    }, []);

    const location = useLocation();
    const [ menuOpen, setMenuOpen ] = useState(haveChildren && location.pathname.startsWith(menu.route));

    const matchExact = !!useMatch(menu.route);
    useEffect(() => {
        if (matchExact) {
            // nothing todo
        }
    }, [ matchExact ]);

    const onMenuItemClick = () => {
        if (menu.link) {
            if (setNavigationOpen) setNavigationOpen(false);
            navigate(menu.route);
        }
        if (haveChildren) setMenuOpen(val => !val);
    }

    if (menu.auth) {
        if (!spaceAuthinfo || !spaceAuthinfo[menu.auth]) return null;
    }

    return (
        <>
            <MenuItem
                onClick={onMenuItemClick}
                style={{ color: matchExact ? colors.secondary : colors.textPrimary }}
            >
                {menu.icon}
                <ListItemText primary={menu.name} style={{ marginLeft: menu.icon ? '8px' : '28px' }} />
                
                {haveChildren && <>
                    {menuOpen ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                </>}
            </MenuItem>

            {haveChildren && <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                <MenuList dense>
                    {menu.children.map((child, index) => <HirachicalMenuItem key={index} menu={child} isChild={true} />)}
                </MenuList>
            </Collapse>}
        </>
    );
}

export default SidebarMenus;