import React, { useState, useContext, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import Title from 'components/Title';
import useSearchBucket from 'api/bucket/useSearchBucket_v2';
import BucketModal from './BucketManageModal';
import { useSpace } from 'contexts/space_v2.context';
import useDetectLast from 'hooks/useDetectLast';
import Filter from './Filter';
import { bucket_state_pipe } from 'pipes';
import Nodata from 'components/Nodata';


const BucketHistoryPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();

    const { me } = useContext(AuthContext);
	const space = useSpace();
    const navigate = useNavigate();

	const params = useMemo(() => {
        const keyword = searchParams.get('keyword') || '';
		const userId = searchParams.get('userId') || '';
		const state = searchParams.get('state') || '';

        return ({
			userId,
			keyword,
			state,
        });
	}, [ searchParams, space ]);

	const [ pageTrigger, setPageTrigger ] = useState();
	const { loading, rows, mayMore } = useSearchBucket(space?.id, params, pageTrigger);

    const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

    const reload = () => {
        setSearchParams({
            ...searchParams,
            trigger: new Date().getTime(),
        });
    }

    const haveConfirmableAuth = useMemo(() => {
        return space && me && !!me.auths.find(auth => auth.code === 'action.confirmable' && auth.refId === space.id);
    }, [ space, me ]);

    const handleModifyBucket = (bucket) => () => {
        navigate('/space/bucket/write?bucketId=' + bucket.id);
    }

	// bucket modal
	const [bucketModalOpen, setBucketModalOpen] = useState(false);
    const [bucketId, setBucketId] = useState();
	const onBucketClick = (bucket) => () => {
		setBucketId(bucket.id);
		setBucketModalOpen(true);
	}

	return (
		<Box>
            <Title>입출고명세서 내역</Title>

			<Filter searchDisabled={loading} />

            {rows && <div className="mt-4 table-responsive">
                <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                        <tr>
                            <th scope="col">타이틀</th>
                            <th scope="col">작성자</th>
                            <th scope="col">수행자</th>
                            <th scope="col">상태</th>
                            <th scope="col">보기</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={row.id} ref={index === rows.length - 1 ? lastElementRef : null}>
                                <th scope="row">{row.name || `입출고명세서_${row.id}`}</th>
                                <td>{row.userNickname}</td>
                                <td>{row.responderNickname || '-'}</td>
                                <td>{bucket_state_pipe(row.state)}</td>
                                <td>
                                    <Button size='small' onClick={onBucketClick(row)} variant="outlined">보기</Button>
                                    <Button size='small' sx={{ ml: 1 }} onClick={handleModifyBucket(row)} variant="outlined" color={'info'} disabled={!(haveConfirmableAuth && (row.state === 'submitted' || row.state === 'making'))}>수정하기</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {rows.length === 0 && <Nodata>{'입출고명세서가 없습니다.'}</Nodata>}
            </div>}

			<BucketModal bucketId={bucketId} open={bucketModalOpen} setOpen={setBucketModalOpen} reload={reload} />
		</Box>
	);
}

export default BucketHistoryPage;