import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { format, sub } from 'date-fns';
import ItemSelectionDialog from 'components/item/selection/ItemSelectionDialog';
import PeriodInput from 'components/PeriodInput';
import CircularButton from 'components/CircularButton';
import { useSpace } from 'contexts/space_v2.context';



const Filter = ({ searchDisabled, item, setItem }) => {
	const [ searchParams, setSearchParams ] = useSearchParams();

    const space = useSpace();

    const [ initialized, setInitialized ] = useState(false);
    const [ itemId, setItemId ] = useState();
	const [ from, setFrom ] = useState();
	const [ to, setTo ] = useState(null);

    useEffect(() => {
        if (!space) return;

        const itemId = searchParams.get('itemId');
        let from = searchParams.get('from');
        let to = searchParams.get('to');

        if (!from) from = format(sub(new Date(), { years: 1 }), 'yyyy-MM-dd');
        if (!to) to = format(new Date(), 'yyyy-MM-dd');

		setItemId(itemId);
        setFrom(from);
        setTo(to);
        setInitialized(true);
    }, [ space, searchParams ]);

    // item selection
	const [ itemSelectionOpen, setItemSelectionOpen ] = useState(false);
	
    const onSearchClick = (event) => {
        event.preventDefault();

        setSearchParams({
            from: from ? format(from, 'yyyy-MM-dd') : '',
            to: to ? format(to, 'yyyy-MM-dd') : '',
            itemId: itemId || ''
        });
    }

	return (
		<>
            <form onSubmit={onSearchClick}>
                <Stack spacing={1}>
                    {initialized && <PeriodInput
                        initialStartDate={from}
                        initialEndDate={to}
                        onPeriodChanged={(start, end) => {
                            setFrom(start);
                            setTo(end);
                        }}
                        options={{
                            baseDateText: '수행일시 기준'
                        }}
                    />}

                    <div className="input-group input-group-sm" style={{ maxWidth: '240px' }}>
                        {/* 제품 */}
                        <span className="input-group-text">제품</span>
                        <input className='form-control' value={item ? item.name : (itemId ? '제품선택됨' : '')} readOnly />
                        {itemId
                            ? <button type="button" className='btn btn-secondary' onClick={() => { setItemId(null); setItem(null) }}>선택해제</button>
                            : <button type="button" className='btn btn-secondary' onClick={() => { setItemSelectionOpen(true); }}>선택</button>
                        }
                    </div>

                    <CircularButton type="submit" variant='contained' loading={searchDisabled}>검색</CircularButton>
                </Stack>
            </form>

            <ItemSelectionDialog
                open={itemSelectionOpen}
                setOpen={setItemSelectionOpen}
                onSelect={item => {
                    setItemId(item.id)
                    setItem(item);
                }}
            />
		</>
	);
}

export default Filter;