import React, { useState, useEffect, Fragment } from 'react';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import CircularButton from 'components/CircularButton';
import getFromToGroupby from '../getFromToGroupby';
import PeriodInput from 'components/PeriodInput';
import ItemSelectionDialog from 'components/item/selection/ItemSelectionDialog';
import { useSpace } from 'contexts/space_v2.context';


const PeriodDivisions = [
    { key: 'month', name: '월별' },
    { key: 'week', name: '주별' },
    { key: 'day', name: '일별' },
];

const Codes = [
    { key: 'store,release', name: '모두' },
    { key: 'store', name: '입고' },
    { key: 'release', name: '출고' },
];

const Filter = ({ searchDisabled, item, setItem }) => {
    const [ searchParams, setSearchParams ] = useSearchParams();

    const space = useSpace();

    const [ codes, setCodes ] = useState('');
    const [ groupby, setGroupby ] = useState();
    const [ place, setPlace ] = useState();
    const [ itemId, setItemId ] = useState();
	const [ from, setFrom ] = useState(null);
	const [ to, setTo ] = useState(null);

    useEffect(() => {
        if (!space) return;

        const codes = searchParams.get('codes') || 'store,release';
        const groupby = searchParams.get('groupby') || 'month';
        const placeId = searchParams.get('placeId');
        const itemId = searchParams.get('itemId');
        let from = searchParams.get('from');
        let to = searchParams.get('to');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby(groupby, from, to);

        setCodes(codes);
        setGroupby(groupby);
		setPlace(space.places.find(el => el.id == placeId));
		setItemId(itemId);
        setFrom(fromDate);
        setTo(toDate);
    }, [ space, searchParams ]);

    const onSearchClick = (event) => {
		event.preventDefault();
		
        const sParams = {
			codes,
            groupby,
            from: from ? format(from, 'yyyy-MM-dd') : '',
            to: to ? format(to, 'yyyy-MM-dd') : '',
		};
		if (place) sParams.placeId = place.id;
		if (itemId) sParams.itemId = itemId;
		setSearchParams(sParams);
	}

    // groupby 가 변경될때마다 기간 초기화
    const handleChangeGroupby = (event) => {
        const groupby_a = event.target.value;
        setGroupby(groupby_a);

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby(groupby_a, '', '');
        setFrom(fromDate);
        setTo(toDate);
    }

    // item selection
	const [ itemSelectionOpen, setItemSelectionOpen ] = useState(false);

	return (
		<>
            <form onSubmit={onSearchClick}>
                <Stack spacing={1}>
                    <div className="input-group input-group-sm" style={{ maxWidth: '320px' }}>
                        {/* 기간구분 */}
                        <span className="input-group-text">기간구분</span>
                        <select className="form-select" value={groupby} onChange={handleChangeGroupby}>
                            <option value=""></option>
                            {PeriodDivisions.map(el => <option key={el.key} value={el.key}>{el.name}</option>)}
                        </select>
                    </div>
                    
                    {/* sampleby 가 변경됨에 따라 다시 그려야 함 */}
                    <Fragment key={groupby}>
                        <PeriodInput
                            initialStartDate={from}
                            initialEndDate={to}
                            onPeriodChanged={(start, end) => {
                                setFrom(start);
                                setTo(end);
                            }}
                            options={{
                                baseDateText: '수행일시 기준'
                            }}
                        />
                    </Fragment>

                    <div className="input-group input-group-sm" style={{ maxWidth: '640px' }}>
                        {/* 코드 */}
                        <span className="input-group-text">구분</span>
                        <select className="form-select" value={codes} onChange={event => setCodes(event.target.value)}>
                            {Codes.map(el => <option key={el.key} value={el.key}>{el.name}</option>)}
                        </select>

                        {/* 창고 */}
                        <span className="input-group-text">창고</span>
                        <select className="form-select" value={place?.id || ''} onChange={event => setPlace(space.places.find(el => el.id == event.target.value))}>
                            <option value=""></option>
                            {space.places.map(place => <option key={place.id} value={place.id}>{place.name}</option>)}
                        </select>

                        {/* 제품 */}
                        <span className="input-group-text ms-1">제품</span>
                        <input className='form-control' value={item ? item.name : (itemId ? '제품선택됨' : '')} readOnly />
                        {itemId
                            ? <button type="button" className='btn btn-secondary' onClick={() => { setItemId(null); setItem(null) }}>선택해제</button>
                            : <button type="button" className='btn btn-secondary' onClick={() => { setItemSelectionOpen(true); }}>선택</button>
                        }
                    </div>

                    <CircularButton type="submit" style={{ marginBottom: '24px' }} variant='contained' loading={searchDisabled}>검색</CircularButton>
                </Stack>
            </form>
            
            <ItemSelectionDialog
                open={itemSelectionOpen}
                setOpen={setItemSelectionOpen}
                onSelect={item => {
                    setItemId(item.id)
                    setItem(item);
                }}
            />
		</>
	);
}

export default Filter;