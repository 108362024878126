import React from 'react';
import { Box } from '@mui/material';
import docuStyles from 'css/docu-style.module.css';
import H4 from 'components/document/H4';
import H5 from 'components/document/H5';
import Body1 from 'components/document/Body1';
import Glossary from 'components/document/Glossary';
import { Link } from 'react-router-dom';


const HistoryHomePage = () => {
	return (
		<Box>
            <H4>히스토리</H4>

            <Glossary>
                <Body1 style={{ margin: 0 }}>
                    <span style={{ fontWeight: 'bold' }}>액션 히스토리</span><br></br>
                    모든 액션은 타임라인에 기록되어 <Link to={'/space/history/action'}>액션 히스토리</Link>에서 확인할 수 있습니다. <br></br>
                    창고의 제품 수량과 액션 히스토리는 서로 연관되어 있어서 데이터의 불일치가 일어나지 않도록 설계되었습니다.<br></br>
                </Body1>
            </Glossary>
            
            <Box className={docuStyles.section}>
                <H5>제품 현황</H5>
                <Body1>제품별로  의 수량을 확인할 수 있습니다.</Body1>
                <Body1>특정 제품의 액션 히스토리를 확인할 수 있습니다.</Body1>
            </Box>

            <Box className={docuStyles.section}>
                <H5>창고 현황</H5>
                <Body1>현재 창고 내에 어떤 제품이 몇 개 있는지 확인할 수 있습니다.</Body1>
                <Body1>특정 창고의 액션 히스토리를 확인할 수 있습니다.</Body1>
            </Box>

            <Box className={docuStyles.section}>
                <H5>거래처 현황</H5>
                <Body1>현재 거래처의 기본정보를 확인할 수 있습니다.</Body1>
                <Body1>특정 거래처와 연관된 액션 히스토리를 확인할 수 있습니다.</Body1>
            </Box>
		</Box>
	);
}

export default HistoryHomePage;